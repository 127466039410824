import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "flowbite-react";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  useGetClustersByCampusQuery,
  useGetStaffByEmailIdQuery,
} from "../../../../graphql/generated.tsx";
import { auth } from "../../../../utils/firebase.js";

const ReportsClusterListing = () => {
  const { campusName } = useParams();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const limit = 10;

  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const userRole = user
    ? JSON.parse(user?.reloadUserInfo?.customAttributes).role
    : null;

  const { data: staffData, loading: staffLoading } = useGetStaffByEmailIdQuery({
    variables: { email: user?.email },
  });

  const { data, refetch } = useGetClustersByCampusQuery({
    variables: { campusName, searchTerm, page, limit },
  });

  const availableClusters =
    userRole === "staff"
      ? staffData?.getStaffByEmailId?.isAdminStaff
        ? data?.getClustersByCampus
        : data?.getClustersByCampus.filter((cluster) =>
            staffData?.getStaffByEmailId?.clusterIds.includes(cluster.id)
          )
      : data?.getClustersByCampus;

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    refetch({ campusName, searchTerm: e.target.value, page: 1, limit });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    refetch({ campusName, searchTerm, page: newPage, limit });
  };

  return (
    <AdminLayout>
      <div className="p-5 max-w-full">
        <div className="flex justify-between items-center mb-8 max-md:flex-col max-md:gap-4">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              {campusName} Clusters
            </div>
          </div>
          <div className="mt-2 w-full md:w-auto">
            <input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={handleSearch}
              className="p-2 border border-gray-300 rounded-md w-full md:max-w-xs"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
          {availableClusters?.map((cluster) => (
            <div
              key={cluster.id}
              className="bg-white shadow-md rounded-lg p-6 hover:shadow-lg cursor-pointer"
              onClick={() => navigate(`${cluster.id}`)}
            >
              <h3 className="text-xl font-semibold mb-2">{cluster.name}</h3>
              <p className="text-gray-700">Enrollee: {cluster.studentCount}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-between items-center mt-4 max-md:flex-col max-md:gap-4">
          <button
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200 w-full md:w-auto"
          >
            Previous
          </button>
          <span className="text-center w-full md:w-auto">Page {page}</span>
          <button
            onClick={() => handlePageChange(page + 1)}
            disabled={data?.getClustersByCampus?.length < limit}
            className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:bg-gray-200 w-full md:w-auto"
          >
            Next
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default ReportsClusterListing;
