import React, { useState } from "react";
import {
  Modal,
  Button,
  TextInput,
  Textarea,
  Label,
  Checkbox,
} from "flowbite-react";
import { toast } from "react-toastify";
import Select from "react-select";
import { useUpdateAssignmentMutation } from "../../../graphql/generated.tsx";

const languageOptions = [
  { value: "C", label: "C" },
  { value: "Python", label: "Python" },
  { value: "Java", label: "Java" },
  { value: "Cpp", label: "Cpp" },
];

const EditAssignmentModal = ({ id, setAssignments, onClose, assignment }) => {
  const [name, setName] = useState(assignment.name || "");
  const [shortDescription, setShortDescription] = useState(
    assignment.short_description || ""
  );
  const [enableProctoring, setEnableProctoring] = useState(
    assignment.enable_proctoring || false
  );
  const [maxTabSwitches, setMaxTabSwitches] = useState(
    assignment.max_tabSwitches || 0
  );
  const [duration, setDuration] = useState(assignment.duration || "");
  const [description, setDescription] = useState(assignment.description || "");
  const [dueDate, setDueDate] = useState(
    assignment.due_date
      ? new Date(assignment.due_date).toISOString().split("T")[0]
      : ""
  );
  const [languages, setLanguages] = useState(
    assignment.languages.map((lang) => ({ value: lang, label: lang })) || []
  );
  const [domain, setDomain] = useState(assignment.domain || "");
  const [maxCheatCount, setMaxCheatCount] = useState(
    assignment.max_cheat_count || 0
  );

  const [updateAssignment] = useUpdateAssignmentMutation({
    onCompleted: (data) => {
      setAssignments(data.updateAssignment);
      toast.success("Assignment updated successfully");
      onClose();
    },
    onError: (error) => {
      console.error("Error updating assignment:", error);
      toast.error("Error updating assignment");
    },
  });

  const handleUpdate = () => {
    if (!enableProctoring) {
      setMaxCheatCount(0);
      setMaxTabSwitches(0);
    }
    updateAssignment({
      variables: {
        id,
        updateAssignmentInput: {
          id,
          name,
          short_description: shortDescription,
          description,
          due_date: new Date(dueDate),
          languages: languages.map((lang) => lang.value),
          domain,
          enable_proctoring: enableProctoring,
          max_tabSwitches: parseInt(maxTabSwitches),
          max_cheat_count: parseInt(maxCheatCount),
          duration: parseInt(duration),
        },
      },
    });
  };

  return (
    <Modal show={true} onClose={onClose}>
      <Modal.Header>Edit Assignment</Modal.Header>
      <Modal.Body>
        <div className="block">
          <Label htmlFor="name" value="Name :" className="font-semibold" />
          <TextInput
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="shortDescription"
            value="Short Description :"
            className="font-semibold"
          />
          <TextInput
            id="shortDescription"
            value={shortDescription}
            onChange={(e) => setShortDescription(e.target.value)}
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="description"
            value="Description :"
            className="font-semibold"
          />
          <Textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="dueDate"
            value="Due Date :"
            className="font-semibold"
          />
          <TextInput
            type="date"
            id="dueDate"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
          />
        </div>
        <div className="block mt-4">
          <Label
            htmlFor="duration"
            value="Duration :"
            className="font-semibold"
          />
          <TextInput
            type="number"
            id="duration"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </div>
        <div className="my-5">
          <Checkbox
            id="enableProctoring"
            checked={enableProctoring}
            onChange={(e) => setEnableProctoring(e.target.checked)}
          />
          <Label
            htmlFor="enableProctoring"
            value="Enable Proctoring"
            className="ml-2"
          />
        </div>

        {enableProctoring && (
          <>
            <div className="block mt-4">
              <Label
                htmlFor="maxCheatCount"
                value="Maximum Cheat Count :"
                className="font-semibold"
              />
              <TextInput
                type="number"
                id="maxCheatCount"
                value={maxCheatCount}
                onChange={(e) => setMaxCheatCount(e.target.value)}
              />
            </div>
            <div className="block mt-4">
              <Label
                htmlFor="maxTabSwitches"
                value="Maximum Tab Switches :"
                className="font-semibold"
              />
              <TextInput
                type="number"
                id="maxTabSwitches"
                value={maxTabSwitches}
                onChange={(e) => setMaxTabSwitches(e.target.value)}
              />
            </div>
          </>
        )}
        <div className="block mt-4">
          <Label
            htmlFor="languages"
            value="Languages :"
            className="font-semibold"
          />
          <Select
            isMulti
            options={languageOptions}
            value={languages}
            onChange={(selectedOptions) => setLanguages(selectedOptions)}
          />
        </div>
        <div className="block mt-4">
          <Label htmlFor="domain" value="Domain :" className="font-semibold" />
          <TextInput
            id="domain"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUpdate} color="success" className="p-0">
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAssignmentModal;
