import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Card } from "flowbite-react";
import axios from "axios";

const Feedback = () => {
  const [campusList, setCampusList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCampusNames = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FEEDBACK_API}/apiforms/api/getCampusNames`
        );
        if (response.data.success) {
          setCampusList(response.data.data); 
        }
      } catch (error) {
        console.error("Error fetching campus names:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampusNames();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AdminLayout>
      <div className="p-10">
        <div className="flex justify-between items-center mb-8 max-md:gap-2">
          <div className="flex items-center">
            <button
              onClick={() => window.history.back()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <IoMdArrowRoundBack className="h-6 w-6" />
            </button>
            <div className="text-2xl font-bold ml-2 max-md:text-lg">
              All Campus
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {campusList.map((campus, index) => (
            <Card
              key={index}
              className="mb-3"
              href={`/admin/feedback/${campus}`}
            >
              <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {campus}
              </h5>
            </Card>
          ))}
        </div>
      </div>
    </AdminLayout>
  );
};

export default Feedback;
