import { Table, TableCell, TableRow } from "flowbite-react";

export function SkeletonRow({col}) {
  return (
    <TableRow className="animate-pulse">
      {Array.from({ length: col }).map((_, index) => (
        <TableCell key={index} className="py-3 px-6">
          <div className="h-4 bg-gray-300 rounded w-full"></div>
        </TableCell>
      ))}
    </TableRow>
  );
}
