import React from "react";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaHeadset,
} from "react-icons/fa";
import NavBar from "../NavBar";

const ContactUs = () => {
  return (
    <>
      <NavBar />
      <div className="p-6 bg-gray-100 text-gray-800 min-h-screen flex items-center justify-center mt-5">
        <div className="max-w-3xl w-full bg-white shadow-lg rounded-lg p-8">
          <h1 className="text-4xl font-bold text-center mb-3">Contact Us</h1>
          <p className="text-sm text-gray-500 text-center italic mb-10">
            Last updated on 13-12-2024 19:27:44
          </p>

          <div className="space-y-8">
            <p className="text-center text-gray-700">
              You may contact us using the information below:
            </p>

            <div className="space-y-6">
              <div className="flex items-start space-x-4">
                <FaMapMarkerAlt className="text-primary text-2xl" />
                <div>
                  <p className="font-semibold text-gray-700">
                    Merchant Legal Entity Name:
                  </p>
                  <p>BETTER TOMORROW TRAINING INSTITUTE LLP</p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <FaMapMarkerAlt className="text-primary text-2xl" />
                <div>
                  <p className="font-semibold text-gray-700">
                    Registered Address:
                  </p>
                  <p>
                    NO.3/13A, SAKTHI NAGAR, ATHUPALAYAM, ANUPPAPALAYAM,
                    TIRUPPUR, TIRUPPUR, TAMIL NADU, PIN: 641652
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <FaMapMarkerAlt className="text-primary text-2xl" />
                <div>
                  <p className="font-semibold text-gray-700">
                    Operational Address:
                  </p>
                  <p>
                    NO.3/13A, SAKTHI NAGAR, ATHUPALAYAM, ANUPPAPALAYAM,
                    TIRUPPUR, TIRUPPUR, TAMIL NADU, PIN: 641652
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <FaPhoneAlt className="text-primary text-2xl" />
                <div>
                  <p className="font-semibold text-gray-700">Telephone No:</p>
                  <p className="hover:text-blue-600 transition-colors cursor-pointer">
                    9750503595
                  </p>
                </div>
              </div>

              <div className="flex items-start space-x-4">
                <FaEnvelope className="text-primary text-2xl" />
                <div>
                  <p className="font-semibold text-gray-700">E-Mail ID:</p>
                  <p className="hover:text-blue-600 transition-colors cursor-pointer">
                    info@thebettertomorrow.in
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <FaHeadset className="text-primary text-2xl" />
                <div>
                  <p className="font-semibold text-gray-700">
                    For any assistance:
                  </p>
                  <p className="hover:text-blue-600 transition-colors cursor-pointer">
                    support@thebettertomorrow.in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
