import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  useGetAllQuestionQuery,
  useGetCampusQuery,
  useGetLastFiveSubmissionsLazyQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import StudentLayout from "../../Student/Layout/index.jsx";
import QuestionListingTable from "../Components/QuestionListingTable.jsx";
import { FaCheckToSlot, FaTableList } from "react-icons/fa6";
import { TbProgressBolt } from "react-icons/tb";
import { MdOutlineGrade } from "react-icons/md";
import { Select } from "flowbite-react";

const StudentQuestionListing = () => {
  const { categoryId, courseId, clusterid } = useParams();
  const [questions, setQuestions] = useState([]);
  const [submissions, setSubmissions] = useState({});
  const [completedCount, setCompletedCount] = useState(0);
  const [catName, setCatName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginatedQuestions, setPaginatedQuestions] = useState([]);
  const [appliedFilter, setAppliedFilter] = useState(false);
  const [filter, setFilter] = useState("all");
  const [difficulty, setDifficulty] = useState("all");
  const [search, setSearch] = useState(""); // Search for title
  const questionsPerPage = 10;

  const { data, loading } = useGetAllQuestionQuery({
    variables: {
      courseId,
      categoryId: String(categoryId),
    },
  });

  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: studentData } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const { data: campusData } = useGetCampusQuery({
    variables: { id: studentData?.getStudentByEmailId?.campusId },
    skip: !studentData,
  });

  const [getSubmissions] = useGetLastFiveSubmissionsLazyQuery();

  useEffect(() => {
    if (data?.getAllQuestion?.questions) {
      setCatName(data.getAllQuestion.questions?.[0]?.category?.name || "");
      setQuestions(data.getAllQuestion.questions);
    }
  }, [data]);

  const [inProgressCount, setInProgressCount] = useState(0);
  const [totalScore, setTotalScore] = useState(0);

  useEffect(() => {
    const fetchSubmissions = async () => {
      if (!studentData || !campusData || questions.length === 0) return;

      const submissionPromises = questions.map((question) => {
        const submitInput = {
          campus_name: campusData?.campus?.name,
          cluster_id: clusterid,
          student_id: studentData?.getStudentByEmailId?.id,
          course_id: courseId,
          question_id: question.id,
        };

        return getSubmissions({ variables: { submitInput } })
          .then(({ data }) => ({ questionId: question.id, data }))
          .catch(() => ({ questionId: question.id, data: null }));
      });

      const results = await Promise.all(submissionPromises);
      const submissionsMap = {};
      let completed = 0;
      let inProgress = 0;
      let totalScore = 0;

      results.forEach(({ questionId, data }) => {
        const submission = data?.getLastFiveSubmissions || null;
        submissionsMap[questionId] = submission;

        totalScore += submission?.max_score || 0;

        if (submission) {
          if (submission.is_completed) {
            completed++;
          } else if (submission.submissions?.length > 0) {
            inProgress++;
          }
        }
      });
      setTotalScore(totalScore);
      setSubmissions(submissionsMap);
      setCompletedCount(completed);
      setInProgressCount(inProgress);
    };

    fetchSubmissions();
  }, [studentData, campusData, questions, courseId, clusterid, getSubmissions]);

  let totalPages = Math.ceil(questions.length / questionsPerPage);
  const max_score = questions.reduce((acc, question) => acc + parseInt(question.marks), 0);
  console.log({ max_score });

  useEffect(() => {
    if (!questions || !submissions) return;

    const filteredQuestions = questions.filter((question) => {
      const submission = submissions[question.id];

      let matchesStatus = true;
      if (filter === "completed") {
        matchesStatus = submission?.is_completed || false;
      } else if (filter === "inprogress") {
        matchesStatus =
          submission?.submissions?.length > 0 && !submission?.is_completed;
      } else if (filter === "notstarted") {
        matchesStatus = !submission || !submission?.submissions?.length;
      }

      const matchesDifficulty =
        difficulty === "all" || question.difficulty === difficulty;

      const matchesSearch =
        !search || question.title.toLowerCase().includes(search.toLowerCase());
      return matchesStatus && matchesDifficulty && matchesSearch;
    });

    const sortedQuestions = filteredQuestions.sort((a, b) => {
      const getStatusOrder = (question) => {
        const submission = submissions[question.id];
        if (submission?.submissions?.length > 0 && !submission?.is_completed) {
          return 0;
        }
        if (!submission || !submission?.submissions?.length) {
          return 1;
        }
        if (submission?.is_completed) {
          return 2;
        }
        return 3;
      };

      return getStatusOrder(a) - getStatusOrder(b);
    });

    // Pagination logic
    const startIndex = (currentPage - 1) * questionsPerPage;
    const endIndex = currentPage * questionsPerPage;
    totalPages = Math.ceil(sortedQuestions.length / questionsPerPage);
    setAppliedFilter(true);
    setPaginatedQuestions(sortedQuestions.slice(startIndex, endIndex));
  }, [
    currentPage,
    questions,
    submissions,
    filter,
    difficulty,
    search,
    questionsPerPage,
  ]);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  if (loading) {
    return (
      <StudentLayout>
        <div className="flex items-center justify-center h-screen">
          <p>Loading...</p>
        </div>
      </StudentLayout>
    );
  }

  return (
    <StudentLayout>
      <div className="p-10 max-md:p-5 max-md:ml-10">
        <div className="flex mb-10">
          <button
            onClick={() => window.history.back()}
            className="text-gray-500 hover:text-gray-700 focus:outline-none mr-4"
          >
            <IoMdArrowRoundBack className="h-6 w-6" />
          </button>
          <h2 className="text-3xl font-bold w-full max-md:text-2xl">
            {catName}
          </h2>
        </div>

        <div className="flex flex-wrap gap-4 my-10">
          <div className="bg-white rounded-3xl border p-6 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <FaTableList className="h-8 w-8" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {data?.getAllQuestion?.totalCount}
            </h2>
            <p className="text-gray-500">
              Total
              <br /> Questions
            </p>
          </div>

          <div className="bg-white rounded-3xl border p-6 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <FaCheckToSlot className="h-8 w-8" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {completedCount}
            </h2>
            <p className="text-gray-500">
              Completed <br />
              Questions
            </p>
          </div>

          <div className="bg-white rounded-3xl border p-5 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <TbProgressBolt className="h-8 w-8" />
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              {inProgressCount}
            </h2>
            <p className="text-gray-500">
              In Progress
              <br /> Questions
            </p>
          </div>
          <div className="bg-white rounded-3xl border p-6 w-60 text-center">
            <div className="mb-4 flex justify-center items-center w-full">
              <MdOutlineGrade className="h-8 w-8" />
            </div>
            <h2 className="text-2xl font-bold text-gray-800">
              {totalScore}/{max_score}
            </h2>
            <p className="text-gray-500">Total Score</p>
          </div>
        </div>

        {/* Filters */}
        <div className="flex gap-4 mb-6 max-md:flex-col">
          <Select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="w-[150px]"
          >
            <option value="all" disabled>
              Status
            </option>
            <option value="all">All</option>
            <option value="completed">Completed</option>
            <option value="inprogress">In Progress</option>
            <option value="notstarted">Awaiting</option>
          </Select>

          <Select
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
            className="w-[150px]"
          >
            <option value="all" disabled>
              Difficulty
            </option>
            <option value="all">All</option>
            <option value="EASY">Easy</option>
            <option value="MEDIUM">Medium</option>
            <option value="HARD">Hard</option>
          </Select>

          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search questions"
            className="border border-gray-300 rounded-lg px-4 py-2 w-[250px]"
          />
        </div>

        <QuestionListingTable
          data={paginatedQuestions}
          courseId={courseId}
          clusterid={clusterid}
          submissions={submissions}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          currentPage={currentPage}
          totalPages={paginatedQuestions.length < 10 ? 1 : totalPages}
        />
      </div>
    </StudentLayout>
  );
};

export default StudentQuestionListing;
