import { useParams } from "react-router-dom";
import {
  useCompileCodeLazyQuery,
  useGetAssignmentQuery,
  useGetAssignmentQuestionQuery,
  useGetAssignmentSubmitQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
  useSubmitAssignmentCodeMutation,
} from "../../../graphql/generated.tsx";
import React, { useState, useEffect, useRef } from "react";
import "codemirror/mode/clike/clike";
import "codemirror/mode/python/python";
import Compiler from "../../Practice/Components/Compiler";
import CustomInput from "../../Practice/Components/CustomInput";
import RunModel from "../../Practice/Components/RunModel";
import ErrorModel from "../../Practice/Components/ErrorModel";
import { Button } from "flowbite-react";
import TestLayout from "../Layout/TestLayout.jsx";
import CompilerQuestionCard from "../Components/CompilerQuestionCard.jsx";
import BackButton from "../Components/BackButton.jsx";
import SumbitModel from "../../Practice/Components/SumbitModel";

const AssignmentTestQuestion = () => {
  const { questionId, assignmentId } = useParams();
  const [code, setCode] = useState("");
  const [codeApplied, setCodeApplied] = useState(false);
  const [output, setOutput] = useState("");
  const [error, setError] = useState("");
  const [input, setInput] = useState("");
  const [question, setQuestion] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const errorRef = useRef(null);
  const [testResults, setTestResults] = useState([]);
  const [run, setRun] = useState(false);
  const [submit, setSubmit] = useState(false);
  const tableRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(true);
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [initialCode, setInitialCode] = useState("");

  const { data, loading } = useGetAssignmentQuestionQuery({
    variables: { id: questionId },
  });

  const studentEmail = JSON.parse(localStorage.getItem("user")).email;
  const [autosave, setAutoSave] = useState();

  const { data: student } = useGetStudentByEmailIdQuery({
    variables: { email: studentEmail },
  });
  const { data: campus } = useGetCampusQuery({
    variables: { id: student?.getStudentByEmailId?.campusId },
    skip: !student?.getStudentByEmailId,
  });

  const { data: PreviousSubmissions, refetch } = useGetAssignmentSubmitQuery({
    variables: {
      assignmentId,
      studentId: student?.getStudentByEmailId?.id,
      questionId,
      campus_name: campus?.campus?.name,
    },
    skip: !student?.getStudentByEmailId,
  });

  const { data: Assignment } = useGetAssignmentQuery({
    variables: { id: assignmentId },
  });
  const [language, setLanguage] = useState("C");
  useEffect(() => {
    if (Assignment?.getAssignment) {
      const defaultLang = Assignment?.getAssignment?.languages[0];
      setLanguage(defaultLang);
    }
  }, [Assignment]);

  const [
    compileCode,
    { data: compileData, loading: compileLoading, error: compileerror },
  ] = useCompileCodeLazyQuery();

  const [submitCode, { data: submitData, loading: submitLoading }] =
    useSubmitAssignmentCodeMutation();

  const extractFunctionCode = (code, language, targetFunctionName) => {
    let functionRegex;

    switch (language) {
      case "python":
        functionRegex = new RegExp(
          `def\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*:[\\s\\S]*?(?=\\n\\S|$)`,
          "g"
        );
        break;
      case "java":
        functionRegex = new RegExp(
          `public\\s+static\\s+\\w+\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*{[^}]*}`,
          "g"
        );
        break;
      case "cpp":
      case "c":
        functionRegex = new RegExp(
          `\\w+(\\s*\\*\\s*|\\s+)${targetFunctionName}\\s*\\([^)]*\\)\\s*{([\\s\\S]*?)}(?![^}]*})`,
          "g"
        );
        break;
      default:
        return "";
    }

    const match = code?.match(functionRegex);
    return match ? match[0] : "";
  };

  useEffect(() => {
    refetch();
    if (PreviousSubmissions?.getAssignmentSubmit?.autosave) {
      setAutoSave(PreviousSubmissions?.getAssignmentSubmit?.autosave);
    }
  }, [PreviousSubmissions]);

  useEffect(() => {
    if (data) {
      setQuestion(data.getAssignmentQuestion);
      const langKey = language?.toLowerCase();
      const previousQuestion =
        PreviousSubmissions?.getAssignmentSubmit?.questions?.find(
          (ques) => ques.question_id === questionId
        );
      const autoSave =
        autosave?.find((ques) => ques.question_id === questionId)?.answer &&
        autosave?.find((ques) => ques.question_id === questionId).language ===
          language;
      if (previousQuestion) {
        if (
          data?.getAssignmentQuestion?.showFunctionOnly &&
          data?.getAssignmentQuestion?.predefinedCode[langKey]
        ) {
          const extractCode = extractFunctionCode(
            previousQuestion?.answer,
            previousQuestion?.language,
            data?.getAssignmentQuestion?.targetFunctionName
          );
          setCode(extractCode);
          console.log({ extractCode });
          setInitialCode(extractCode);
        } else {
          setCode(previousQuestion?.answer);
        }
        setLanguage(previousQuestion?.language);
      } else if (autoSave) {
        autosave.find((ques) => ques.question_id === questionId)?.answer &&
          setCode(
            autosave.find((ques) => ques?.question_id === questionId)?.answer
          );
        setInitialCode(
          autosave.find((ques) => ques?.question_id === questionId)?.answer
        );
        setLanguage(
          autosave.find((ques) => ques.question_id === questionId)?.language
        );
      } else if (
        data?.getAssignmentQuestion?.showFunctionOnly &&
        data?.getAssignmentQuestion?.predefinedCode[langKey]
      ) {
        const targetFunctionCode = extractFunctionCode(
          data?.getAssignmentQuestion?.predefinedCode[langKey],
          langKey,
          data?.getAssignmentQuestion.targetFunctionName
        );
        setInitialCode(targetFunctionCode);
        setCode(targetFunctionCode);
      } else if (data?.getAssignmentQuestion?.predefinedCode[langKey]) {
        setInitialCode(data.getAssignmentQuestion.predefinedCode[langKey]);
        setCode(data.getAssignmentQuestion.predefinedCode[langKey]);
      } else {
        console.log({langKey})
        if (langKey === "python") {
          setCode("#Type your code here");
          setInitialCode("#Type your code here");
        } else {
          setCode("//Type your code here");
          setInitialCode("//Type your code here");
        }
      }
    }
  }, [data, PreviousSubmissions,language, autosave]);

  console.log({ language });

  const handleFullScreenChange = () => {
    setFullScreen(!!document.fullscreenElement);
  };

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [testResults]);

  useEffect(() => {
    if (error) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  useEffect(() => {
    if (
      compileData &&
      compileData?.compileCode?.compilationOutput?.stderr === ""
    ) {
      setTestResults(compileData.compileCode.Results);
    } else {
      setTestResults([]);
    }
  }, [compileData]);

  useEffect(() => {
    if (submitData?.submitAssignmentCode) {
      setOutput(submitData.submitAssignmentCode);
    }
  }, [submitData]);

  const handleCodeChange = (newValue) => {
    setCode(newValue);
    setIsCodeChanged(newValue !== initialCode);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setRun(false);
    setInput("");
  };
  const assignmentSubmitInput = {
    assignment_id: assignmentId,
    question_id: question.id,
    student_id: student?.getStudentByEmailId.id,
    cluster_id: student?.getStudentByEmailId.clusterIds[0],
    campus_name: campus?.campus?.name,
  };

  const handleSubmit = async () => {
    try {
      await submitCode({
        variables: {
          assignmentCompilerCodeInput: {
            code,
            language,
            questionid: question.id,
            testCases: question?.test_cases?.map((test) => ({
              id: test.id,
              input: test.input,
              output: test.output,
              visibility: test.visibility,
              weightage: parseFloat(test.weightage),
            })),
          },
          assignmentSubmitInput: assignmentSubmitInput,
        },
      });
      setSubmit(true);
      setRun(false);
      setInput("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleRun = async () => {
    try {
      setSubmit(false);
      setError(false);

      let data;

      if (!isChecked) {
        const response = await compileCode({
          variables: {
            compileCodeInput: {
              code,
              testCases: question?.test_cases?.map((test) => ({
                id: test.id,
                input: test.input,
                output: test.output,
                visibility: test.visibility,
                weightage: parseFloat(test.weightage),
              })),
              language,
              questionid: question.id,
            },
          },
        });
        setRun(true);
        data = response.data;
      } else {
        const response = await compileCode({
          variables: {
            compileCodeInput: {
              code,
              testCases: [
                {
                  id: "0",
                  input: input,
                  output: " ",
                  visibility: "SAMPLE",
                  weightage: 0,
                },
              ],
              language,
              questionid: question.id,
            },
          },
        });
        data = response.data;
      }

      if (data && data?.compileCode?.compilationOutput?.stderr === "") {
        setRun(true);
        setTestResults(data.compileCode.Results);
      }
    } catch (err) {
      setTestResults([]);

      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        const errorMessage = err.graphQLErrors[0].message;
        setError(errorMessage);
      } else {
        setError(
          "There could some logic error in your code or network connectivity issue. Kindly try again later"
        );
      }
    }
  };

  const setToDefault = () => {
    const langKey = language?.toLowerCase();
    if (
      data?.getAssignmentQuestion.showFunctionOnly &&
      data?.getAssignmentQuestion.predefinedCode[langKey]
    ) {
      const targetFunctionCode = extractFunctionCode(
        data.getAssignmentQuestion.predefinedCode[langKey],
        langKey,
        data.getAssignmentQuestion.targetFunctionName
      );
      setCode(targetFunctionCode);
      setInitialCode(targetFunctionCode);
    } else if (data?.getAssignmentQuestion.predefinedCode[langKey]) {
      setCode(data.getAssignmentQuestion.predefinedCode[langKey]);
      setInitialCode(data.getAssignmentQuestion.predefinedCode[langKey]);
    }
  };

  return (
    <TestLayout assignmentId={assignmentId} disableSubmit={true}>
      {isFullScreen ? (
        <div className="pt-8 bg-slate-100">
          <BackButton
            setFullScreen={setFullScreen}
            isFullScreen={isFullScreen}
          />
          <div className="flex max-md:flex-col h-[calc(100vh-4rem)]">
            <div
              className="lg:w-1/2 pr-4 overflow-y-auto"
              style={{ maxHeight: "100%" }}
            >
              <CompilerQuestionCard
                question={question}
                loading={loading}
                onApply={setCode}
              />
            </div>

            <div
              className="lg:w-1/2 pl-4 overflow-y-auto"
              style={{ maxHeight: "100%" }}
            >
              <Compiler
                student_id={student?.getStudentByEmailId?.id}
                question_id={questionId}
                language={language}
                code={code}
                setCodeApplied={setCodeApplied}
                setToDefault={setToDefault}
                setLanguage={setLanguage}
                handleCodeChange={handleCodeChange}
                errorRef={errorRef}
                handleSubmit={handleSubmit}
                handleRun={handleRun}
                CourseData={Assignment?.getAssignment}
                compileLoading={compileLoading}
                submitLoading={submitLoading}
                submitInput={assignmentSubmitInput}
                isAssignment
                isChecked={isChecked}
                isCodeChanged={isCodeChanged}
              />
              <CustomInput
                isChecked={isChecked}
                handleCheckboxChange={handleCheckboxChange}
                setInput={setInput}
                input={input}
              />

              {!compileerror && run && testResults?.length !== 0 && (
                <RunModel
                  tableRef={tableRef}
                  isChecked={isChecked}
                  testResults={testResults}
                  loading={compileLoading}
                />
              )}

              {output && submit && (
                <SumbitModel output={output} question={question} />
              )}

              {compileerror && !submit && (
                <ErrorModel error={compileerror} language={language} />
              )}

              {testResults.length === 0 &&
                !submit &&
                run &&
                !compileLoading &&
                !compileerror && (
                  <ErrorModel
                    error={compileData?.compileCode?.compilationOutput?.stderr}
                    language={language}
                    runtimeError={testResults.length === 0}
                  />
                )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-screen h-screen bg-gray-100">
          <h1 className="text-xl mb-4">
            Please switch to Fullscreen to continue
          </h1>
          <Button className="bg-primary p-0" onClick={toggleFullScreen}>
            Full Screen
          </Button>
        </div>
      )}
    </TestLayout>
  );
};

export default AssignmentTestQuestion;
