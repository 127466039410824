import React from "react";
import NavBar from "../NavBar";

const Privacy = () => {
  return (
    <>
      <NavBar />
      <div className="p-10 bg-gray-100 text-gray-800 mt-10">
        <div className="max-w-6xl mx-auto p-6 space-y-6 bg-white rounded-lg">
          <h1 className="text-4xl font-extrabold text-center text-gray-900">
            Privacy Policy
          </h1>
          <p className="text-sm text-gray-500 text-center italic mb-10">
            Last updated: January 13, 2025
          </p>
          <section className="space-y-6">
            <p className="text-gray-700">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You. We use Your Personal data to provide and improve
              the Service. By using the Service, You agree to the collection and
              use of information in accordance with this Privacy Policy.
            </p>
          </section>

          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">
              Interpretation and Definitions
            </h2>
            <h3 className="text-xl font-semibold">Interpretation</h3>
            <p className="text-gray-700">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>

            <h3 className="text-xl font-semibold">Definitions</h3>
            <ul className="list-inside list-disc space-y-2 text-gray-700">
              <li>
                <strong>Account:</strong> A unique account created for You to
                access our Service or parts of our Service.
              </li>
              <li>
                <strong>Affiliate:</strong> An entity that controls, is
                controlled by or is under common control with a party.
              </li>
              <li>
                <strong>Company:</strong> Refers to BETTER TOMORROW TRAINING
                INSTITUTE LLP, located at No.3/13A, Sakthi Nagar, Tiruppur,
                Tamil Nadu, India.
              </li>
              <li>
                <strong>Cookies:</strong> Small files placed on Your device
                containing browsing history details.
              </li>
              <li>
                <strong>Country:</strong> Refers to Tamil Nadu, India.
              </li>
              <li>
                <strong>Device:</strong> Any device that can access the Service
                such as a computer, cellphone, or tablet.
              </li>
              <li>
                <strong>Personal Data:</strong> Information that relates to an
                identified or identifiable individual.
              </li>
              <li>
                <strong>Service:</strong> Refers to the Website, Campushub,
                accessible from{" "}
                <a
                  href="https://campushub.thebettertomorrow.in/"
                  className="text-blue-600"
                >
                  Campushub
                </a>
                .
              </li>
              <li>
                <strong>Service Provider:</strong> A third-party entity that
                processes data on behalf of the Company.
              </li>
              <li>
                <strong>Usage Data:</strong> Data collected automatically during
                use of the Service.
              </li>
              <li>
                <strong>You:</strong> The individual accessing or using the
                Service.
              </li>
            </ul>
          </section>

          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">Consent</h2>
            <p className="text-gray-700">
              By using our Service, providing Personal Data, or completing
              transactions, You consent to our collection, use, and disclosure
              of Your information as described in this Privacy Policy.
            </p>
            <p className="text-gray-700">
              If You do not consent, You should not use the Service. You can
              withdraw Your consent at any time by contacting us at:
              <a
                href="mailto:info@thebettertomorrow.in"
                className="text-blue-600"
              >
                info@thebettertomorrow.in
              </a>
              .
            </p>
          </section>

          {/* Disclosure */}
          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">Disclosure</h2>
            <p className="text-gray-700">
              We may disclose Your Personal Data in the following situations:
            </p>
            <ul className="list-inside list-disc text-gray-700">
              <li>
                To comply with legal obligations or respond to valid requests
                from public authorities
              </li>
              <li>To protect and defend our rights or property</li>
              <li>
                In connection with business transactions such as mergers, sales,
                or acquisitions
              </li>
              <li>
                To Service Providers who help us operate or manage our Service
              </li>
            </ul>
          </section>

          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">
              Collecting and Using Your Personal Data
            </h2>

            <h3 className="text-xl font-semibold">Types of Data Collected</h3>
            <div className="space-y-4">
              <h4 className="text-lg font-semibold">Personal Data</h4>
              <p className="text-gray-700">
                We may ask You to provide certain personally identifiable
                information, which may include, but is not limited to:
              </p>
              <ul className="list-inside list-disc text-gray-700">
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Usage Data</li>
              </ul>
            </div>

            <div className="space-y-4">
              <h4 className="text-lg font-semibold">Usage Data</h4>
              <p className="text-gray-700">
                Usage Data is collected automatically when using the Service and
                may include:
              </p>
              <ul className="list-inside list-disc text-gray-700">
                <li>IP address, browser type, browser version</li>
                <li>Pages visited, time spent on pages</li>
                <li>Device identifiers and other diagnostic data</li>
              </ul>
            </div>

            <div className="space-y-4">
              <h4 className="text-lg font-semibold">
                Tracking Technologies and Cookies
              </h4>
              <p className="text-gray-700">
                We use Cookies and similar tracking technologies to improve and
                analyze the Service. These technologies may include:
              </p>
              <ul className="list-inside list-disc text-gray-700">
                <li>
                  <strong>Cookies:</strong> Small files placed on Your device.
                  You can refuse or delete Cookies through your browser
                  settings.
                </li>
                <li>
                  <strong>Web Beacons:</strong> Small electronic files used for
                  tracking website statistics and email interactions.
                </li>
              </ul>
            </div>
          </section>
          {/* Payment */}
          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">Payment</h2>
            <p className="text-gray-700">
              For processing payments, we use third-party payment processors
              such as Razorpay. We do not store Your payment details like
              credit/debit card information. All payment details are encrypted
              and securely processed by Razorpay in compliance with industry
              standards such as PCI-DSS.
            </p>
            <p className="text-gray-700">
              Razorpay's Privacy Policy can be accessed at:
              <a
                href="https://razorpay.com/privacy/"
                className="text-blue-600"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://razorpay.com/privacy/
              </a>
              .
            </p>
          </section>

          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">
              Use of Your Personal Data
            </h2>
            <p className="text-gray-700">
              The Company may use Your Personal Data for various purposes,
              including:
            </p>
            <ul className="list-inside list-disc text-gray-700">
              <li>Providing and maintaining our Service</li>
              <li>Managing your account and offering personalized features</li>
              <li>
                Contacting You regarding updates, offers, and security updates
              </li>
              <li>For business transfers such as mergers or acquisitions</li>
              <li>Improving our services and analyzing usage trends</li>
            </ul>
          </section>

          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">
              Security of Your Personal Data
            </h2>
            <p className="text-gray-700">
              While We strive to use commercially acceptable means to protect
              Your Personal Data, no method of transmission or storage is 100%
              secure.
            </p>
          </section>

          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">
              Changes to this Privacy Policy
            </h2>
            <p className="text-gray-700">
              We may update our Privacy Policy from time to time. Changes will
              be posted on this page with the updated "Last updated" date.
            </p>
          </section>

          <section className="space-y-6">
            <h2 className="text-2xl font-semibold">Contact Us</h2>
            <p className="text-gray-700">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <ul className="list-inside list-disc text-gray-700">
              <li>
                Email:{" "}
                <a
                  href="mailto:info@thebettertomorrow.in"
                  className="text-blue-600"
                >
                  info@thebettertomorrow.in
                </a>
              </li>
              <li>
                Website:{" "}
                <a
                  href="https://thebettertomorrow.in/"
                  className="text-blue-600"
                >
                  thebettertomorrow.in
                </a>
              </li>
              <li>Phone: 9750503595</li>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default Privacy;
