import React, { useEffect, useState } from "react";
import { Sidebar } from "flowbite-react";
import { RiAdminLine, RiBarChartGroupedLine } from "react-icons/ri";
import { FaBuildingColumns } from "react-icons/fa6";
import { HiChartPie, HiUser, HiViewBoards } from "react-icons/hi";
import { MdAssignment, MdGroups2, MdMessage } from "react-icons/md";
import { auth } from "../../../utils/firebase";
import { AiOutlineCluster } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  useGetAdminUsingEmailQuery,
  useGetStaffByEmailIdQuery,
} from "../../../graphql/generated.tsx";

function LeftSideBar() {
  const [currentPage, setCurrentPage] = useState(window.location.pathname);
  const [user, setUser] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [email, setEmail] = useState();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        setEmail(user.email);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const userRole = user
    ? JSON.parse(user?.reloadUserInfo?.customAttributes).role
    : null;

  const { data: admin } = useGetAdminUsingEmailQuery({
    variables: { email },
    skip: userRole != "bt-admin",
  });

  const { data: staff } = useGetStaffByEmailIdQuery({
    variables: { email },
    skip: userRole != "staff",
  });

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleLocationChange = () => setCurrentPage(window.location.pathname);
    window.addEventListener("popstate", handleLocationChange);
    return () => window.removeEventListener("popstate", handleLocationChange);
  }, []);

  const getSidebarItemClass = (path) =>
    currentPage.startsWith(path) ? "bg-gray-100 dark:bg-gray-700" : "";

  const customTheme = {
    root: {
      base: "bg-white",
      inner: "bg-white",
    },
  };

  return (
    <div className="lg:w-64 lg:flex-shrink-0 h-full">
      <Sidebar
        collapsed={isMobile}
        theme={customTheme}
        className="flex p-4 mt-3 fixed top-0 left-0 z-20 flex-col flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700"
      >
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {userRole === "staff" && (
              <Link to={"/admin"}>
                <Sidebar.Item
                  icon={MdGroups2}
                  className={
                    "/admin" === currentPage.toLowerCase() ||
                    "/admin/student" === currentPage.toLowerCase()
                      ? "bg-gray-100 dark:bg-gray-700"
                      : ""
                  }
                >
                  Course Report
                </Sidebar.Item>
              </Link>
            )}

            {userRole === "staff" && (
              <>
                <Link to={"/admin/student-details"}>
                  <Sidebar.Item
                    icon={RiBarChartGroupedLine}
                    className={
                      "/admin/student-details" === currentPage.toLowerCase()
                        ? "bg-gray-100 dark:bg-gray-700"
                        : ""
                    }
                  >
                    Overall Performance
                  </Sidebar.Item>
                </Link>
              </>
            )}
            {userRole === "bt-admin" && (
              <>
                {admin?.getAdminUsingEmail?.isSuperAdmin ? (
                  <>
                    <Link to="/admin/courses">
                      <Sidebar.Item
                        icon={HiViewBoards}
                        className={getSidebarItemClass("/admin/courses")}
                      >
                        Courses
                      </Sidebar.Item>
                    </Link>
                    <Link to="/admin/assignments">
                      <Sidebar.Item
                        icon={MdAssignment}
                        className={getSidebarItemClass("/admin/assignments")}
                      >
                        Assignments
                      </Sidebar.Item>
                    </Link>
                    <Link to="/admin/feedback">
                      <Sidebar.Item
                        icon={MdMessage}
                        className={getSidebarItemClass("/admin/feedback")}
                      >
                        Feedback
                      </Sidebar.Item>
                    </Link>
                  </>
                ) : (
                  <>
                    {admin?.getAdminUsingEmail?.permissions?.courses.length >
                      0 && (
                      <Link to="/admin/courses">
                        <Sidebar.Item
                          icon={HiViewBoards}
                          className={getSidebarItemClass("/admin/courses")}
                        >
                          Courses
                        </Sidebar.Item>
                      </Link>
                    )}
                    {admin?.getAdminUsingEmail?.permissions?.assignments
                      .length > 0 && (
                      <Link to="/admin/assignments">
                        <Sidebar.Item
                          icon={MdAssignment}
                          className={getSidebarItemClass("/admin/assignments")}
                        >
                          Assignments
                        </Sidebar.Item>
                      </Link>
                    )}
                  </>
                )}

                {admin?.getAdminUsingEmail?.isSuperAdmin && (
                  <>
                    <Link to="/admin/campus">
                      <Sidebar.Item
                        icon={FaBuildingColumns}
                        className={getSidebarItemClass("/admin/campus")}
                      >
                        Manage Staff
                      </Sidebar.Item>
                    </Link>

                    <Link to="/admin/bt-admin">
                      <Sidebar.Item
                        icon={RiAdminLine}
                        className={getSidebarItemClass("/admin/bt-admin")}
                      >
                        BT-Admin
                      </Sidebar.Item>
                    </Link>
                    <Link to="/admin/clusters/campus-list">
                      <Sidebar.Item
                        icon={AiOutlineCluster}
                        className={getSidebarItemClass("/admin/clusters")}
                      >
                        Manage Cluster
                      </Sidebar.Item>
                    </Link>
                  </>
                )}
                {admin?.getAdminUsingEmail?.isSuperAdmin ||
                admin?.getAdminUsingEmail?.permissions?.student_creation ? (
                  <Link to="/admin/students">
                    <Sidebar.Item
                      icon={HiUser}
                      className={getSidebarItemClass("/admin/students")}
                    >
                      Students Creation
                    </Sidebar.Item>
                  </Link>
                ) : null}
              </>
            )}
          </Sidebar.ItemGroup>

          <Sidebar.ItemGroup>
            {(userRole === "bt-admin" || userRole === "staff") &&
              (staff?.getStaffByEmailId?.isAdminStaff ||
              admin?.getAdminUsingEmail?.isSuperAdmin ||
              admin?.getAdminUsingEmail?.permissions?.reports ? (
                <Link to="/admin/reports">
                  <Sidebar.Item
                    icon={HiChartPie}
                    className={getSidebarItemClass("/admin/reports")}
                  >
                    Reports
                  </Sidebar.Item>
                </Link>
              ) : null)}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  );
}

export default LeftSideBar;
