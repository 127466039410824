import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Button,
} from "@mui/material";
import {
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import { useNavigate } from "react-router-dom";
import { MdOutlinePersonOff } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

const QuestionListingTable = ({
  data: questions,
  courseId,
  clusterid,
  handlePrevPage,
  handleNextPage,
  submissions,
  currentPage,
  totalPages,
}) => {
  const navigate = useNavigate();

  const getStatus = (submission) => {
    if (!submission) return "Awaiting";
    if (submission.is_completed) return "Completed";
    if (submission.submissions?.length > 0) return "In Progress";
    return "Awaiting";
  };

  function getDifficultyColor(difficulty) {
    switch (difficulty) {
      case "EASY":
        return "green";
      case "MEDIUM":
        return "#F5C650";
      case "HARD":
        return "red";
      default:
        return "gray";
    }
  }

  return (
    <Card className="bg-white border rounded-3xl ">
      <Table className="overflow-x-auto overflow-x-auto w-full block">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            <TableCell
              sx={{ fontWeight: "bold", color: "#333", fontSize: "18px" }}
            >
              Question Text
            </TableCell>
            <TableCell
              sx={{ fontWeight: "bold", color: "#333", fontSize: "18px" }}
              align="center"
            >
              Difficulty
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "#333", fontSize: "18px" }}
            >
              Your Score
            </TableCell>
            <TableCell
              align="center"
              sx={{ fontWeight: "bold", color: "#333", fontSize: "18px" }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={4}
                align="center"
                sx={{ fontWeight: "light", color: "#333", fontSize: "18px" }}
              >
                <div className="flex flex-col items-center justify-center h-[300px] text-center">
                  <FaSearch className="text-7xl text-gray-400 mb-4" />
                  <h2 className="text-2xl font-bold text-gray-600 mb-2">
                    Oops, No Results Found
                  </h2>
                  <p className="text-gray-500 mb-6 lg:w-[500px]">
                    It looks like we couldn't find any matching data. Try
                    refining the filter options.
                  </p>
                </div>
              </TableCell>
            </TableRow>
          ) : (
            questions.map((question, index) => {
              const submission = submissions[question.id];
              const status = getStatus(submission);
              const statusColor =
                status === "Completed"
                  ? "#4CAF50"
                  : status === "In Progress"
                  ? "#FFC107"
                  : status === "Awaiting"
                  ? "#A9A9A9" // Light gray
                  : "";

              return (
                <TableRow
                  key={question.id}
                  onClick={() =>
                    navigate(
                      `/student/courses/question/${clusterid}/${courseId}/${question?.id}`
                    )
                  }
                  sx={{
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    cursor: "pointer",
                    borderBottom: "1px solid #e0e0e0", // Thinner border
                  }}
                >
                  <TableCell
                    sx={{
                      "&:hover": {
                        color: "blue",
                        cursor: "pointer",
                      },
                    }}
                  >
                    {question.title}
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{ color: getDifficultyColor(question?.difficulty) }}
                  >
                    {question?.difficulty
                      ? question.difficulty.charAt(0).toUpperCase() +
                        question.difficulty.slice(1).toLowerCase()
                      : "Unknown"}
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>
                    {submission?.max_score || "-"}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: statusColor, fontWeight: "bold" }}
                  >
                    {status}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      <div className="flex items-center justify-between border-t border-blue-gray-50 bg-white p-4 rounded-3xl">
        <Button
          variant="outlined"
          size="small"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="mb-2 md:mb-0"
        >
          Previous
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </Card>
  );
};

export default QuestionListingTable;
