import React, { useState, useEffect } from "react";
import StudentLayout from "../Layout";
import { AssignmentsTable } from "../Components/AssignmentsTable";
import {
  useGetAssignmentQuery,
  useGetAssignmentLeaderBoardQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import { Spinner, Table } from "flowbite-react";
import { SkeletonRow } from "../Components/LeaderBoardSkeleton.jsx";
import { useParams } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const StudentAssignment = () => {
  const { assignmentId } = useParams();
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  const {
    data: studentData,
    loading: studentLoading,
    error: studentError,
  } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const campusId = studentData?.getStudentByEmailId?.campusId;
  const {
    data: campusData,
    loading: campusLoading,
    error: campusError,
  } = useGetCampusQuery({
    variables: { id: campusId },
    skip: !campusId,
  });

  const { data: leaderboard, loading } = useGetAssignmentLeaderBoardQuery({
    variables: { assignmentId, campus_name: campusData?.campus?.name },
  });
  const { data: AssignmentInfo } = useGetAssignmentQuery({
    variables: { id: assignmentId },
  });

  if (loading) {
    return (
      <StudentLayout>
        <div className="overflow-x-auto my-10 bg-white py-5 shadow-sm rounded-lg m-10">
          <Table className="min-w-full text-sm text-left text-gray-500">
            <Table.Head className="bg-gray-100">
              {[
                "Position",
                "Name",
                "Roll No",
                "Points",
                "Questions Attended",
                "Questions Completed",
                "Batch Enrolled",
                "Progress",
              ].map((heading) => (
                <Table.HeadCell className="py-3 px-6" key={heading}>
                  {heading}
                </Table.HeadCell>
              ))}
            </Table.Head>
            <Table.Body className="bg-white divide-y divide-gray-200">
              {[...Array(5)].map((_, index) => (
                <SkeletonRow col={8} />
              ))}
            </Table.Body>
          </Table>
        </div>
      </StudentLayout>
    );
  }

  return (
    <StudentLayout>
      <div className="m-10">
        <button
          onClick={() => navigate("/student/assignment-leaderboard")}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <span className="gap-2 text-sm font-medium flex items-center">
            <IoMdArrowRoundBack className="h-6 w-6" />
            Go Back
          </span>
        </button>
        <AssignmentsTable
          assignmentInfo={AssignmentInfo?.getAssignment}
          assignments={leaderboard?.getAssignmentLeaderBoard}
          assignmentsLoading={loading}
        />
      </div>
    </StudentLayout>
  );
};

export default StudentAssignment;
