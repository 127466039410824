import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddClusterToStudentsInput = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  courseIds: Array<Scalars['String']['input']>;
  studentIds: Array<Scalars['String']['input']>;
};

export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSuperAdmin?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<Permissions>;
};

export type AllQuestionsCount = {
  __typename?: 'AllQuestionsCount';
  count: Scalars['Float']['output'];
  difficulty?: Maybe<Scalars['String']['output']>;
};

export type Assignment = {
  __typename?: 'Assignment';
  _id: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['DateTime']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  enable_proctoring?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  languages: Array<Scalars['String']['output']>;
  max_cheat_count?: Maybe<Scalars['Float']['output']>;
  max_tabSwitches?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  short_description?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type AssignmentFilterInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type AssignmentInput = {
  assignment_id: Scalars['String']['input'];
  campus_name: Scalars['String']['input'];
  cheat_count: Scalars['Float']['input'];
  cluster_id: Scalars['String']['input'];
  student_id: Scalars['String']['input'];
  tabSwitches: Scalars['Float']['input'];
};

export type AssignmentLeaderBoard = {
  __typename?: 'AssignmentLeaderBoard';
  assignment_id: Scalars['String']['output'];
  clusterNames: Scalars['String']['output'];
  position: Scalars['Float']['output'];
  questions?: Maybe<Array<QuestionLeaderboard>>;
  student?: Maybe<Student>;
  totalNumberOfQuestion: Scalars['Float']['output'];
  totalScore: Scalars['Float']['output'];
};

export type AssignmentQuestion = {
  __typename?: 'AssignmentQuestion';
  _id: Scalars['String']['output'];
  assignment_id: Scalars['String']['output'];
  company_tags: Array<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  difficulty: Scalars['String']['output'];
  hints: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  marks: Scalars['String']['output'];
  predefinedCode?: Maybe<PredefinedLanguages>;
  question_sub_text?: Maybe<Scalars['String']['output']>;
  showFunctionOnly?: Maybe<Scalars['Boolean']['output']>;
  tags: Array<Scalars['String']['output']>;
  targetFunctionName?: Maybe<Scalars['String']['output']>;
  test_cases: Array<TestCase>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_by: Scalars['String']['output'];
};

export type AssignmentResults = {
  __typename?: 'AssignmentResults';
  assignment_id?: Maybe<Scalars['String']['output']>;
  student_id?: Maybe<Scalars['String']['output']>;
  totalScore?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentSubmit = {
  __typename?: 'AssignmentSubmit';
  assignment_id?: Maybe<Scalars['String']['output']>;
  autosave?: Maybe<Array<QuestionSubmission>>;
  cheat_count?: Maybe<Scalars['Float']['output']>;
  cluster_id?: Maybe<Scalars['String']['output']>;
  completed?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<QuestionSubmission>>;
  student_id?: Maybe<Scalars['String']['output']>;
  tabSwitches?: Maybe<Scalars['Float']['output']>;
  totalScore?: Maybe<Scalars['Float']['output']>;
};

export type AssignmentSubmitInput = {
  assignment_id: Scalars['String']['input'];
  campus_name: Scalars['String']['input'];
  cluster_id: Scalars['String']['input'];
  question_id: Scalars['String']['input'];
  student_id: Scalars['String']['input'];
};

export type Badge = {
  __typename?: 'Badge';
  creationDate?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Campus = {
  __typename?: 'Campus';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Categories = {
  __typename?: 'Categories';
  _id: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CategoryDto = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CategoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Cluster = {
  __typename?: 'Cluster';
  assignmentIds?: Maybe<Array<Scalars['String']['output']>>;
  campusName: Scalars['String']['output'];
  courseIds?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  sendEmail?: Maybe<Scalars['Boolean']['output']>;
  staffs?: Maybe<Array<StaffType>>;
  studentCount: Scalars['Float']['output'];
};

export type CompilationOutput = {
  __typename?: 'CompilationOutput';
  compilation_time: Scalars['Float']['output'];
  stderr: Scalars['String']['output'];
  stdout: Scalars['String']['output'];
};

export type CompilationResults = {
  __typename?: 'CompilationResults';
  error: Scalars['String']['output'];
  expextedOutput: Scalars['String']['output'];
  input: Scalars['String']['output'];
  output: Scalars['String']['output'];
};

export type CompilerInput = {
  code: Scalars['String']['input'];
  language: Scalars['String']['input'];
  questionid: Scalars['String']['input'];
  testCases: Array<TestCaseInput>;
};

export type CompilerOutput = {
  __typename?: 'CompilerOutput';
  Results: Array<CompilationResults>;
  compilationOutput?: Maybe<CompilationOutput>;
  testCaseFailed?: Maybe<TestCaseDetails>;
  testCasePassed?: Maybe<TestCaseDetails>;
};

export type Contributions = {
  __typename?: 'Contributions';
  points: Scalars['Float']['output'];
  questionCount: Scalars['Float']['output'];
  testcaseCount: Scalars['Float']['output'];
};

export type Course = {
  __typename?: 'Course';
  _id: Scalars['String']['output'];
  categories?: Maybe<Array<Category>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  domain: Scalars['String']['output'];
  easy_count: Scalars['Float']['output'];
  hard_count: Scalars['Float']['output'];
  id?: Maybe<Scalars['String']['output']>;
  languages: Array<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  medium_count: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  question_ids?: Maybe<Array<Scalars['String']['output']>>;
  short_description?: Maybe<Scalars['String']['output']>;
  time_period?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type CourseDetails = {
  __typename?: 'CourseDetails';
  completedQuestion: Scalars['String']['output'];
  courseId: Scalars['ID']['output'];
  courseName?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  percentageCompleted: Scalars['Float']['output'];
  totalQuestion: Scalars['String']['output'];
};

export type CourseResults = {
  __typename?: 'CourseResults';
  courseDetails: Array<CourseDetails>;
  rewardPoints: Scalars['String']['output'];
};

export type CreateAdminInput = {
  email: Scalars['String']['input'];
  isSuperAdmin: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  permissions: PermissionsInput;
};

export type CreateAdminResponse = {
  __typename?: 'CreateAdminResponse';
  admin: Admin;
  message: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type CreateAssignmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  due_date?: InputMaybe<Scalars['DateTime']['input']>;
  duration: Scalars['Float']['input'];
  enable_proctoring?: InputMaybe<Scalars['Boolean']['input']>;
  languages: Array<Scalars['String']['input']>;
  max_cheat_count: Scalars['Float']['input'];
  max_tabSwitches: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  short_description?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAssignmentQuestionInput = {
  assignment_id: Scalars['String']['input'];
  company_tags: Array<Scalars['String']['input']>;
  difficulty: Scalars['String']['input'];
  hints: Array<Scalars['String']['input']>;
  marks: Scalars['String']['input'];
  predefinedCode?: InputMaybe<PredefinedLanguagesInput>;
  question_sub_text?: InputMaybe<Scalars['String']['input']>;
  showFunctionOnly?: InputMaybe<Scalars['Boolean']['input']>;
  tags: Array<Scalars['String']['input']>;
  targetFunctionName?: InputMaybe<Scalars['String']['input']>;
  test_cases: Array<TestCaseInput>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateCampusInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateClusterInput = {
  campusName: Scalars['String']['input'];
  courseIds: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCourseDto = {
  categories: Array<CategoryDto>;
  description: Scalars['String']['input'];
  domain: Scalars['String']['input'];
  easy_count?: Scalars['Float']['input'];
  hard_count?: Scalars['Float']['input'];
  languages: Array<Scalars['String']['input']>;
  level: Scalars['String']['input'];
  medium_count?: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  question_ids?: Array<Scalars['String']['input']>;
  short_description: Scalars['String']['input'];
  time_period: Scalars['Float']['input'];
};

export type CreatePaymentInput = {
  amount_paid: Scalars['Float']['input'];
  cluster_id: Scalars['String']['input'];
  course_id: Scalars['String']['input'];
  order_id: Scalars['String']['input'];
  payment_id: Scalars['String']['input'];
  student_id: Scalars['String']['input'];
};

export type CreateQuestionInput = {
  assignment_ids?: Array<Scalars['String']['input']>;
  category: QuestionCategoryInput;
  company_tags: Array<Scalars['String']['input']>;
  course_id: Scalars['String']['input'];
  difficulty: Scalars['String']['input'];
  hints: Array<Scalars['String']['input']>;
  marks: Scalars['String']['input'];
  predefinedCode?: InputMaybe<PredefinedLanguagesInput>;
  question_sub_text?: InputMaybe<Scalars['String']['input']>;
  showFunctionOnly?: InputMaybe<Scalars['Boolean']['input']>;
  tags: Array<Scalars['String']['input']>;
  targetFunctionName?: InputMaybe<Scalars['String']['input']>;
  test_cases: Array<TestCaseInput>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateStaffInput = {
  campusId: Scalars['String']['input'];
  clusterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
  isAdminStaff: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateStaffResponse = {
  __typename?: 'CreateStaffResponse';
  message: Scalars['String']['output'];
  staff: StaffType;
  uid: Scalars['String']['output'];
};

export type CreateStudentInput = {
  campusId: Scalars['String']['input'];
  clusterIds: Array<Scalars['String']['input']>;
  courseIds?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['String']['input'];
};

export type CreateStudentResponse = {
  __typename?: 'CreateStudentResponse';
  message: Scalars['String']['output'];
  student: Student;
  uid: Scalars['String']['output'];
};

export type DashboardCourseDetails = {
  __typename?: 'DashboardCourseDetails';
  courseDetails: Array<CourseDetails>;
  rewardPoints: Scalars['String']['output'];
};

export type EmailInput = {
  assignmentName?: InputMaybe<Scalars['String']['input']>;
  csvContent: Scalars['String']['input'];
  emailList: Array<Scalars['String']['input']>;
};

export type GetStudentsByCampusResponseType = {
  __typename?: 'GetStudentsByCampusResponseType';
  students: Array<Student>;
  total: Scalars['Float']['output'];
};

export type LeaderBoardResponse = {
  __typename?: 'LeaderBoardResponse';
  completedQuestions: Scalars['Float']['output'];
  easyQuestionsCompleted?: Maybe<Scalars['Float']['output']>;
  hardQuestionCompleted?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  latestSubmissionDate?: Maybe<Scalars['DateTime']['output']>;
  leetcode_data?: Maybe<Array<LeetCodeQuestionCount>>;
  mediumQuestionsCompleted?: Maybe<Scalars['Float']['output']>;
  position: Scalars['Float']['output'];
  student?: Maybe<Student>;
  student_id: Scalars['String']['output'];
  totalScore: Scalars['Float']['output'];
};

export type LeetCodeQuestionCount = {
  __typename?: 'LeetCodeQuestionCount';
  count: Scalars['Float']['output'];
  difficulty: Scalars['String']['output'];
  submissions: Scalars['Float']['output'];
};

export type LeetcodeData = {
  __typename?: 'LeetcodeData';
  allQuestionsCount?: Maybe<Array<AllQuestionsCount>>;
  matchedUser?: Maybe<MatchedUser>;
  recentSubmissionList?: Maybe<Array<RecentSubmission>>;
};

export type MatchedUser = {
  __typename?: 'MatchedUser';
  activeBadge?: Maybe<Badge>;
  badges?: Maybe<Array<Badge>>;
  contributions?: Maybe<Contributions>;
  githubUrl?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  profile?: Maybe<Profile>;
  submissionCalendar?: Maybe<Scalars['String']['output']>;
  submitStats?: Maybe<SubmitStats>;
  twitterUrl?: Maybe<Scalars['String']['output']>;
  upcomingBadges?: Maybe<Array<UpcomingBadge>>;
  username?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAssignmentToCluster: Cluster;
  addClusterToStudents: Array<Student>;
  addCoursesToCluster: Cluster;
  addQuestionsToCourse: Course;
  addStaffToCluster: Array<StaffResponse>;
  bulkUploadStudents: Array<CreateStudentResponse>;
  createAdmin: CreateAdminResponse;
  createAssignment: Assignment;
  createAssignmentQuestion: AssignmentQuestion;
  createBulkAssignmentQuestions: Array<AssignmentQuestion>;
  createCampus: Campus;
  createCluster: Cluster;
  createCourse: Course;
  createQuestion: Question;
  createRazorpayOrder: Scalars['String']['output'];
  createStaff: CreateStaffResponse;
  deleteAdmin: Scalars['Boolean']['output'];
  deleteAssignmentQuestion: Scalars['Boolean']['output'];
  deleteBulkStudents: Scalars['String']['output'];
  deleteQuestion: Scalars['Boolean']['output'];
  deleteStaff: Scalars['Boolean']['output'];
  deleteStudent: Scalars['String']['output'];
  finalSubmitAssignment: AssignmentSubmit;
  removeAssignment: Assignment;
  removeAssignmentFromCluster: Cluster;
  removeAssignmentFromStudent: Student;
  removeClusterFromStudents: Array<Student>;
  removeCourse: Course;
  removeCourseFromCluster: Cluster;
  removeStaffFromCluster: Array<RemoveStaffResponse>;
  saveAssignmentCode: Scalars['String']['output'];
  saveAutoSave: Scalars['String']['output'];
  savePaymentDetails: Payment;
  sendEmail: Scalars['String']['output'];
  sendInviteEmailToStaff: Scalars['String']['output'];
  sendInviteEmailToStudent: Scalars['String']['output'];
  submitAssignmentCode: CompilerOutput;
  submitCode: CompilerOutput;
  toggleSendEmail: Cluster;
  updateAdmin: Admin;
  updateAssignment: Assignment;
  updateAssignmentQuestion: AssignmentQuestion;
  updateClusterName: Cluster;
  updateCourse: Course;
  updateQuestion: Question;
  updateStaff: StaffType;
  updateStudent: Student;
};


export type MutationAddAssignmentToClusterArgs = {
  assignmentId: Scalars['String']['input'];
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
};


export type MutationAddClusterToStudentsArgs = {
  input: AddClusterToStudentsInput;
};


export type MutationAddCoursesToClusterArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  courseIds: Array<Scalars['String']['input']>;
};


export type MutationAddQuestionsToCourseArgs = {
  courseId: Scalars['String']['input'];
  questionIds: Array<Scalars['String']['input']>;
};


export type MutationAddStaffToClusterArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  staffs: Array<UpdateStaffInput>;
};


export type MutationBulkUploadStudentsArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  students: Array<CreateStudentInput>;
};


export type MutationCreateAdminArgs = {
  createAdminInput: CreateAdminInput;
};


export type MutationCreateAssignmentArgs = {
  createAssignmentInput: CreateAssignmentInput;
};


export type MutationCreateAssignmentQuestionArgs = {
  createQuestionInput: CreateAssignmentQuestionInput;
};


export type MutationCreateBulkAssignmentQuestionsArgs = {
  createQuestionsInput: Array<CreateAssignmentQuestionInput>;
};


export type MutationCreateCampusArgs = {
  createCampusInput: CreateCampusInput;
};


export type MutationCreateClusterArgs = {
  createClusterInput: CreateClusterInput;
};


export type MutationCreateCourseArgs = {
  createCourseInput: CreateCourseDto;
};


export type MutationCreateQuestionArgs = {
  createQuestionInput: CreateQuestionInput;
};


export type MutationCreateRazorpayOrderArgs = {
  orderAmount: Scalars['Float']['input'];
  orderId: Scalars['String']['input'];
};


export type MutationCreateStaffArgs = {
  createStaffInput: CreateStaffInput;
};


export type MutationDeleteAdminArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteAssignmentQuestionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteBulkStudentsArgs = {
  campusName: Scalars['String']['input'];
  studentIds: Array<Scalars['String']['input']>;
};


export type MutationDeleteQuestionArgs = {
  course_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationDeleteStaffArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteStudentArgs = {
  campusName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationFinalSubmitAssignmentArgs = {
  AssignmentInput: AssignmentInput;
};


export type MutationRemoveAssignmentArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveAssignmentFromClusterArgs = {
  assignmentId: Scalars['String']['input'];
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
};


export type MutationRemoveAssignmentFromStudentArgs = {
  removeAssignmentInput: RemoveAssignmentInput;
};


export type MutationRemoveClusterFromStudentsArgs = {
  input: RemoveClusterFromStudentsInput;
};


export type MutationRemoveCourseArgs = {
  id: Scalars['String']['input'];
};


export type MutationRemoveCourseFromClusterArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  courseId: Scalars['String']['input'];
};


export type MutationRemoveStaffFromClusterArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  staffIds: Array<Scalars['String']['input']>;
};


export type MutationSaveAssignmentCodeArgs = {
  code: Scalars['String']['input'];
  language: Scalars['String']['input'];
  saveCodeInput: AssignmentSubmitInput;
};


export type MutationSaveAutoSaveArgs = {
  code: Scalars['String']['input'];
  language: Scalars['String']['input'];
  submitInput: SubmitInput;
};


export type MutationSavePaymentDetailsArgs = {
  createPaymentInput: CreatePaymentInput;
};


export type MutationSendEmailArgs = {
  emailInput: EmailInput;
};


export type MutationSendInviteEmailToStaffArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type MutationSendInviteEmailToStudentArgs = {
  emails: Array<Scalars['String']['input']>;
};


export type MutationSubmitAssignmentCodeArgs = {
  assignmentCompilerCodeInput: CompilerInput;
  assignmentSubmitInput: AssignmentSubmitInput;
};


export type MutationSubmitCodeArgs = {
  compilerCodeInput: CompilerInput;
  submitInput: SubmitInput;
};


export type MutationToggleSendEmailArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  sendEmail: Scalars['Boolean']['input'];
};


export type MutationUpdateAdminArgs = {
  updateAdminInput: UpdateAdminInput;
};


export type MutationUpdateAssignmentArgs = {
  id: Scalars['String']['input'];
  updateAssignmentInput: UpdateAssignmentInput;
};


export type MutationUpdateAssignmentQuestionArgs = {
  id: Scalars['String']['input'];
  updateQuestionInput: UpdateAssignmentQuestionInput;
};


export type MutationUpdateClusterNameArgs = {
  updateClusterNameInput: UpdateClusterNameInput;
};


export type MutationUpdateCourseArgs = {
  id: Scalars['String']['input'];
  updateCourseInput: UpdateCourseDto;
};


export type MutationUpdateQuestionArgs = {
  id: Scalars['String']['input'];
  updateQuestionInput: UpdateQuestionInput;
};


export type MutationUpdateStaffArgs = {
  updateStaffInput: UpdateStaffInput;
};


export type MutationUpdateStudentArgs = {
  updateStudentInput: UpdateStudentInput;
};

export type Payment = {
  __typename?: 'Payment';
  _id: Scalars['String']['output'];
  amount_paid: Scalars['Float']['output'];
  cluster_id: Scalars['String']['output'];
  course_id: Scalars['String']['output'];
  date_of_purchase: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order_id: Scalars['String']['output'];
  payment_id: Scalars['String']['output'];
  student_id: Scalars['ID']['output'];
};

export type Permissions = {
  __typename?: 'Permissions';
  assignments?: Maybe<Array<Scalars['String']['output']>>;
  courses?: Maybe<Array<Scalars['String']['output']>>;
  reports?: Maybe<Scalars['Boolean']['output']>;
  student_creation?: Maybe<Scalars['Boolean']['output']>;
};

export type PermissionsInput = {
  assignments?: InputMaybe<Array<Scalars['String']['input']>>;
  courses?: InputMaybe<Array<Scalars['String']['input']>>;
  reports?: InputMaybe<Scalars['Boolean']['input']>;
  student_creation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PredefinedLanguages = {
  __typename?: 'PredefinedLanguages';
  c: Scalars['String']['output'];
  cpp: Scalars['String']['output'];
  java: Scalars['String']['output'];
  python: Scalars['String']['output'];
};

export type PredefinedLanguagesInput = {
  c: Scalars['String']['input'];
  cpp: Scalars['String']['input'];
  java: Scalars['String']['input'];
  python: Scalars['String']['input'];
};

export type Profile = {
  __typename?: 'Profile';
  aboutMe?: Maybe<Scalars['String']['output']>;
  birthday?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  ranking: Scalars['Float']['output'];
  realName?: Maybe<Scalars['String']['output']>;
  reputation: Scalars['Float']['output'];
  school?: Maybe<Scalars['String']['output']>;
  skillTags?: Maybe<Array<Scalars['String']['output']>>;
  starRating?: Maybe<Scalars['Float']['output']>;
  userAvatar?: Maybe<Scalars['String']['output']>;
  websites: Array<Scalars['String']['output']>;
};

export type PurchasedCourse = {
  __typename?: 'PurchasedCourse';
  cluster_id: Scalars['String']['output'];
  course_id: Scalars['String']['output'];
};

export type PurchasedCourseInput = {
  cluster_id: Scalars['String']['input'];
  course_id: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  allCampus: Array<Campus>;
  allCategories: Array<Categories>;
  campus: Campus;
  compileCode: CompilerOutput;
  findAllQuestions: Array<Question>;
  findAssignmentsForStudentWithFilter: Array<Assignment>;
  getAdminUsingEmail: Admin;
  getAllAdmin: Array<Admin>;
  getAllAssignmentQuestion: Array<AssignmentQuestion>;
  getAllAssignments: Array<Assignment>;
  getAllCourses: Array<Course>;
  getAllCoursesByCluster: Array<Course>;
  getAllQuestion: QuestionResponse;
  getAllStaffs: Array<StaffType>;
  getAllStudents: Array<Student>;
  getAssignment: Assignment;
  getAssignmentByCampus: Array<Assignment>;
  getAssignmentByCluster: Array<Assignment>;
  getAssignmentLeaderBoard: Array<AssignmentLeaderBoard>;
  getAssignmentQuestion: AssignmentQuestion;
  getAssignmentQuestionsByTags: Array<AssignmentQuestion>;
  getAssignmentSubmit?: Maybe<AssignmentSubmit>;
  getAssignmentsForStudent: Array<Assignment>;
  getCampusByName: Campus;
  getCluster: Cluster;
  getClustersByCampus: Array<Cluster>;
  getClustersByIdsAndCampus: Array<Cluster>;
  getCourse: Course;
  getCoursesByStudentEmail: Array<Course>;
  getDashboardCourseDetails: DashboardCourseDetails;
  getLastFiveSubmissions: Submit;
  getLeaderboard: Array<LeaderBoardResponse>;
  getQuestion: Question;
  getQuestionCompleted: QuestionCompletionResult;
  getQuestionsByCategory: QuestionResponse;
  getQuestionsByTags: Array<Question>;
  getStaff: StaffType;
  getStaffByEmailId: StaffType;
  getStaffsByCampus: Array<StaffType>;
  getStudent?: Maybe<Student>;
  getStudentAndCampusDetailsByAssignmentId: Array<StudentCampusDto>;
  getStudentAssignmentResults: Array<AssignmentSubmit>;
  getStudentByEmailId: Student;
  getStudentSubmissionsForMonth: Array<SubmissionData>;
  getStudentSubmissionsForTimeRange: Array<SubmissionData>;
  getStudentsByCampus: GetStudentsByCampusResponseType;
  getStudentsByCluster: Array<Student>;
  getStudentsResults: StudentResults;
  leetcodeData: LeetcodeData;
  studentsNotAttendedAssignment: Array<StudentWithClusterInfo>;
};


export type QueryCampusArgs = {
  id: Scalars['String']['input'];
};


export type QueryCompileCodeArgs = {
  compileCodeInput: CompilerInput;
};


export type QueryFindAllQuestionsArgs = {
  difficulties?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchName?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryFindAssignmentsForStudentWithFilterArgs = {
  customDateRange?: InputMaybe<AssignmentFilterInput>;
  email: Scalars['String']['input'];
  filterType?: Scalars['String']['input'];
};


export type QueryGetAdminUsingEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetAllAssignmentQuestionArgs = {
  assignmentId: Scalars['String']['input'];
  limit?: Scalars['Int']['input'];
  page?: Scalars['Int']['input'];
};


export type QueryGetAllCoursesByClusterArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
};


export type QueryGetAllQuestionArgs = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  courseId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetAssignmentArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssignmentByCampusArgs = {
  campusName: Scalars['String']['input'];
};


export type QueryGetAssignmentByClusterArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
};


export type QueryGetAssignmentLeaderBoardArgs = {
  assignmentId: Scalars['String']['input'];
  campus_name: Scalars['String']['input'];
};


export type QueryGetAssignmentQuestionArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetAssignmentQuestionsByTagsArgs = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  tags: Array<Scalars['String']['input']>;
};


export type QueryGetAssignmentSubmitArgs = {
  assignmentId: Scalars['String']['input'];
  campus_name: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};


export type QueryGetAssignmentsForStudentArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetCampusByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryGetClusterArgs = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
};


export type QueryGetClustersByCampusArgs = {
  campusName: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetClustersByIdsAndCampusArgs = {
  campusName: Scalars['String']['input'];
  clusterIds: Array<Scalars['String']['input']>;
};


export type QueryGetCourseArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetCoursesByStudentEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetDashboardCourseDetailsArgs = {
  is_paid?: Scalars['Boolean']['input'];
  student_id: Scalars['String']['input'];
};


export type QueryGetLastFiveSubmissionsArgs = {
  submitInput: SubmitInput;
};


export type QueryGetLeaderboardArgs = {
  campusName: Scalars['String']['input'];
  clusterId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetQuestionArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetQuestionCompletedArgs = {
  is_paid?: Scalars['Boolean']['input'];
  student_id: Scalars['String']['input'];
};


export type QueryGetQuestionsByCategoryArgs = {
  categoryId: Scalars['String']['input'];
  courseId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchName?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetQuestionsByTagsArgs = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  tags: Array<Scalars['String']['input']>;
};


export type QueryGetStaffArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetStaffByEmailIdArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetStaffsByCampusArgs = {
  campusId: Scalars['String']['input'];
};


export type QueryGetStudentArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetStudentAndCampusDetailsByAssignmentIdArgs = {
  assignmentId: Scalars['String']['input'];
};


export type QueryGetStudentAssignmentResultsArgs = {
  student_id: Scalars['String']['input'];
};


export type QueryGetStudentByEmailIdArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetStudentSubmissionsForMonthArgs = {
  campusName: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  studentId: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};


export type QueryGetStudentSubmissionsForTimeRangeArgs = {
  campusName: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
  timeRangeInMonths: Scalars['Int']['input'];
};


export type QueryGetStudentsByCampusArgs = {
  campusId: Scalars['String']['input'];
  clusterid?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStudentsByClusterArgs = {
  clusterId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetStudentsResultsArgs = {
  campusId: Scalars['String']['input'];
  clusterid?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLeetcodeDataArgs = {
  username: Scalars['String']['input'];
};


export type QueryStudentsNotAttendedAssignmentArgs = {
  assignmentId: Scalars['String']['input'];
  campus_id: Scalars['String']['input'];
  cluster_id?: InputMaybe<Scalars['String']['input']>;
};

export type Question = {
  __typename?: 'Question';
  _id: Scalars['String']['output'];
  assignment_ids?: Maybe<Array<Scalars['String']['output']>>;
  category: QuestionCategory;
  company_tags: Array<Scalars['String']['output']>;
  course_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by?: Maybe<Scalars['String']['output']>;
  difficulty: Scalars['String']['output'];
  hints: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  marks: Scalars['String']['output'];
  predefinedCode?: Maybe<PredefinedLanguages>;
  question_sub_text?: Maybe<Scalars['String']['output']>;
  showFunctionOnly?: Maybe<Scalars['Boolean']['output']>;
  tags: Array<Scalars['String']['output']>;
  targetFunctionName?: Maybe<Scalars['String']['output']>;
  test_cases: Array<TestCase>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  updated_by?: Maybe<Scalars['String']['output']>;
};

export type QuestionCategory = {
  __typename?: 'QuestionCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type QuestionCategoryInput = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type QuestionCompletionResult = {
  __typename?: 'QuestionCompletionResult';
  easy: QuestionCount;
  hard: QuestionCount;
  medium: QuestionCount;
  overall: QuestionCount;
};

export type QuestionCount = {
  __typename?: 'QuestionCount';
  completed: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type QuestionLeaderboard = {
  __typename?: 'QuestionLeaderboard';
  AllotedTotalMarks: Scalars['Float']['output'];
  questionId: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  questions: Array<Question>;
  totalCount: Scalars['Int']['output'];
};

export type QuestionSubmission = {
  __typename?: 'QuestionSubmission';
  answer: Scalars['String']['output'];
  language: Scalars['String']['output'];
  question_id: Scalars['String']['output'];
  score: Scalars['Float']['output'];
};

export type RecentSubmission = {
  __typename?: 'RecentSubmission';
  lang?: Maybe<Scalars['String']['output']>;
  statusDisplay?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleSlug?: Maybe<Scalars['String']['output']>;
};

export type RemoveAssignmentInput = {
  assignmentId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type RemoveClusterFromStudentsInput = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  courseIds: Array<Scalars['String']['input']>;
  studentIds: Array<Scalars['String']['input']>;
};

export type RemoveStaffResponse = {
  __typename?: 'RemoveStaffResponse';
  removed: Scalars['Boolean']['output'];
  staffId: Scalars['String']['output'];
};

export type StaffResponse = {
  __typename?: 'StaffResponse';
  added: Scalars['Boolean']['output'];
  staff?: Maybe<StaffType>;
};

export type StaffType = {
  __typename?: 'StaffType';
  campusId: Scalars['String']['output'];
  clusterIds?: Maybe<Array<Scalars['String']['output']>>;
  contactNo?: Maybe<Scalars['String']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAdminStaff?: Maybe<Scalars['Boolean']['output']>;
  isInviteSend: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Student = {
  __typename?: 'Student';
  assignmentIds: Array<Scalars['String']['output']>;
  campusId: Scalars['String']['output'];
  clusterIds: Array<Scalars['String']['output']>;
  completedQuestions?: Maybe<Array<Scalars['String']['output']>>;
  courseIds: Array<Scalars['String']['output']>;
  dob: Scalars['String']['output'];
  email: Scalars['String']['output'];
  hackerRankUname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isInviteSend: Scalars['Boolean']['output'];
  leetCodeUname: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phno: Scalars['String']['output'];
  placeOfResidence: Scalars['String']['output'];
  purchasedCourses?: Maybe<Array<Maybe<PurchasedCourse>>>;
  rollNo: Scalars['String']['output'];
};

export type StudentCampusDto = {
  __typename?: 'StudentCampusDTO';
  campus: Campus;
  student: Student;
};

export type StudentPersonalDetails = {
  __typename?: 'StudentPersonalDetails';
  assignmentDetails: Array<AssignmentResults>;
  courseDetails: CourseResults;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rollNo: Scalars['String']['output'];
};

export type StudentResults = {
  __typename?: 'StudentResults';
  students: Array<StudentPersonalDetails>;
  total: Scalars['Float']['output'];
};

export type StudentWithClusterInfo = {
  __typename?: 'StudentWithClusterInfo';
  cluster_names: Scalars['String']['output'];
  student_email: Scalars['String']['output'];
  student_id: Scalars['String']['output'];
  student_name: Scalars['String']['output'];
  student_rollno: Scalars['String']['output'];
};

export type Submission = {
  __typename?: 'Submission';
  submittedAt: Scalars['DateTime']['output'];
};

export type SubmissionData = {
  __typename?: 'SubmissionData';
  courseName?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  submissions: Array<Submission>;
};

export type SubmissionDetail = {
  __typename?: 'SubmissionDetail';
  code: Scalars['String']['output'];
  compilation_time?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
  language?: Maybe<Scalars['String']['output']>;
  score: Scalars['Float']['output'];
  submittedAt: Scalars['DateTime']['output'];
};

export type Submit = {
  __typename?: 'Submit';
  autosave?: Maybe<SubmissionDetail>;
  cluster_id: Scalars['String']['output'];
  course_id: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_completed: Scalars['Boolean']['output'];
  max_score: Scalars['Float']['output'];
  min_compilation_time?: Maybe<Scalars['Float']['output']>;
  question_id: Scalars['String']['output'];
  student_id: Scalars['String']['output'];
  submissions: Array<SubmissionDetail>;
};

export type SubmitInput = {
  campus_name: Scalars['String']['input'];
  cluster_id: Scalars['String']['input'];
  course_id: Scalars['String']['input'];
  question_id: Scalars['String']['input'];
  student_id: Scalars['String']['input'];
};

export type SubmitStats = {
  __typename?: 'SubmitStats';
  acSubmissionNum?: Maybe<Array<SubmitStatsNum>>;
  totalSubmissionNum?: Maybe<Array<SubmitStatsNum>>;
};

export type SubmitStatsNum = {
  __typename?: 'SubmitStatsNum';
  count?: Maybe<Scalars['Float']['output']>;
  difficulty?: Maybe<Scalars['String']['output']>;
  submissions?: Maybe<Scalars['Float']['output']>;
};

export type TestCase = {
  __typename?: 'TestCase';
  id?: Maybe<Scalars['ID']['output']>;
  input: Scalars['String']['output'];
  output: Scalars['String']['output'];
  visibility: Scalars['String']['output'];
  weightage: Scalars['Float']['output'];
};

export type TestCaseDetails = {
  __typename?: 'TestCaseDetails';
  count: Scalars['Float']['output'];
  testCaseIds: Array<TestCaseOutputFormat>;
};

export type TestCaseInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: Scalars['String']['input'];
  output: Scalars['String']['input'];
  visibility: Scalars['String']['input'];
  weightage: Scalars['Float']['input'];
};

export type TestCaseOutputFormat = {
  __typename?: 'TestCaseOutputFormat';
  runtime: Scalars['Float']['output'];
  stderr?: Maybe<Scalars['String']['output']>;
  stdout?: Maybe<Scalars['String']['output']>;
  testCaseId: Scalars['Float']['output'];
};

export type UpcomingBadge = {
  __typename?: 'UpcomingBadge';
  icon?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type UpdateAdminInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isSuperAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<PermissionsInput>;
};

export type UpdateAssignmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['DateTime']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  enable_proctoring?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  max_cheat_count?: InputMaybe<Scalars['Float']['input']>;
  max_tabSwitches?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedBy?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAssignmentQuestionInput = {
  assignment_id?: InputMaybe<Scalars['String']['input']>;
  company_tags?: InputMaybe<Array<Scalars['String']['input']>>;
  difficulty?: InputMaybe<Scalars['String']['input']>;
  hints?: InputMaybe<Array<Scalars['String']['input']>>;
  marks?: InputMaybe<Scalars['String']['input']>;
  predefinedCode?: InputMaybe<PredefinedLanguagesInput>;
  question_sub_text?: InputMaybe<Scalars['String']['input']>;
  showFunctionOnly?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  targetFunctionName?: InputMaybe<Scalars['String']['input']>;
  test_cases?: InputMaybe<Array<TestCaseInput>>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClusterNameInput = {
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  newName: Scalars['String']['input'];
};

export type UpdateCourseDto = {
  categories?: InputMaybe<Array<InputMaybe<CategoryInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  level?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  short_description?: InputMaybe<Scalars['String']['input']>;
  time_period?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateQuestionInput = {
  assignment_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  category?: InputMaybe<QuestionCategoryInput>;
  company_tags?: InputMaybe<Array<Scalars['String']['input']>>;
  course_id?: InputMaybe<Scalars['String']['input']>;
  difficulty?: InputMaybe<Scalars['String']['input']>;
  hints?: InputMaybe<Array<Scalars['String']['input']>>;
  marks?: InputMaybe<Scalars['String']['input']>;
  predefinedCode?: InputMaybe<PredefinedLanguagesInput>;
  question_sub_text?: InputMaybe<Scalars['String']['input']>;
  showFunctionOnly?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  targetFunctionName?: InputMaybe<Scalars['String']['input']>;
  test_cases?: InputMaybe<Array<TestCaseInput>>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStaffInput = {
  clusterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  contactNo?: InputMaybe<Scalars['String']['input']>;
  designation?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  isAdminStaff?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStudentInput = {
  assignmentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  courseIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  hackerRankUname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  leetCodeUname?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phno?: InputMaybe<Scalars['String']['input']>;
  placeOfResidence?: InputMaybe<Scalars['String']['input']>;
  purchasedCourses?: InputMaybe<Array<InputMaybe<PurchasedCourseInput>>>;
  rollNo?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCampusMutationVariables = Exact<{
  createCampusInput: CreateCampusInput;
}>;


export type CreateCampusMutation = { __typename?: 'Mutation', createCampus: { __typename?: 'Campus', id: string, name: string } };

export type CreateClusterMutationVariables = Exact<{
  createClusterInput: CreateClusterInput;
}>;


export type CreateClusterMutation = { __typename?: 'Mutation', createCluster: { __typename?: 'Cluster', id: string, name?: string | null, courseIds?: Array<string> | null } };

export type CreateStaffMutationVariables = Exact<{
  createStaffInput: CreateStaffInput;
}>;


export type CreateStaffMutation = { __typename?: 'Mutation', createStaff: { __typename?: 'CreateStaffResponse', uid: string, message: string, staff: { __typename?: 'StaffType', id: string, name: string, email: string, campusId: string } } };

export type DeleteStaffMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteStaffMutation = { __typename?: 'Mutation', deleteStaff: boolean };

export type SendInviteEmailToStaffMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SendInviteEmailToStaffMutation = { __typename?: 'Mutation', sendInviteEmailToStaff: string };

export type SendInviteEmailToStudentMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SendInviteEmailToStudentMutation = { __typename?: 'Mutation', sendInviteEmailToStudent: string };

export type DeleteStudentMutationVariables = Exact<{
  id: Scalars['String']['input'];
  campusName: Scalars['String']['input'];
}>;


export type DeleteStudentMutation = { __typename?: 'Mutation', deleteStudent: string };

export type UpdateStudentMutationVariables = Exact<{
  updateStudentInput: UpdateStudentInput;
}>;


export type UpdateStudentMutation = { __typename?: 'Mutation', updateStudent: { __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, placeOfResidence: string, assignmentIds: Array<string>, courseIds: Array<string> } };

export type BulkUploadStudentsMutationVariables = Exact<{
  students: Array<CreateStudentInput> | CreateStudentInput;
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
}>;


export type BulkUploadStudentsMutation = { __typename?: 'Mutation', bulkUploadStudents: Array<{ __typename?: 'CreateStudentResponse', uid: string, message: string, student: { __typename?: 'Student', id: string, name: string, email: string } }> };

export type CreateAdminMutationVariables = Exact<{
  CreateAdminInput: CreateAdminInput;
}>;


export type CreateAdminMutation = { __typename?: 'Mutation', createAdmin: { __typename?: 'CreateAdminResponse', uid: string, message: string, admin: { __typename?: 'Admin', id: string, name: string, email: string } } };

export type DeleteAdminMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteAdminMutation = { __typename?: 'Mutation', deleteAdmin: boolean };

export type UpdateStaffMutationVariables = Exact<{
  updateStaffInput: UpdateStaffInput;
}>;


export type UpdateStaffMutation = { __typename?: 'Mutation', updateStaff: { __typename?: 'StaffType', id: string, name: string, email: string, campusId: string, designation?: string | null, contactNo?: string | null } };

export type CreateCourseMutationVariables = Exact<{
  createCourseInput: CreateCourseDto;
}>;


export type CreateCourseMutation = { __typename?: 'Mutation', createCourse: { __typename?: 'Course', id?: string | null, name: string, short_description?: string | null, description: string, price: number, time_period?: number | null, languages: Array<string>, domain: string, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null, categories?: Array<{ __typename?: 'Category', id: string, name: string, description: string }> | null } };

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars['String']['input'];
  updateCourseInput: UpdateCourseDto;
}>;


export type UpdateCourseMutation = { __typename?: 'Mutation', updateCourse: { __typename?: 'Course', id?: string | null, name: string, short_description?: string | null, description: string, price: number, time_period?: number | null, languages: Array<string>, domain: string, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null, categories?: Array<{ __typename?: 'Category', name: string, description: string }> | null } };

export type RemoveCourseMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RemoveCourseMutation = { __typename?: 'Mutation', removeCourse: { __typename?: 'Course', id?: string | null, name: string } };

export type CreateQuestionMutationVariables = Exact<{
  createQuestionInput: CreateQuestionInput;
}>;


export type CreateQuestionMutation = { __typename?: 'Mutation', createQuestion: { __typename?: 'Question', id: string, course_id: string, assignment_ids?: Array<string> | null, title: string, text: string, question_sub_text?: string | null, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, marks: string, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, created_by?: string | null, created_at: any, updated_by?: string | null, updated_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, category: { __typename?: 'QuestionCategory', id: string, name: string }, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null } };

export type UpdateQuestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  updateQuestionInput: UpdateQuestionInput;
}>;


export type UpdateQuestionMutation = { __typename?: 'Mutation', updateQuestion: { __typename?: 'Question', id: string, course_id: string, assignment_ids?: Array<string> | null, title: string, text: string, question_sub_text?: string | null, marks: string, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by?: string | null, updated_at: any, created_by?: string | null, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, category: { __typename?: 'QuestionCategory', name: string, id: string } } };

export type DeleteQuestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  course_id: Scalars['String']['input'];
}>;


export type DeleteQuestionMutation = { __typename?: 'Mutation', deleteQuestion: boolean };

export type AddCoursesToClusterMutationVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  courseIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AddCoursesToClusterMutation = { __typename?: 'Mutation', addCoursesToCluster: { __typename?: 'Cluster', id: string, name?: string | null, courseIds?: Array<string> | null } };

export type RemoveCourseFromClusterMutationVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  courseId: Scalars['String']['input'];
}>;


export type RemoveCourseFromClusterMutation = { __typename?: 'Mutation', removeCourseFromCluster: { __typename?: 'Cluster', id: string, name?: string | null, courseIds?: Array<string> | null } };

export type UpdateClusterNameMutationVariables = Exact<{
  updateClusterNameInput: UpdateClusterNameInput;
}>;


export type UpdateClusterNameMutation = { __typename?: 'Mutation', updateClusterName: { __typename?: 'Cluster', id: string, name?: string | null, courseIds?: Array<string> | null } };

export type AddClusterToStudentsMutationVariables = Exact<{
  input: AddClusterToStudentsInput;
}>;


export type AddClusterToStudentsMutation = { __typename?: 'Mutation', addClusterToStudents: Array<{ __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, placeOfResidence: string, assignmentIds: Array<string>, courseIds: Array<string>, isInviteSend: boolean }> };

export type RemoveClusterFromStudentsMutationVariables = Exact<{
  input: RemoveClusterFromStudentsInput;
}>;


export type RemoveClusterFromStudentsMutation = { __typename?: 'Mutation', removeClusterFromStudents: Array<{ __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, placeOfResidence: string, assignmentIds: Array<string>, courseIds: Array<string>, clusterIds: Array<string>, isInviteSend: boolean }> };

export type SubmitCodeMutationVariables = Exact<{
  compilerCodeInput: CompilerInput;
  submitInput: SubmitInput;
}>;


export type SubmitCodeMutation = { __typename?: 'Mutation', submitCode: { __typename?: 'CompilerOutput', compilationOutput?: { __typename?: 'CompilationOutput', stdout: string, stderr: string } | null, testCasePassed?: { __typename?: 'TestCaseDetails', count: number, testCaseIds: Array<{ __typename?: 'TestCaseOutputFormat', testCaseId: number, stdout?: string | null }> } | null, testCaseFailed?: { __typename?: 'TestCaseDetails', count: number, testCaseIds: Array<{ __typename?: 'TestCaseOutputFormat', testCaseId: number, stdout?: string | null }> } | null, Results: Array<{ __typename?: 'CompilationResults', input: string, output: string, expextedOutput: string, error: string }> } };

export type AddStaffToClusterMutationVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  staffs: Array<UpdateStaffInput> | UpdateStaffInput;
}>;


export type AddStaffToClusterMutation = { __typename?: 'Mutation', addStaffToCluster: Array<{ __typename?: 'StaffResponse', added: boolean, staff?: { __typename?: 'StaffType', id: string, email: string, name: string, designation?: string | null } | null }> };

export type RemoveStaffFromClusterMutationVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  staffIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type RemoveStaffFromClusterMutation = { __typename?: 'Mutation', removeStaffFromCluster: Array<{ __typename?: 'RemoveStaffResponse', staffId: string, removed: boolean }> };

export type ToggleSendEmailMutationVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  sendEmail: Scalars['Boolean']['input'];
}>;


export type ToggleSendEmailMutation = { __typename?: 'Mutation', toggleSendEmail: { __typename?: 'Cluster', sendEmail?: boolean | null } };

export type SendEmailMutationVariables = Exact<{
  emailInput: EmailInput;
}>;


export type SendEmailMutation = { __typename?: 'Mutation', sendEmail: string };

export type CreateAssignmentMutationVariables = Exact<{
  createAssignmentInput: CreateAssignmentInput;
}>;


export type CreateAssignmentMutation = { __typename?: 'Mutation', createAssignment: { __typename?: 'Assignment', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, languages: Array<string>, domain?: string | null, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null } };

export type UpdateAssignmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
  updateAssignmentInput: UpdateAssignmentInput;
}>;


export type UpdateAssignmentMutation = { __typename?: 'Mutation', updateAssignment: { __typename?: 'Assignment', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, languages: Array<string>, domain?: string | null, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null } };

export type RemoveAssignmentMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type RemoveAssignmentMutation = { __typename?: 'Mutation', removeAssignment: { __typename?: 'Assignment', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, languages: Array<string>, domain?: string | null, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null } };

export type CreateBulkAssignmentQuestionsMutationVariables = Exact<{
  createQuestionsInput: Array<CreateAssignmentQuestionInput> | CreateAssignmentQuestionInput;
}>;


export type CreateBulkAssignmentQuestionsMutation = { __typename?: 'Mutation', createBulkAssignmentQuestions: Array<{ __typename?: 'AssignmentQuestion', id: string, assignment_id: string, title: string, text: string, question_sub_text?: string | null, marks: string, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by: string, updated_at: any, created_by: string, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null }> };

export type CreateAssignmentQuestionMutationVariables = Exact<{
  createQuestionInput: CreateAssignmentQuestionInput;
}>;


export type CreateAssignmentQuestionMutation = { __typename?: 'Mutation', createAssignmentQuestion: { __typename?: 'AssignmentQuestion', id: string, assignment_id: string, title: string, text: string, question_sub_text?: string | null, marks: string, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by: string, updated_at: any, created_by: string, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null } };

export type UpdateAssignmentQuestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  updateQuestionInput: UpdateAssignmentQuestionInput;
}>;


export type UpdateAssignmentQuestionMutation = { __typename?: 'Mutation', updateAssignmentQuestion: { __typename?: 'AssignmentQuestion', id: string, assignment_id: string, title: string, text: string, question_sub_text?: string | null, marks: string, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by: string, updated_at: any, created_by: string, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null } };

export type DeleteAssignmentQuestionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteAssignmentQuestionMutation = { __typename?: 'Mutation', deleteAssignmentQuestion: boolean };

export type RemoveAssignmentFromStudentMutationVariables = Exact<{
  removeAssignmentInput: RemoveAssignmentInput;
}>;


export type RemoveAssignmentFromStudentMutation = { __typename?: 'Mutation', removeAssignmentFromStudent: { __typename?: 'Student', id: string, name: string, email: string, assignmentIds: Array<string> } };

export type SubmitAssignmentCodeMutationVariables = Exact<{
  assignmentCompilerCodeInput: CompilerInput;
  assignmentSubmitInput: AssignmentSubmitInput;
}>;


export type SubmitAssignmentCodeMutation = { __typename?: 'Mutation', submitAssignmentCode: { __typename?: 'CompilerOutput', compilationOutput?: { __typename?: 'CompilationOutput', stdout: string, stderr: string } | null, testCasePassed?: { __typename?: 'TestCaseDetails', count: number, testCaseIds: Array<{ __typename?: 'TestCaseOutputFormat', testCaseId: number, stdout?: string | null }> } | null, testCaseFailed?: { __typename?: 'TestCaseDetails', count: number, testCaseIds: Array<{ __typename?: 'TestCaseOutputFormat', testCaseId: number, stdout?: string | null }> } | null, Results: Array<{ __typename?: 'CompilationResults', input: string, output: string, expextedOutput: string, error: string }> } };

export type FinalSubmitAssignmentMutationVariables = Exact<{
  AssignmentInput: AssignmentInput;
}>;


export type FinalSubmitAssignmentMutation = { __typename?: 'Mutation', finalSubmitAssignment: { __typename?: 'AssignmentSubmit', id?: string | null, assignment_id?: string | null, student_id?: string | null, totalScore?: number | null, tabSwitches?: number | null, cheat_count?: number | null, completed?: boolean | null, questions?: Array<{ __typename?: 'QuestionSubmission', question_id: string, answer: string, score: number, language: string }> | null } };

export type DeleteBulkStudentsMutationVariables = Exact<{
  studentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  campusName: Scalars['String']['input'];
}>;


export type DeleteBulkStudentsMutation = { __typename?: 'Mutation', deleteBulkStudents: string };

export type AddQuestionsToCourseMutationVariables = Exact<{
  courseId: Scalars['String']['input'];
  questionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AddQuestionsToCourseMutation = { __typename?: 'Mutation', addQuestionsToCourse: { __typename?: 'Course', id?: string | null, name: string, short_description?: string | null, description: string, price: number, time_period?: number | null, languages: Array<string>, level?: string | null, domain: string, question_ids?: Array<string> | null, easy_count: number, hard_count: number, medium_count: number, categories?: Array<{ __typename?: 'Category', id: string, name: string, description: string }> | null } };

export type RemoveAssignmentFromClusterMutationVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  assignmentId: Scalars['String']['input'];
}>;


export type RemoveAssignmentFromClusterMutation = { __typename?: 'Mutation', removeAssignmentFromCluster: { __typename?: 'Cluster', courseIds?: Array<string> | null, studentCount: number } };

export type UpdateAdminMutationVariables = Exact<{
  updateAdminInput: UpdateAdminInput;
}>;


export type UpdateAdminMutation = { __typename?: 'Mutation', updateAdmin: { __typename?: 'Admin', id: string, name: string, email: string, isSuperAdmin?: boolean | null, permissions?: { __typename?: 'Permissions', courses?: Array<string> | null, assignments?: Array<string> | null, reports?: boolean | null, student_creation?: boolean | null } | null } };

export type SaveAutoSaveMutationVariables = Exact<{
  submitInput: SubmitInput;
  code: Scalars['String']['input'];
  language: Scalars['String']['input'];
}>;


export type SaveAutoSaveMutation = { __typename?: 'Mutation', saveAutoSave: string };

export type SaveAssignmentCodeMutationVariables = Exact<{
  saveCodeInput: AssignmentSubmitInput;
  code: Scalars['String']['input'];
  language: Scalars['String']['input'];
}>;


export type SaveAssignmentCodeMutation = { __typename?: 'Mutation', saveAssignmentCode: string };

export type AddAssignmentToClusterMutationVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
  assignmentId: Scalars['String']['input'];
}>;


export type AddAssignmentToClusterMutation = { __typename?: 'Mutation', addAssignmentToCluster: { __typename: 'Cluster', id: string, name?: string | null, campusName: string, courseIds?: Array<string> | null, assignmentIds?: Array<string> | null, createdAt?: any | null, studentCount: number, sendEmail?: boolean | null, staffs?: Array<{ __typename?: 'StaffType', id: string, name: string, email: string, campusId: string, designation?: string | null, contactNo?: string | null, clusterIds?: Array<string> | null, isInviteSend: boolean, isAdminStaff?: boolean | null }> | null } };

export type GetCampusQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetCampusQuery = { __typename?: 'Query', campus: { __typename?: 'Campus', id: string, name: string } };

export type GetAllCampusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCampusesQuery = { __typename?: 'Query', allCampus: Array<{ __typename?: 'Campus', id: string, name: string }> };

export type GetAllAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAdminQuery = { __typename?: 'Query', getAllAdmin: Array<{ __typename?: 'Admin', id: string, name: string, email: string, isSuperAdmin?: boolean | null, permissions?: { __typename?: 'Permissions', courses?: Array<string> | null, assignments?: Array<string> | null, reports?: boolean | null, student_creation?: boolean | null } | null }> };

export type GetAllStaffsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllStaffsQuery = { __typename?: 'Query', getAllStaffs: Array<{ __typename?: 'StaffType', id: string, name: string, email: string, campusId: string }> };

export type GetClustersByCampusQueryVariables = Exact<{
  campusName: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetClustersByCampusQuery = { __typename?: 'Query', getClustersByCampus: Array<{ __typename?: 'Cluster', id: string, name?: string | null, studentCount: number }> };

export type GetStudentsByClusterQueryVariables = Exact<{
  clusterId: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetStudentsByClusterQuery = { __typename?: 'Query', getStudentsByCluster: Array<{ __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, rollNo: string, placeOfResidence: string, leetCodeUname: string, hackerRankUname: string, campusId: string, assignmentIds: Array<string>, clusterIds: Array<string>, courseIds: Array<string>, isInviteSend: boolean }> };

export type GetStaffsByCampusQueryVariables = Exact<{
  campusId: Scalars['String']['input'];
}>;


export type GetStaffsByCampusQuery = { __typename?: 'Query', getStaffsByCampus: Array<{ __typename?: 'StaffType', id: string, name: string, email: string, campusId: string, designation?: string | null, contactNo?: string | null, isInviteSend: boolean, clusterIds?: Array<string> | null, isAdminStaff?: boolean | null }> };

export type GetStaffByEmailIdQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetStaffByEmailIdQuery = { __typename?: 'Query', getStaffByEmailId: { __typename?: 'StaffType', id: string, name: string, email: string, designation?: string | null, contactNo?: string | null, campusId: string, isInviteSend: boolean, isAdminStaff?: boolean | null, clusterIds?: Array<string> | null } };

export type GetStudentByEmailIdQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetStudentByEmailIdQuery = { __typename?: 'Query', getStudentByEmailId: { __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, rollNo: string, campusId: string, leetCodeUname: string, hackerRankUname: string, placeOfResidence: string, assignmentIds: Array<string>, courseIds: Array<string>, clusterIds: Array<string>, completedQuestions?: Array<string> | null } };

export type GetStaffQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetStaffQuery = { __typename?: 'Query', getStaff: { __typename?: 'StaffType', id: string, name: string, email: string, campusId: string } };

export type GetAllStudentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllStudentsQuery = { __typename?: 'Query', getAllStudents: Array<{ __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, placeOfResidence: string, assignmentIds: Array<string>, courseIds: Array<string> }> };

export type GetStudentQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetStudentQuery = { __typename?: 'Query', getStudent?: { __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, rollNo: string, campusId: string, leetCodeUname: string, hackerRankUname: string, placeOfResidence: string, assignmentIds: Array<string>, courseIds: Array<string>, clusterIds: Array<string>, completedQuestions?: Array<string> | null } | null };

export type GetStudentsByCampusQueryVariables = Exact<{
  campusId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  clusterid?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetStudentsByCampusQuery = { __typename?: 'Query', getStudentsByCampus: { __typename?: 'GetStudentsByCampusResponseType', total: number, students: Array<{ __typename?: 'Student', id: string, name: string, email: string, rollNo: string, assignmentIds: Array<string> }> } };

export type GetLeetcodeDataQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type GetLeetcodeDataQuery = { __typename?: 'Query', leetcodeData: { __typename?: 'LeetcodeData', allQuestionsCount?: Array<{ __typename?: 'AllQuestionsCount', difficulty?: string | null, count: number }> | null, matchedUser?: { __typename?: 'MatchedUser', username?: string | null, githubUrl?: string | null, twitterUrl?: string | null, linkedinUrl?: string | null, submissionCalendar?: string | null, contributions?: { __typename?: 'Contributions', points: number, questionCount: number, testcaseCount: number } | null, profile?: { __typename?: 'Profile', realName?: string | null, userAvatar?: string | null, birthday?: string | null, ranking: number, reputation: number, websites: Array<string>, countryName?: string | null, company?: string | null, school?: string | null, skillTags?: Array<string> | null, aboutMe?: string | null, starRating?: number | null } | null, badges?: Array<{ __typename?: 'Badge', id?: string | null, displayName?: string | null, icon?: string | null, creationDate?: string | null }> | null, upcomingBadges?: Array<{ __typename?: 'UpcomingBadge', name?: string | null, icon?: string | null }> | null, activeBadge?: { __typename?: 'Badge', id?: string | null, displayName?: string | null, icon?: string | null, creationDate?: string | null } | null, submitStats?: { __typename?: 'SubmitStats', totalSubmissionNum?: Array<{ __typename?: 'SubmitStatsNum', difficulty?: string | null, count?: number | null, submissions?: number | null }> | null, acSubmissionNum?: Array<{ __typename?: 'SubmitStatsNum', difficulty?: string | null, count?: number | null, submissions?: number | null }> | null } | null } | null, recentSubmissionList?: Array<{ __typename?: 'RecentSubmission', title?: string | null, titleSlug?: string | null, timestamp?: string | null, statusDisplay?: string | null, lang?: string | null }> | null } };

export type GetAllCoursesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCoursesQuery = { __typename?: 'Query', getAllCourses: Array<{ __typename?: 'Course', id?: string | null, name: string, short_description?: string | null, description: string, price: number, time_period?: number | null, languages: Array<string>, domain: string, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null, categories?: Array<{ __typename?: 'Category', id: string, name: string, description: string }> | null }> };

export type GetCourseQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetCourseQuery = { __typename?: 'Query', getCourse: { __typename?: 'Course', _id: string, id?: string | null, name: string, question_ids?: Array<string> | null, short_description?: string | null, description: string, easy_count: number, medium_count: number, hard_count: number, price: number, time_period?: number | null, languages: Array<string>, domain: string, level?: string | null, categories?: Array<{ __typename?: 'Category', id: string, name: string, description: string }> | null } };

export type FindAllQuestionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  difficulties?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  searchName?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
}>;


export type FindAllQuestionsQuery = { __typename?: 'Query', findAllQuestions: Array<{ __typename?: 'Question', id: string, title: string, text: string, question_sub_text?: string | null, marks: string, difficulty: string, hints: Array<string>, tags: Array<string>, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, company_tags: Array<string>, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null }> };

export type GetAllQuestionQueryVariables = Exact<{
  courseId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  categoryId: Scalars['String']['input'];
}>;


export type GetAllQuestionQuery = { __typename?: 'Query', getAllQuestion: { __typename?: 'QuestionResponse', totalCount: number, questions: Array<{ __typename?: 'Question', id: string, course_id: string, assignment_ids?: Array<string> | null, title: string, text: string, question_sub_text?: string | null, marks: string, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, category: { __typename?: 'QuestionCategory', name: string, id: string } }> } };

export type GetQuestionQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetQuestionQuery = { __typename?: 'Query', getQuestion: { __typename?: 'Question', id: string, course_id: string, assignment_ids?: Array<string> | null, title: string, text: string, question_sub_text?: string | null, marks: string, difficulty: string, hints: Array<string>, tags: Array<string>, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, company_tags: Array<string>, updated_by?: string | null, updated_at: any, created_by?: string | null, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, category: { __typename?: 'QuestionCategory', name: string, id: string }, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null } };

export type GetQuestionsByTagsQueryVariables = Exact<{
  tags: Array<Scalars['String']['input']> | Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetQuestionsByTagsQuery = { __typename?: 'Query', getQuestionsByTags: Array<{ __typename?: 'Question', id: string, course_id: string, assignment_ids?: Array<string> | null, title: string, text: string, question_sub_text?: string | null, marks: string, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by?: string | null, updated_at: any, created_by?: string | null, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, category: { __typename?: 'QuestionCategory', name: string, id: string } }> };

export type GetCoursesByStudentEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetCoursesByStudentEmailQuery = { __typename?: 'Query', getCoursesByStudentEmail: Array<{ __typename?: 'Course', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, price: number, time_period?: number | null, languages: Array<string>, domain: string, categories?: Array<{ __typename?: 'Category', id: string, name: string, description: string }> | null }> };

export type GetAllCoursesByClusterQueryVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
}>;


export type GetAllCoursesByClusterQuery = { __typename?: 'Query', getAllCoursesByCluster: Array<{ __typename?: 'Course', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, price: number, time_period?: number | null, languages: Array<string>, domain: string, categories?: Array<{ __typename?: 'Category', id: string, name: string, description: string }> | null }> };

export type GetClusterQueryVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
}>;


export type GetClusterQuery = { __typename?: 'Query', getCluster: { __typename?: 'Cluster', id: string, name?: string | null, courseIds?: Array<string> | null, studentCount: number, createdAt?: any | null, sendEmail?: boolean | null, staffs?: Array<{ __typename?: 'StaffType', id: string, name: string, email: string }> | null } };

export type CompileCodeQueryVariables = Exact<{
  compileCodeInput: CompilerInput;
}>;


export type CompileCodeQuery = { __typename?: 'Query', compileCode: { __typename?: 'CompilerOutput', compilationOutput?: { __typename?: 'CompilationOutput', stdout: string, stderr: string } | null, testCasePassed?: { __typename?: 'TestCaseDetails', count: number, testCaseIds: Array<{ __typename?: 'TestCaseOutputFormat', testCaseId: number, stdout?: string | null }> } | null, testCaseFailed?: { __typename?: 'TestCaseDetails', count: number, testCaseIds: Array<{ __typename?: 'TestCaseOutputFormat', testCaseId: number, stdout?: string | null }> } | null, Results: Array<{ __typename?: 'CompilationResults', input: string, output: string, expextedOutput: string, error: string }> } };

export type GetCampusByNameQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type GetCampusByNameQuery = { __typename?: 'Query', getCampusByName: { __typename?: 'Campus', id: string, name: string } };

export type GetClustersByIdsAndCampusQueryVariables = Exact<{
  clusterIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  campusName: Scalars['String']['input'];
}>;


export type GetClustersByIdsAndCampusQuery = { __typename?: 'Query', getClustersByIdsAndCampus: Array<{ __typename?: 'Cluster', id: string, name?: string | null, courseIds?: Array<string> | null, createdAt?: any | null, studentCount: number }> };

export type GetLeaderboardQueryVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetLeaderboardQuery = { __typename?: 'Query', getLeaderboard: Array<{ __typename?: 'LeaderBoardResponse', student_id: string, totalScore: number, completedQuestions: number, latestSubmissionDate?: any | null, position: number, easyQuestionsCompleted?: number | null, hardQuestionCompleted?: number | null, mediumQuestionsCompleted?: number | null, student?: { __typename?: 'Student', id: string, name: string, email: string, phno: string, dob: string, completedQuestions?: Array<string> | null } | null, leetcode_data?: Array<{ __typename?: 'LeetCodeQuestionCount', difficulty: string, count: number, submissions: number }> | null }> };

export type GetQuestionCompletedQueryVariables = Exact<{
  student_id: Scalars['String']['input'];
  is_paid: Scalars['Boolean']['input'];
}>;


export type GetQuestionCompletedQuery = { __typename?: 'Query', getQuestionCompleted: { __typename?: 'QuestionCompletionResult', easy: { __typename?: 'QuestionCount', completed: number, total: number }, medium: { __typename?: 'QuestionCount', completed: number, total: number }, hard: { __typename?: 'QuestionCount', completed: number, total: number }, overall: { __typename?: 'QuestionCount', completed: number, total: number } } };

export type GetDashboardCourseDetailsQueryVariables = Exact<{
  student_id: Scalars['String']['input'];
  is_paid: Scalars['Boolean']['input'];
}>;


export type GetDashboardCourseDetailsQuery = { __typename?: 'Query', getDashboardCourseDetails: { __typename: 'DashboardCourseDetails', rewardPoints: string, courseDetails: Array<{ __typename?: 'CourseDetails', courseId: string, courseName?: string | null, level?: string | null, completedQuestion: string, totalQuestion: string, percentageCompleted: number }> } };

export type GetLastFiveSubmissionsQueryVariables = Exact<{
  submitInput: SubmitInput;
}>;


export type GetLastFiveSubmissionsQuery = { __typename?: 'Query', getLastFiveSubmissions: { __typename?: 'Submit', id: string, student_id: string, course_id: string, cluster_id: string, question_id: string, max_score: number, is_completed: boolean, autosave?: { __typename?: 'SubmissionDetail', id: string, code: string, score: number, submittedAt: any, language?: string | null } | null, submissions: Array<{ __typename?: 'SubmissionDetail', id: string, code: string, score: number, submittedAt: any, language?: string | null }> } };

export type GetAllAssignmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllAssignmentsQuery = { __typename?: 'Query', getAllAssignments: Array<{ __typename?: 'Assignment', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, duration?: number | null, languages: Array<string>, domain?: string | null, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null }> };

export type GetAssignmentQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetAssignmentQuery = { __typename?: 'Query', getAssignment: { __typename?: 'Assignment', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, duration?: number | null, languages: Array<string>, max_cheat_count?: number | null, max_tabSwitches?: number | null, enable_proctoring?: boolean | null, domain?: string | null, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null } };

export type GetAllAssignmentQuestionQueryVariables = Exact<{
  assignmentId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetAllAssignmentQuestionQuery = { __typename?: 'Query', getAllAssignmentQuestion: Array<{ __typename?: 'AssignmentQuestion', id: string, assignment_id: string, title: string, text: string, question_sub_text?: string | null, marks: string, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by: string, updated_at: any, created_by: string, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null }> };

export type GetAssignmentQuestionQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetAssignmentQuestionQuery = { __typename?: 'Query', getAssignmentQuestion: { __typename?: 'AssignmentQuestion', id: string, assignment_id: string, title: string, text: string, question_sub_text?: string | null, marks: string, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by: string, updated_at: any, created_by: string, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null } };

export type GetAssignmentQuestionsByTagsQueryVariables = Exact<{
  tags: Array<Scalars['String']['input']> | Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetAssignmentQuestionsByTagsQuery = { __typename?: 'Query', getAssignmentQuestionsByTags: Array<{ __typename?: 'AssignmentQuestion', id: string, assignment_id: string, title: string, text: string, question_sub_text?: string | null, marks: string, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, updated_by: string, updated_at: any, created_by: string, created_at: any, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null }> };

export type GetStudentAndCampusDetailsByAssignmentIdQueryVariables = Exact<{
  assignmentId: Scalars['String']['input'];
}>;


export type GetStudentAndCampusDetailsByAssignmentIdQuery = { __typename?: 'Query', getStudentAndCampusDetailsByAssignmentId: Array<{ __typename?: 'StudentCampusDTO', student: { __typename?: 'Student', id: string, name: string, email: string, campusId: string, rollNo: string, assignmentIds: Array<string> }, campus: { __typename?: 'Campus', id: string, name: string } }> };

export type GetAssignmentsForStudentQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetAssignmentsForStudentQuery = { __typename?: 'Query', getAssignmentsForStudent: Array<{ __typename?: 'Assignment', id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null }> };

export type GetAssignmentSubmitQueryVariables = Exact<{
  assignmentId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
  campus_name: Scalars['String']['input'];
}>;


export type GetAssignmentSubmitQuery = { __typename?: 'Query', getAssignmentSubmit?: { __typename?: 'AssignmentSubmit', id?: string | null, assignment_id?: string | null, cluster_id?: string | null, student_id?: string | null, totalScore?: number | null, tabSwitches?: number | null, cheat_count?: number | null, completed?: boolean | null, questions?: Array<{ __typename?: 'QuestionSubmission', question_id: string, answer: string, score: number, language: string }> | null, autosave?: Array<{ __typename?: 'QuestionSubmission', question_id: string, answer: string, score: number, language: string }> | null } | null };

export type GetStudentSubmissionsForMonthQueryVariables = Exact<{
  studentId: Scalars['String']['input'];
  campusName: Scalars['String']['input'];
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
}>;


export type GetStudentSubmissionsForMonthQuery = { __typename?: 'Query', getStudentSubmissionsForMonth: Array<{ __typename?: 'SubmissionData', date: any, courseName?: string | null, submissions: Array<{ __typename?: 'Submission', submittedAt: any }> }> };

export type GetStudentSubmissionsForTimeRangeQueryVariables = Exact<{
  studentId: Scalars['String']['input'];
  campusName: Scalars['String']['input'];
  timeRangeInMonths: Scalars['Int']['input'];
}>;


export type GetStudentSubmissionsForTimeRangeQuery = { __typename?: 'Query', getStudentSubmissionsForTimeRange: Array<{ __typename?: 'SubmissionData', courseName?: string | null, date: any, submissions: Array<{ __typename?: 'Submission', submittedAt: any }> }> };

export type GetAssignmentLeaderBoardQueryVariables = Exact<{
  assignmentId: Scalars['String']['input'];
  campus_name: Scalars['String']['input'];
}>;


export type GetAssignmentLeaderBoardQuery = { __typename?: 'Query', getAssignmentLeaderBoard: Array<{ __typename: 'AssignmentLeaderBoard', assignment_id: string, totalScore: number, clusterNames: string, totalNumberOfQuestion: number, position: number, student?: { __typename?: 'Student', id: string, name: string, email: string, campusId: string, rollNo: string } | null, questions?: Array<{ __typename?: 'QuestionLeaderboard', questionId: string, score: number, AllotedTotalMarks: number }> | null }> };

export type FindAssignmentsForStudentWithFilterQueryVariables = Exact<{
  email: Scalars['String']['input'];
  filterType: Scalars['String']['input'];
  customDateRange?: InputMaybe<AssignmentFilterInput>;
}>;


export type FindAssignmentsForStudentWithFilterQuery = { __typename?: 'Query', findAssignmentsForStudentWithFilter: Array<{ __typename: 'Assignment', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, languages: Array<string>, domain?: string | null, duration?: number | null, max_cheat_count?: number | null, max_tabSwitches?: number | null, enable_proctoring?: boolean | null, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null }> };

export type AllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCategoriesQuery = { __typename?: 'Query', allCategories: Array<{ __typename?: 'Categories', id?: string | null, name: string, description: string }> };

export type GetQuestionsByCategoryQueryVariables = Exact<{
  categoryId: Scalars['String']['input'];
  courseId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  searchName?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetQuestionsByCategoryQuery = { __typename?: 'Query', getQuestionsByCategory: { __typename?: 'QuestionResponse', totalCount: number, questions: Array<{ __typename?: 'Question', id: string, course_id: string, assignment_ids?: Array<string> | null, title: string, text: string, question_sub_text?: string | null, marks: string, showFunctionOnly?: boolean | null, targetFunctionName?: string | null, difficulty: string, hints: Array<string>, tags: Array<string>, company_tags: Array<string>, test_cases: Array<{ __typename?: 'TestCase', id?: string | null, visibility: string, input: string, output: string, weightage: number }>, category: { __typename?: 'QuestionCategory', id: string, name: string }, predefinedCode?: { __typename?: 'PredefinedLanguages', c: string, java: string, python: string, cpp: string } | null }> } };

export type GetAssignmentByClusterQueryVariables = Exact<{
  campusName: Scalars['String']['input'];
  clusterId: Scalars['String']['input'];
}>;


export type GetAssignmentByClusterQuery = { __typename?: 'Query', getAssignmentByCluster: Array<{ __typename?: 'Assignment', id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, languages: Array<string>, domain?: string | null, duration?: number | null }> };

export type GetAdminUsingEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type GetAdminUsingEmailQuery = { __typename?: 'Query', getAdminUsingEmail: { __typename?: 'Admin', id: string, name: string, email: string, isSuperAdmin?: boolean | null, permissions?: { __typename?: 'Permissions', courses?: Array<string> | null, assignments?: Array<string> | null, reports?: boolean | null, student_creation?: boolean | null } | null } };

export type GetStudentsResultsQueryVariables = Exact<{
  campusId: Scalars['String']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  clusterid?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetStudentsResultsQuery = { __typename?: 'Query', getStudentsResults: { __typename: 'StudentResults', total: number, students: Array<{ __typename?: 'StudentPersonalDetails', id: string, name: string, email: string, rollNo: string, courseDetails: { __typename?: 'CourseResults', rewardPoints: string, courseDetails: Array<{ __typename?: 'CourseDetails', courseId: string, percentageCompleted: number, completedQuestion: string, totalQuestion: string }> }, assignmentDetails: Array<{ __typename?: 'AssignmentResults', assignment_id?: string | null, student_id?: string | null, totalScore?: number | null }> }> } };

export type GetAssignmentByCampusQueryVariables = Exact<{
  campusName: Scalars['String']['input'];
}>;


export type GetAssignmentByCampusQuery = { __typename?: 'Query', getAssignmentByCampus: Array<{ __typename: 'Assignment', _id: string, id?: string | null, name: string, short_description?: string | null, description: string, due_date?: any | null, languages: Array<string>, domain?: string | null, duration?: number | null, max_cheat_count?: number | null, max_tabSwitches?: number | null, enable_proctoring?: boolean | null, updatedBy?: string | null, updatedAt?: any | null, createdBy?: string | null, createdAt?: any | null }> };

export type StudentsNotAttendedAssignmentQueryVariables = Exact<{
  campus_id: Scalars['String']['input'];
  assignmentId: Scalars['String']['input'];
  cluster_id?: InputMaybe<Scalars['String']['input']>;
}>;


export type StudentsNotAttendedAssignmentQuery = { __typename?: 'Query', studentsNotAttendedAssignment: Array<{ __typename: 'StudentWithClusterInfo', student_name: string, student_id: string, cluster_names: string, student_rollno: string, student_email: string }> };


export const CreateCampusDocument = gql`
    mutation CreateCampus($createCampusInput: CreateCampusInput!) {
  createCampus(createCampusInput: $createCampusInput) {
    id
    name
  }
}
    `;
export type CreateCampusMutationFn = Apollo.MutationFunction<CreateCampusMutation, CreateCampusMutationVariables>;

/**
 * __useCreateCampusMutation__
 *
 * To run a mutation, you first call `useCreateCampusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampusMutation, { data, loading, error }] = useCreateCampusMutation({
 *   variables: {
 *      createCampusInput: // value for 'createCampusInput'
 *   },
 * });
 */
export function useCreateCampusMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampusMutation, CreateCampusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampusMutation, CreateCampusMutationVariables>(CreateCampusDocument, options);
      }
export type CreateCampusMutationHookResult = ReturnType<typeof useCreateCampusMutation>;
export type CreateCampusMutationResult = Apollo.MutationResult<CreateCampusMutation>;
export type CreateCampusMutationOptions = Apollo.BaseMutationOptions<CreateCampusMutation, CreateCampusMutationVariables>;
export const CreateClusterDocument = gql`
    mutation CreateCluster($createClusterInput: CreateClusterInput!) {
  createCluster(createClusterInput: $createClusterInput) {
    id
    name
    courseIds
  }
}
    `;
export type CreateClusterMutationFn = Apollo.MutationFunction<CreateClusterMutation, CreateClusterMutationVariables>;

/**
 * __useCreateClusterMutation__
 *
 * To run a mutation, you first call `useCreateClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClusterMutation, { data, loading, error }] = useCreateClusterMutation({
 *   variables: {
 *      createClusterInput: // value for 'createClusterInput'
 *   },
 * });
 */
export function useCreateClusterMutation(baseOptions?: Apollo.MutationHookOptions<CreateClusterMutation, CreateClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClusterMutation, CreateClusterMutationVariables>(CreateClusterDocument, options);
      }
export type CreateClusterMutationHookResult = ReturnType<typeof useCreateClusterMutation>;
export type CreateClusterMutationResult = Apollo.MutationResult<CreateClusterMutation>;
export type CreateClusterMutationOptions = Apollo.BaseMutationOptions<CreateClusterMutation, CreateClusterMutationVariables>;
export const CreateStaffDocument = gql`
    mutation CreateStaff($createStaffInput: CreateStaffInput!) {
  createStaff(createStaffInput: $createStaffInput) {
    staff {
      id
      name
      email
      campusId
    }
    uid
    message
  }
}
    `;
export type CreateStaffMutationFn = Apollo.MutationFunction<CreateStaffMutation, CreateStaffMutationVariables>;

/**
 * __useCreateStaffMutation__
 *
 * To run a mutation, you first call `useCreateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStaffMutation, { data, loading, error }] = useCreateStaffMutation({
 *   variables: {
 *      createStaffInput: // value for 'createStaffInput'
 *   },
 * });
 */
export function useCreateStaffMutation(baseOptions?: Apollo.MutationHookOptions<CreateStaffMutation, CreateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStaffMutation, CreateStaffMutationVariables>(CreateStaffDocument, options);
      }
export type CreateStaffMutationHookResult = ReturnType<typeof useCreateStaffMutation>;
export type CreateStaffMutationResult = Apollo.MutationResult<CreateStaffMutation>;
export type CreateStaffMutationOptions = Apollo.BaseMutationOptions<CreateStaffMutation, CreateStaffMutationVariables>;
export const DeleteStaffDocument = gql`
    mutation DeleteStaff($id: String!) {
  deleteStaff(id: $id)
}
    `;
export type DeleteStaffMutationFn = Apollo.MutationFunction<DeleteStaffMutation, DeleteStaffMutationVariables>;

/**
 * __useDeleteStaffMutation__
 *
 * To run a mutation, you first call `useDeleteStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStaffMutation, { data, loading, error }] = useDeleteStaffMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStaffMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStaffMutation, DeleteStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStaffMutation, DeleteStaffMutationVariables>(DeleteStaffDocument, options);
      }
export type DeleteStaffMutationHookResult = ReturnType<typeof useDeleteStaffMutation>;
export type DeleteStaffMutationResult = Apollo.MutationResult<DeleteStaffMutation>;
export type DeleteStaffMutationOptions = Apollo.BaseMutationOptions<DeleteStaffMutation, DeleteStaffMutationVariables>;
export const SendInviteEmailToStaffDocument = gql`
    mutation SendInviteEmailToStaff($emails: [String!]!) {
  sendInviteEmailToStaff(emails: $emails)
}
    `;
export type SendInviteEmailToStaffMutationFn = Apollo.MutationFunction<SendInviteEmailToStaffMutation, SendInviteEmailToStaffMutationVariables>;

/**
 * __useSendInviteEmailToStaffMutation__
 *
 * To run a mutation, you first call `useSendInviteEmailToStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteEmailToStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteEmailToStaffMutation, { data, loading, error }] = useSendInviteEmailToStaffMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useSendInviteEmailToStaffMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteEmailToStaffMutation, SendInviteEmailToStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteEmailToStaffMutation, SendInviteEmailToStaffMutationVariables>(SendInviteEmailToStaffDocument, options);
      }
export type SendInviteEmailToStaffMutationHookResult = ReturnType<typeof useSendInviteEmailToStaffMutation>;
export type SendInviteEmailToStaffMutationResult = Apollo.MutationResult<SendInviteEmailToStaffMutation>;
export type SendInviteEmailToStaffMutationOptions = Apollo.BaseMutationOptions<SendInviteEmailToStaffMutation, SendInviteEmailToStaffMutationVariables>;
export const SendInviteEmailToStudentDocument = gql`
    mutation sendInviteEmailToStudent($emails: [String!]!) {
  sendInviteEmailToStudent(emails: $emails)
}
    `;
export type SendInviteEmailToStudentMutationFn = Apollo.MutationFunction<SendInviteEmailToStudentMutation, SendInviteEmailToStudentMutationVariables>;

/**
 * __useSendInviteEmailToStudentMutation__
 *
 * To run a mutation, you first call `useSendInviteEmailToStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteEmailToStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteEmailToStudentMutation, { data, loading, error }] = useSendInviteEmailToStudentMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useSendInviteEmailToStudentMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteEmailToStudentMutation, SendInviteEmailToStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteEmailToStudentMutation, SendInviteEmailToStudentMutationVariables>(SendInviteEmailToStudentDocument, options);
      }
export type SendInviteEmailToStudentMutationHookResult = ReturnType<typeof useSendInviteEmailToStudentMutation>;
export type SendInviteEmailToStudentMutationResult = Apollo.MutationResult<SendInviteEmailToStudentMutation>;
export type SendInviteEmailToStudentMutationOptions = Apollo.BaseMutationOptions<SendInviteEmailToStudentMutation, SendInviteEmailToStudentMutationVariables>;
export const DeleteStudentDocument = gql`
    mutation DeleteStudent($id: String!, $campusName: String!) {
  deleteStudent(id: $id, campusName: $campusName)
}
    `;
export type DeleteStudentMutationFn = Apollo.MutationFunction<DeleteStudentMutation, DeleteStudentMutationVariables>;

/**
 * __useDeleteStudentMutation__
 *
 * To run a mutation, you first call `useDeleteStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentMutation, { data, loading, error }] = useDeleteStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      campusName: // value for 'campusName'
 *   },
 * });
 */
export function useDeleteStudentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentMutation, DeleteStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentMutation, DeleteStudentMutationVariables>(DeleteStudentDocument, options);
      }
export type DeleteStudentMutationHookResult = ReturnType<typeof useDeleteStudentMutation>;
export type DeleteStudentMutationResult = Apollo.MutationResult<DeleteStudentMutation>;
export type DeleteStudentMutationOptions = Apollo.BaseMutationOptions<DeleteStudentMutation, DeleteStudentMutationVariables>;
export const UpdateStudentDocument = gql`
    mutation UpdateStudent($updateStudentInput: UpdateStudentInput!) {
  updateStudent(updateStudentInput: $updateStudentInput) {
    id
    name
    email
    phno
    dob
    placeOfResidence
    assignmentIds
    courseIds
  }
}
    `;
export type UpdateStudentMutationFn = Apollo.MutationFunction<UpdateStudentMutation, UpdateStudentMutationVariables>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      updateStudentInput: // value for 'updateStudentInput'
 *   },
 * });
 */
export function useUpdateStudentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentMutation, UpdateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(UpdateStudentDocument, options);
      }
export type UpdateStudentMutationHookResult = ReturnType<typeof useUpdateStudentMutation>;
export type UpdateStudentMutationResult = Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<UpdateStudentMutation, UpdateStudentMutationVariables>;
export const BulkUploadStudentsDocument = gql`
    mutation BulkUploadStudents($students: [CreateStudentInput!]!, $campusName: String!, $clusterId: String!) {
  bulkUploadStudents(
    students: $students
    campusName: $campusName
    clusterId: $clusterId
  ) {
    student {
      id
      name
      email
    }
    uid
    message
  }
}
    `;
export type BulkUploadStudentsMutationFn = Apollo.MutationFunction<BulkUploadStudentsMutation, BulkUploadStudentsMutationVariables>;

/**
 * __useBulkUploadStudentsMutation__
 *
 * To run a mutation, you first call `useBulkUploadStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUploadStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUploadStudentsMutation, { data, loading, error }] = useBulkUploadStudentsMutation({
 *   variables: {
 *      students: // value for 'students'
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useBulkUploadStudentsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUploadStudentsMutation, BulkUploadStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUploadStudentsMutation, BulkUploadStudentsMutationVariables>(BulkUploadStudentsDocument, options);
      }
export type BulkUploadStudentsMutationHookResult = ReturnType<typeof useBulkUploadStudentsMutation>;
export type BulkUploadStudentsMutationResult = Apollo.MutationResult<BulkUploadStudentsMutation>;
export type BulkUploadStudentsMutationOptions = Apollo.BaseMutationOptions<BulkUploadStudentsMutation, BulkUploadStudentsMutationVariables>;
export const CreateAdminDocument = gql`
    mutation CreateAdmin($CreateAdminInput: CreateAdminInput!) {
  createAdmin(createAdminInput: $CreateAdminInput) {
    admin {
      id
      name
      email
    }
    uid
    message
  }
}
    `;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      CreateAdminInput: // value for 'CreateAdminInput'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const DeleteAdminDocument = gql`
    mutation DeleteAdmin($id: String!) {
  deleteAdmin(id: $id)
}
    `;
export type DeleteAdminMutationFn = Apollo.MutationFunction<DeleteAdminMutation, DeleteAdminMutationVariables>;

/**
 * __useDeleteAdminMutation__
 *
 * To run a mutation, you first call `useDeleteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminMutation, { data, loading, error }] = useDeleteAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminMutation, DeleteAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminMutation, DeleteAdminMutationVariables>(DeleteAdminDocument, options);
      }
export type DeleteAdminMutationHookResult = ReturnType<typeof useDeleteAdminMutation>;
export type DeleteAdminMutationResult = Apollo.MutationResult<DeleteAdminMutation>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<DeleteAdminMutation, DeleteAdminMutationVariables>;
export const UpdateStaffDocument = gql`
    mutation UpdateStaff($updateStaffInput: UpdateStaffInput!) {
  updateStaff(updateStaffInput: $updateStaffInput) {
    id
    name
    email
    campusId
    designation
    contactNo
  }
}
    `;
export type UpdateStaffMutationFn = Apollo.MutationFunction<UpdateStaffMutation, UpdateStaffMutationVariables>;

/**
 * __useUpdateStaffMutation__
 *
 * To run a mutation, you first call `useUpdateStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaffMutation, { data, loading, error }] = useUpdateStaffMutation({
 *   variables: {
 *      updateStaffInput: // value for 'updateStaffInput'
 *   },
 * });
 */
export function useUpdateStaffMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStaffMutation, UpdateStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStaffMutation, UpdateStaffMutationVariables>(UpdateStaffDocument, options);
      }
export type UpdateStaffMutationHookResult = ReturnType<typeof useUpdateStaffMutation>;
export type UpdateStaffMutationResult = Apollo.MutationResult<UpdateStaffMutation>;
export type UpdateStaffMutationOptions = Apollo.BaseMutationOptions<UpdateStaffMutation, UpdateStaffMutationVariables>;
export const CreateCourseDocument = gql`
    mutation CreateCourse($createCourseInput: CreateCourseDto!) {
  createCourse(createCourseInput: $createCourseInput) {
    id
    name
    short_description
    description
    categories {
      id
      name
      description
    }
    price
    time_period
    languages
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      createCourseInput: // value for 'createCourseInput'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const UpdateCourseDocument = gql`
    mutation UpdateCourse($id: String!, $updateCourseInput: UpdateCourseDto!) {
  updateCourse(id: $id, updateCourseInput: $updateCourseInput) {
    id
    name
    short_description
    description
    categories {
      name
      description
    }
    price
    time_period
    languages
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateCourseInput: // value for 'updateCourseInput'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const RemoveCourseDocument = gql`
    mutation RemoveCourse($id: String!) {
  removeCourse(id: $id) {
    id
    name
  }
}
    `;
export type RemoveCourseMutationFn = Apollo.MutationFunction<RemoveCourseMutation, RemoveCourseMutationVariables>;

/**
 * __useRemoveCourseMutation__
 *
 * To run a mutation, you first call `useRemoveCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseMutation, { data, loading, error }] = useRemoveCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCourseMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCourseMutation, RemoveCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCourseMutation, RemoveCourseMutationVariables>(RemoveCourseDocument, options);
      }
export type RemoveCourseMutationHookResult = ReturnType<typeof useRemoveCourseMutation>;
export type RemoveCourseMutationResult = Apollo.MutationResult<RemoveCourseMutation>;
export type RemoveCourseMutationOptions = Apollo.BaseMutationOptions<RemoveCourseMutation, RemoveCourseMutationVariables>;
export const CreateQuestionDocument = gql`
    mutation CreateQuestion($createQuestionInput: CreateQuestionInput!) {
  createQuestion(createQuestionInput: $createQuestionInput) {
    id
    course_id
    assignment_ids
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    category {
      id
      name
    }
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    marks
    difficulty
    hints
    tags
    company_tags
    created_by
    created_at
    updated_by
    updated_at
  }
}
    `;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;

/**
 * __useCreateQuestionMutation__
 *
 * To run a mutation, you first call `useCreateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionMutation, { data, loading, error }] = useCreateQuestionMutation({
 *   variables: {
 *      createQuestionInput: // value for 'createQuestionInput'
 *   },
 * });
 */
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export type CreateQuestionMutationOptions = Apollo.BaseMutationOptions<CreateQuestionMutation, CreateQuestionMutationVariables>;
export const UpdateQuestionDocument = gql`
    mutation updateQuestion($id: String!, $updateQuestionInput: UpdateQuestionInput!) {
  updateQuestion(id: $id, updateQuestionInput: $updateQuestionInput) {
    id
    course_id
    assignment_ids
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    category {
      name
      id
    }
    marks
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;

/**
 * __useUpdateQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionMutation, { data, loading, error }] = useUpdateQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateQuestionInput: // value for 'updateQuestionInput'
 *   },
 * });
 */
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export type UpdateQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export const DeleteQuestionDocument = gql`
    mutation deleteQuestion($id: String!, $course_id: String!) {
  deleteQuestion(id: $id, course_id: $course_id)
}
    `;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;

/**
 * __useDeleteQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionMutation, { data, loading, error }] = useDeleteQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      course_id: // value for 'course_id'
 *   },
 * });
 */
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export type DeleteQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export const AddCoursesToClusterDocument = gql`
    mutation addCoursesToCluster($campusName: String!, $clusterId: String!, $courseIds: [String!]!) {
  addCoursesToCluster(
    campusName: $campusName
    clusterId: $clusterId
    courseIds: $courseIds
  ) {
    id
    name
    courseIds
  }
}
    `;
export type AddCoursesToClusterMutationFn = Apollo.MutationFunction<AddCoursesToClusterMutation, AddCoursesToClusterMutationVariables>;

/**
 * __useAddCoursesToClusterMutation__
 *
 * To run a mutation, you first call `useAddCoursesToClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCoursesToClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCoursesToClusterMutation, { data, loading, error }] = useAddCoursesToClusterMutation({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      courseIds: // value for 'courseIds'
 *   },
 * });
 */
export function useAddCoursesToClusterMutation(baseOptions?: Apollo.MutationHookOptions<AddCoursesToClusterMutation, AddCoursesToClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCoursesToClusterMutation, AddCoursesToClusterMutationVariables>(AddCoursesToClusterDocument, options);
      }
export type AddCoursesToClusterMutationHookResult = ReturnType<typeof useAddCoursesToClusterMutation>;
export type AddCoursesToClusterMutationResult = Apollo.MutationResult<AddCoursesToClusterMutation>;
export type AddCoursesToClusterMutationOptions = Apollo.BaseMutationOptions<AddCoursesToClusterMutation, AddCoursesToClusterMutationVariables>;
export const RemoveCourseFromClusterDocument = gql`
    mutation removeCourseFromCluster($campusName: String!, $clusterId: String!, $courseId: String!) {
  removeCourseFromCluster(
    campusName: $campusName
    clusterId: $clusterId
    courseId: $courseId
  ) {
    id
    name
    courseIds
  }
}
    `;
export type RemoveCourseFromClusterMutationFn = Apollo.MutationFunction<RemoveCourseFromClusterMutation, RemoveCourseFromClusterMutationVariables>;

/**
 * __useRemoveCourseFromClusterMutation__
 *
 * To run a mutation, you first call `useRemoveCourseFromClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCourseFromClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCourseFromClusterMutation, { data, loading, error }] = useRemoveCourseFromClusterMutation({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useRemoveCourseFromClusterMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCourseFromClusterMutation, RemoveCourseFromClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCourseFromClusterMutation, RemoveCourseFromClusterMutationVariables>(RemoveCourseFromClusterDocument, options);
      }
export type RemoveCourseFromClusterMutationHookResult = ReturnType<typeof useRemoveCourseFromClusterMutation>;
export type RemoveCourseFromClusterMutationResult = Apollo.MutationResult<RemoveCourseFromClusterMutation>;
export type RemoveCourseFromClusterMutationOptions = Apollo.BaseMutationOptions<RemoveCourseFromClusterMutation, RemoveCourseFromClusterMutationVariables>;
export const UpdateClusterNameDocument = gql`
    mutation updateClusterName($updateClusterNameInput: UpdateClusterNameInput!) {
  updateClusterName(updateClusterNameInput: $updateClusterNameInput) {
    id
    name
    courseIds
  }
}
    `;
export type UpdateClusterNameMutationFn = Apollo.MutationFunction<UpdateClusterNameMutation, UpdateClusterNameMutationVariables>;

/**
 * __useUpdateClusterNameMutation__
 *
 * To run a mutation, you first call `useUpdateClusterNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClusterNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClusterNameMutation, { data, loading, error }] = useUpdateClusterNameMutation({
 *   variables: {
 *      updateClusterNameInput: // value for 'updateClusterNameInput'
 *   },
 * });
 */
export function useUpdateClusterNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClusterNameMutation, UpdateClusterNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClusterNameMutation, UpdateClusterNameMutationVariables>(UpdateClusterNameDocument, options);
      }
export type UpdateClusterNameMutationHookResult = ReturnType<typeof useUpdateClusterNameMutation>;
export type UpdateClusterNameMutationResult = Apollo.MutationResult<UpdateClusterNameMutation>;
export type UpdateClusterNameMutationOptions = Apollo.BaseMutationOptions<UpdateClusterNameMutation, UpdateClusterNameMutationVariables>;
export const AddClusterToStudentsDocument = gql`
    mutation addClusterToStudents($input: AddClusterToStudentsInput!) {
  addClusterToStudents(input: $input) {
    id
    name
    email
    phno
    dob
    placeOfResidence
    assignmentIds
    courseIds
    isInviteSend
  }
}
    `;
export type AddClusterToStudentsMutationFn = Apollo.MutationFunction<AddClusterToStudentsMutation, AddClusterToStudentsMutationVariables>;

/**
 * __useAddClusterToStudentsMutation__
 *
 * To run a mutation, you first call `useAddClusterToStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClusterToStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClusterToStudentsMutation, { data, loading, error }] = useAddClusterToStudentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddClusterToStudentsMutation(baseOptions?: Apollo.MutationHookOptions<AddClusterToStudentsMutation, AddClusterToStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClusterToStudentsMutation, AddClusterToStudentsMutationVariables>(AddClusterToStudentsDocument, options);
      }
export type AddClusterToStudentsMutationHookResult = ReturnType<typeof useAddClusterToStudentsMutation>;
export type AddClusterToStudentsMutationResult = Apollo.MutationResult<AddClusterToStudentsMutation>;
export type AddClusterToStudentsMutationOptions = Apollo.BaseMutationOptions<AddClusterToStudentsMutation, AddClusterToStudentsMutationVariables>;
export const RemoveClusterFromStudentsDocument = gql`
    mutation removeClusterFromStudents($input: RemoveClusterFromStudentsInput!) {
  removeClusterFromStudents(input: $input) {
    id
    name
    email
    phno
    dob
    placeOfResidence
    assignmentIds
    courseIds
    clusterIds
    isInviteSend
  }
}
    `;
export type RemoveClusterFromStudentsMutationFn = Apollo.MutationFunction<RemoveClusterFromStudentsMutation, RemoveClusterFromStudentsMutationVariables>;

/**
 * __useRemoveClusterFromStudentsMutation__
 *
 * To run a mutation, you first call `useRemoveClusterFromStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClusterFromStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClusterFromStudentsMutation, { data, loading, error }] = useRemoveClusterFromStudentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveClusterFromStudentsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClusterFromStudentsMutation, RemoveClusterFromStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClusterFromStudentsMutation, RemoveClusterFromStudentsMutationVariables>(RemoveClusterFromStudentsDocument, options);
      }
export type RemoveClusterFromStudentsMutationHookResult = ReturnType<typeof useRemoveClusterFromStudentsMutation>;
export type RemoveClusterFromStudentsMutationResult = Apollo.MutationResult<RemoveClusterFromStudentsMutation>;
export type RemoveClusterFromStudentsMutationOptions = Apollo.BaseMutationOptions<RemoveClusterFromStudentsMutation, RemoveClusterFromStudentsMutationVariables>;
export const SubmitCodeDocument = gql`
    mutation submitCode($compilerCodeInput: CompilerInput!, $submitInput: SubmitInput!) {
  submitCode(compilerCodeInput: $compilerCodeInput, submitInput: $submitInput) {
    compilationOutput {
      stdout
      stderr
    }
    testCasePassed {
      count
      testCaseIds {
        testCaseId
        stdout
      }
    }
    testCaseFailed {
      count
      testCaseIds {
        testCaseId
        stdout
      }
    }
    Results {
      input
      output
      expextedOutput
      error
    }
  }
}
    `;
export type SubmitCodeMutationFn = Apollo.MutationFunction<SubmitCodeMutation, SubmitCodeMutationVariables>;

/**
 * __useSubmitCodeMutation__
 *
 * To run a mutation, you first call `useSubmitCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCodeMutation, { data, loading, error }] = useSubmitCodeMutation({
 *   variables: {
 *      compilerCodeInput: // value for 'compilerCodeInput'
 *      submitInput: // value for 'submitInput'
 *   },
 * });
 */
export function useSubmitCodeMutation(baseOptions?: Apollo.MutationHookOptions<SubmitCodeMutation, SubmitCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitCodeMutation, SubmitCodeMutationVariables>(SubmitCodeDocument, options);
      }
export type SubmitCodeMutationHookResult = ReturnType<typeof useSubmitCodeMutation>;
export type SubmitCodeMutationResult = Apollo.MutationResult<SubmitCodeMutation>;
export type SubmitCodeMutationOptions = Apollo.BaseMutationOptions<SubmitCodeMutation, SubmitCodeMutationVariables>;
export const AddStaffToClusterDocument = gql`
    mutation AddStaffToCluster($campusName: String!, $clusterId: String!, $staffs: [UpdateStaffInput!]!) {
  addStaffToCluster(
    campusName: $campusName
    clusterId: $clusterId
    staffs: $staffs
  ) {
    staff {
      id
      email
      name
      designation
    }
    added
  }
}
    `;
export type AddStaffToClusterMutationFn = Apollo.MutationFunction<AddStaffToClusterMutation, AddStaffToClusterMutationVariables>;

/**
 * __useAddStaffToClusterMutation__
 *
 * To run a mutation, you first call `useAddStaffToClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStaffToClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStaffToClusterMutation, { data, loading, error }] = useAddStaffToClusterMutation({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      staffs: // value for 'staffs'
 *   },
 * });
 */
export function useAddStaffToClusterMutation(baseOptions?: Apollo.MutationHookOptions<AddStaffToClusterMutation, AddStaffToClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStaffToClusterMutation, AddStaffToClusterMutationVariables>(AddStaffToClusterDocument, options);
      }
export type AddStaffToClusterMutationHookResult = ReturnType<typeof useAddStaffToClusterMutation>;
export type AddStaffToClusterMutationResult = Apollo.MutationResult<AddStaffToClusterMutation>;
export type AddStaffToClusterMutationOptions = Apollo.BaseMutationOptions<AddStaffToClusterMutation, AddStaffToClusterMutationVariables>;
export const RemoveStaffFromClusterDocument = gql`
    mutation RemoveStaffFromCluster($campusName: String!, $clusterId: String!, $staffIds: [String!]!) {
  removeStaffFromCluster(
    campusName: $campusName
    clusterId: $clusterId
    staffIds: $staffIds
  ) {
    staffId
    removed
  }
}
    `;
export type RemoveStaffFromClusterMutationFn = Apollo.MutationFunction<RemoveStaffFromClusterMutation, RemoveStaffFromClusterMutationVariables>;

/**
 * __useRemoveStaffFromClusterMutation__
 *
 * To run a mutation, you first call `useRemoveStaffFromClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStaffFromClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStaffFromClusterMutation, { data, loading, error }] = useRemoveStaffFromClusterMutation({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      staffIds: // value for 'staffIds'
 *   },
 * });
 */
export function useRemoveStaffFromClusterMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStaffFromClusterMutation, RemoveStaffFromClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStaffFromClusterMutation, RemoveStaffFromClusterMutationVariables>(RemoveStaffFromClusterDocument, options);
      }
export type RemoveStaffFromClusterMutationHookResult = ReturnType<typeof useRemoveStaffFromClusterMutation>;
export type RemoveStaffFromClusterMutationResult = Apollo.MutationResult<RemoveStaffFromClusterMutation>;
export type RemoveStaffFromClusterMutationOptions = Apollo.BaseMutationOptions<RemoveStaffFromClusterMutation, RemoveStaffFromClusterMutationVariables>;
export const ToggleSendEmailDocument = gql`
    mutation ToggleSendEmail($campusName: String!, $clusterId: String!, $sendEmail: Boolean!) {
  toggleSendEmail(
    campusName: $campusName
    clusterId: $clusterId
    sendEmail: $sendEmail
  ) {
    sendEmail
  }
}
    `;
export type ToggleSendEmailMutationFn = Apollo.MutationFunction<ToggleSendEmailMutation, ToggleSendEmailMutationVariables>;

/**
 * __useToggleSendEmailMutation__
 *
 * To run a mutation, you first call `useToggleSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSendEmailMutation, { data, loading, error }] = useToggleSendEmailMutation({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useToggleSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSendEmailMutation, ToggleSendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSendEmailMutation, ToggleSendEmailMutationVariables>(ToggleSendEmailDocument, options);
      }
export type ToggleSendEmailMutationHookResult = ReturnType<typeof useToggleSendEmailMutation>;
export type ToggleSendEmailMutationResult = Apollo.MutationResult<ToggleSendEmailMutation>;
export type ToggleSendEmailMutationOptions = Apollo.BaseMutationOptions<ToggleSendEmailMutation, ToggleSendEmailMutationVariables>;
export const SendEmailDocument = gql`
    mutation SendEmail($emailInput: EmailInput!) {
  sendEmail(emailInput: $emailInput)
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;

/**
 * __useSendEmailMutation__
 *
 * To run a mutation, you first call `useSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailMutation, { data, loading, error }] = useSendEmailMutation({
 *   variables: {
 *      emailInput: // value for 'emailInput'
 *   },
 * });
 */
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = Apollo.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const CreateAssignmentDocument = gql`
    mutation CreateAssignment($createAssignmentInput: CreateAssignmentInput!) {
  createAssignment(createAssignmentInput: $createAssignmentInput) {
    _id
    id
    name
    short_description
    description
    due_date
    languages
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<CreateAssignmentMutation, CreateAssignmentMutationVariables>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      createAssignmentInput: // value for 'createAssignmentInput'
 *   },
 * });
 */
export function useCreateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(CreateAssignmentDocument, options);
      }
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>;
export const UpdateAssignmentDocument = gql`
    mutation UpdateAssignment($id: String!, $updateAssignmentInput: UpdateAssignmentInput!) {
  updateAssignment(id: $id, updateAssignmentInput: $updateAssignmentInput) {
    _id
    id
    name
    short_description
    description
    due_date
    languages
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateAssignmentInput: // value for 'updateAssignmentInput'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, options);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = Apollo.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;
export const RemoveAssignmentDocument = gql`
    mutation RemoveAssignment($id: String!) {
  removeAssignment(id: $id) {
    _id
    id
    name
    short_description
    description
    due_date
    languages
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;
export type RemoveAssignmentMutationFn = Apollo.MutationFunction<RemoveAssignmentMutation, RemoveAssignmentMutationVariables>;

/**
 * __useRemoveAssignmentMutation__
 *
 * To run a mutation, you first call `useRemoveAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignmentMutation, { data, loading, error }] = useRemoveAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAssignmentMutation, RemoveAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAssignmentMutation, RemoveAssignmentMutationVariables>(RemoveAssignmentDocument, options);
      }
export type RemoveAssignmentMutationHookResult = ReturnType<typeof useRemoveAssignmentMutation>;
export type RemoveAssignmentMutationResult = Apollo.MutationResult<RemoveAssignmentMutation>;
export type RemoveAssignmentMutationOptions = Apollo.BaseMutationOptions<RemoveAssignmentMutation, RemoveAssignmentMutationVariables>;
export const CreateBulkAssignmentQuestionsDocument = gql`
    mutation CreateBulkAssignmentQuestions($createQuestionsInput: [CreateAssignmentQuestionInput!]!) {
  createBulkAssignmentQuestions(createQuestionsInput: $createQuestionsInput) {
    id
    assignment_id
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    marks
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;
export type CreateBulkAssignmentQuestionsMutationFn = Apollo.MutationFunction<CreateBulkAssignmentQuestionsMutation, CreateBulkAssignmentQuestionsMutationVariables>;

/**
 * __useCreateBulkAssignmentQuestionsMutation__
 *
 * To run a mutation, you first call `useCreateBulkAssignmentQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkAssignmentQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkAssignmentQuestionsMutation, { data, loading, error }] = useCreateBulkAssignmentQuestionsMutation({
 *   variables: {
 *      createQuestionsInput: // value for 'createQuestionsInput'
 *   },
 * });
 */
export function useCreateBulkAssignmentQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateBulkAssignmentQuestionsMutation, CreateBulkAssignmentQuestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBulkAssignmentQuestionsMutation, CreateBulkAssignmentQuestionsMutationVariables>(CreateBulkAssignmentQuestionsDocument, options);
      }
export type CreateBulkAssignmentQuestionsMutationHookResult = ReturnType<typeof useCreateBulkAssignmentQuestionsMutation>;
export type CreateBulkAssignmentQuestionsMutationResult = Apollo.MutationResult<CreateBulkAssignmentQuestionsMutation>;
export type CreateBulkAssignmentQuestionsMutationOptions = Apollo.BaseMutationOptions<CreateBulkAssignmentQuestionsMutation, CreateBulkAssignmentQuestionsMutationVariables>;
export const CreateAssignmentQuestionDocument = gql`
    mutation createAssignmentQuestion($createQuestionInput: CreateAssignmentQuestionInput!) {
  createAssignmentQuestion(createQuestionInput: $createQuestionInput) {
    id
    assignment_id
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    marks
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;
export type CreateAssignmentQuestionMutationFn = Apollo.MutationFunction<CreateAssignmentQuestionMutation, CreateAssignmentQuestionMutationVariables>;

/**
 * __useCreateAssignmentQuestionMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentQuestionMutation, { data, loading, error }] = useCreateAssignmentQuestionMutation({
 *   variables: {
 *      createQuestionInput: // value for 'createQuestionInput'
 *   },
 * });
 */
export function useCreateAssignmentQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssignmentQuestionMutation, CreateAssignmentQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssignmentQuestionMutation, CreateAssignmentQuestionMutationVariables>(CreateAssignmentQuestionDocument, options);
      }
export type CreateAssignmentQuestionMutationHookResult = ReturnType<typeof useCreateAssignmentQuestionMutation>;
export type CreateAssignmentQuestionMutationResult = Apollo.MutationResult<CreateAssignmentQuestionMutation>;
export type CreateAssignmentQuestionMutationOptions = Apollo.BaseMutationOptions<CreateAssignmentQuestionMutation, CreateAssignmentQuestionMutationVariables>;
export const UpdateAssignmentQuestionDocument = gql`
    mutation updateAssignmentQuestion($id: String!, $updateQuestionInput: UpdateAssignmentQuestionInput!) {
  updateAssignmentQuestion(id: $id, updateQuestionInput: $updateQuestionInput) {
    id
    assignment_id
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    marks
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;
export type UpdateAssignmentQuestionMutationFn = Apollo.MutationFunction<UpdateAssignmentQuestionMutation, UpdateAssignmentQuestionMutationVariables>;

/**
 * __useUpdateAssignmentQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentQuestionMutation, { data, loading, error }] = useUpdateAssignmentQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateQuestionInput: // value for 'updateQuestionInput'
 *   },
 * });
 */
export function useUpdateAssignmentQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentQuestionMutation, UpdateAssignmentQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignmentQuestionMutation, UpdateAssignmentQuestionMutationVariables>(UpdateAssignmentQuestionDocument, options);
      }
export type UpdateAssignmentQuestionMutationHookResult = ReturnType<typeof useUpdateAssignmentQuestionMutation>;
export type UpdateAssignmentQuestionMutationResult = Apollo.MutationResult<UpdateAssignmentQuestionMutation>;
export type UpdateAssignmentQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateAssignmentQuestionMutation, UpdateAssignmentQuestionMutationVariables>;
export const DeleteAssignmentQuestionDocument = gql`
    mutation deleteAssignmentQuestion($id: String!) {
  deleteAssignmentQuestion(id: $id)
}
    `;
export type DeleteAssignmentQuestionMutationFn = Apollo.MutationFunction<DeleteAssignmentQuestionMutation, DeleteAssignmentQuestionMutationVariables>;

/**
 * __useDeleteAssignmentQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteAssignmentQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignmentQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignmentQuestionMutation, { data, loading, error }] = useDeleteAssignmentQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssignmentQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssignmentQuestionMutation, DeleteAssignmentQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssignmentQuestionMutation, DeleteAssignmentQuestionMutationVariables>(DeleteAssignmentQuestionDocument, options);
      }
export type DeleteAssignmentQuestionMutationHookResult = ReturnType<typeof useDeleteAssignmentQuestionMutation>;
export type DeleteAssignmentQuestionMutationResult = Apollo.MutationResult<DeleteAssignmentQuestionMutation>;
export type DeleteAssignmentQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteAssignmentQuestionMutation, DeleteAssignmentQuestionMutationVariables>;
export const RemoveAssignmentFromStudentDocument = gql`
    mutation RemoveAssignmentFromStudent($removeAssignmentInput: RemoveAssignmentInput!) {
  removeAssignmentFromStudent(removeAssignmentInput: $removeAssignmentInput) {
    id
    name
    email
    assignmentIds
  }
}
    `;
export type RemoveAssignmentFromStudentMutationFn = Apollo.MutationFunction<RemoveAssignmentFromStudentMutation, RemoveAssignmentFromStudentMutationVariables>;

/**
 * __useRemoveAssignmentFromStudentMutation__
 *
 * To run a mutation, you first call `useRemoveAssignmentFromStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignmentFromStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignmentFromStudentMutation, { data, loading, error }] = useRemoveAssignmentFromStudentMutation({
 *   variables: {
 *      removeAssignmentInput: // value for 'removeAssignmentInput'
 *   },
 * });
 */
export function useRemoveAssignmentFromStudentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAssignmentFromStudentMutation, RemoveAssignmentFromStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAssignmentFromStudentMutation, RemoveAssignmentFromStudentMutationVariables>(RemoveAssignmentFromStudentDocument, options);
      }
export type RemoveAssignmentFromStudentMutationHookResult = ReturnType<typeof useRemoveAssignmentFromStudentMutation>;
export type RemoveAssignmentFromStudentMutationResult = Apollo.MutationResult<RemoveAssignmentFromStudentMutation>;
export type RemoveAssignmentFromStudentMutationOptions = Apollo.BaseMutationOptions<RemoveAssignmentFromStudentMutation, RemoveAssignmentFromStudentMutationVariables>;
export const SubmitAssignmentCodeDocument = gql`
    mutation SubmitAssignmentCode($assignmentCompilerCodeInput: CompilerInput!, $assignmentSubmitInput: AssignmentSubmitInput!) {
  submitAssignmentCode(
    assignmentCompilerCodeInput: $assignmentCompilerCodeInput
    assignmentSubmitInput: $assignmentSubmitInput
  ) {
    compilationOutput {
      stdout
      stderr
    }
    testCasePassed {
      count
      testCaseIds {
        testCaseId
        stdout
      }
    }
    testCaseFailed {
      count
      testCaseIds {
        testCaseId
        stdout
      }
    }
    Results {
      input
      output
      expextedOutput
      error
    }
  }
}
    `;
export type SubmitAssignmentCodeMutationFn = Apollo.MutationFunction<SubmitAssignmentCodeMutation, SubmitAssignmentCodeMutationVariables>;

/**
 * __useSubmitAssignmentCodeMutation__
 *
 * To run a mutation, you first call `useSubmitAssignmentCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAssignmentCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAssignmentCodeMutation, { data, loading, error }] = useSubmitAssignmentCodeMutation({
 *   variables: {
 *      assignmentCompilerCodeInput: // value for 'assignmentCompilerCodeInput'
 *      assignmentSubmitInput: // value for 'assignmentSubmitInput'
 *   },
 * });
 */
export function useSubmitAssignmentCodeMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAssignmentCodeMutation, SubmitAssignmentCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAssignmentCodeMutation, SubmitAssignmentCodeMutationVariables>(SubmitAssignmentCodeDocument, options);
      }
export type SubmitAssignmentCodeMutationHookResult = ReturnType<typeof useSubmitAssignmentCodeMutation>;
export type SubmitAssignmentCodeMutationResult = Apollo.MutationResult<SubmitAssignmentCodeMutation>;
export type SubmitAssignmentCodeMutationOptions = Apollo.BaseMutationOptions<SubmitAssignmentCodeMutation, SubmitAssignmentCodeMutationVariables>;
export const FinalSubmitAssignmentDocument = gql`
    mutation FinalSubmitAssignment($AssignmentInput: AssignmentInput!) {
  finalSubmitAssignment(AssignmentInput: $AssignmentInput) {
    id
    assignment_id
    student_id
    questions {
      question_id
      answer
      score
      language
    }
    totalScore
    tabSwitches
    cheat_count
    completed
  }
}
    `;
export type FinalSubmitAssignmentMutationFn = Apollo.MutationFunction<FinalSubmitAssignmentMutation, FinalSubmitAssignmentMutationVariables>;

/**
 * __useFinalSubmitAssignmentMutation__
 *
 * To run a mutation, you first call `useFinalSubmitAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalSubmitAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalSubmitAssignmentMutation, { data, loading, error }] = useFinalSubmitAssignmentMutation({
 *   variables: {
 *      AssignmentInput: // value for 'AssignmentInput'
 *   },
 * });
 */
export function useFinalSubmitAssignmentMutation(baseOptions?: Apollo.MutationHookOptions<FinalSubmitAssignmentMutation, FinalSubmitAssignmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalSubmitAssignmentMutation, FinalSubmitAssignmentMutationVariables>(FinalSubmitAssignmentDocument, options);
      }
export type FinalSubmitAssignmentMutationHookResult = ReturnType<typeof useFinalSubmitAssignmentMutation>;
export type FinalSubmitAssignmentMutationResult = Apollo.MutationResult<FinalSubmitAssignmentMutation>;
export type FinalSubmitAssignmentMutationOptions = Apollo.BaseMutationOptions<FinalSubmitAssignmentMutation, FinalSubmitAssignmentMutationVariables>;
export const DeleteBulkStudentsDocument = gql`
    mutation DeleteBulkStudents($studentIds: [String!]!, $campusName: String!) {
  deleteBulkStudents(studentIds: $studentIds, campusName: $campusName)
}
    `;
export type DeleteBulkStudentsMutationFn = Apollo.MutationFunction<DeleteBulkStudentsMutation, DeleteBulkStudentsMutationVariables>;

/**
 * __useDeleteBulkStudentsMutation__
 *
 * To run a mutation, you first call `useDeleteBulkStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulkStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulkStudentsMutation, { data, loading, error }] = useDeleteBulkStudentsMutation({
 *   variables: {
 *      studentIds: // value for 'studentIds'
 *      campusName: // value for 'campusName'
 *   },
 * });
 */
export function useDeleteBulkStudentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBulkStudentsMutation, DeleteBulkStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBulkStudentsMutation, DeleteBulkStudentsMutationVariables>(DeleteBulkStudentsDocument, options);
      }
export type DeleteBulkStudentsMutationHookResult = ReturnType<typeof useDeleteBulkStudentsMutation>;
export type DeleteBulkStudentsMutationResult = Apollo.MutationResult<DeleteBulkStudentsMutation>;
export type DeleteBulkStudentsMutationOptions = Apollo.BaseMutationOptions<DeleteBulkStudentsMutation, DeleteBulkStudentsMutationVariables>;
export const AddQuestionsToCourseDocument = gql`
    mutation addQuestionsToCourse($courseId: String!, $questionIds: [String!]!) {
  addQuestionsToCourse(courseId: $courseId, questionIds: $questionIds) {
    id
    name
    short_description
    description
    categories {
      id
      name
      description
    }
    price
    time_period
    languages
    level
    domain
    question_ids
    easy_count
    hard_count
    medium_count
  }
}
    `;
export type AddQuestionsToCourseMutationFn = Apollo.MutationFunction<AddQuestionsToCourseMutation, AddQuestionsToCourseMutationVariables>;

/**
 * __useAddQuestionsToCourseMutation__
 *
 * To run a mutation, you first call `useAddQuestionsToCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionsToCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionsToCourseMutation, { data, loading, error }] = useAddQuestionsToCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useAddQuestionsToCourseMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionsToCourseMutation, AddQuestionsToCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQuestionsToCourseMutation, AddQuestionsToCourseMutationVariables>(AddQuestionsToCourseDocument, options);
      }
export type AddQuestionsToCourseMutationHookResult = ReturnType<typeof useAddQuestionsToCourseMutation>;
export type AddQuestionsToCourseMutationResult = Apollo.MutationResult<AddQuestionsToCourseMutation>;
export type AddQuestionsToCourseMutationOptions = Apollo.BaseMutationOptions<AddQuestionsToCourseMutation, AddQuestionsToCourseMutationVariables>;
export const RemoveAssignmentFromClusterDocument = gql`
    mutation removeAssignmentFromCluster($campusName: String!, $clusterId: String!, $assignmentId: String!) {
  removeAssignmentFromCluster(
    campusName: $campusName
    clusterId: $clusterId
    assignmentId: $assignmentId
  ) {
    courseIds
    studentCount
  }
}
    `;
export type RemoveAssignmentFromClusterMutationFn = Apollo.MutationFunction<RemoveAssignmentFromClusterMutation, RemoveAssignmentFromClusterMutationVariables>;

/**
 * __useRemoveAssignmentFromClusterMutation__
 *
 * To run a mutation, you first call `useRemoveAssignmentFromClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAssignmentFromClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAssignmentFromClusterMutation, { data, loading, error }] = useRemoveAssignmentFromClusterMutation({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useRemoveAssignmentFromClusterMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAssignmentFromClusterMutation, RemoveAssignmentFromClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAssignmentFromClusterMutation, RemoveAssignmentFromClusterMutationVariables>(RemoveAssignmentFromClusterDocument, options);
      }
export type RemoveAssignmentFromClusterMutationHookResult = ReturnType<typeof useRemoveAssignmentFromClusterMutation>;
export type RemoveAssignmentFromClusterMutationResult = Apollo.MutationResult<RemoveAssignmentFromClusterMutation>;
export type RemoveAssignmentFromClusterMutationOptions = Apollo.BaseMutationOptions<RemoveAssignmentFromClusterMutation, RemoveAssignmentFromClusterMutationVariables>;
export const UpdateAdminDocument = gql`
    mutation UpdateAdmin($updateAdminInput: UpdateAdminInput!) {
  updateAdmin(updateAdminInput: $updateAdminInput) {
    id
    name
    email
    isSuperAdmin
    permissions {
      courses
      assignments
      reports
      student_creation
    }
  }
}
    `;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      updateAdminInput: // value for 'updateAdminInput'
 *   },
 * });
 */
export function useUpdateAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
      }
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const SaveAutoSaveDocument = gql`
    mutation saveAutoSave($submitInput: SubmitInput!, $code: String!, $language: String!) {
  saveAutoSave(submitInput: $submitInput, code: $code, language: $language)
}
    `;
export type SaveAutoSaveMutationFn = Apollo.MutationFunction<SaveAutoSaveMutation, SaveAutoSaveMutationVariables>;

/**
 * __useSaveAutoSaveMutation__
 *
 * To run a mutation, you first call `useSaveAutoSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAutoSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAutoSaveMutation, { data, loading, error }] = useSaveAutoSaveMutation({
 *   variables: {
 *      submitInput: // value for 'submitInput'
 *      code: // value for 'code'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSaveAutoSaveMutation(baseOptions?: Apollo.MutationHookOptions<SaveAutoSaveMutation, SaveAutoSaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAutoSaveMutation, SaveAutoSaveMutationVariables>(SaveAutoSaveDocument, options);
      }
export type SaveAutoSaveMutationHookResult = ReturnType<typeof useSaveAutoSaveMutation>;
export type SaveAutoSaveMutationResult = Apollo.MutationResult<SaveAutoSaveMutation>;
export type SaveAutoSaveMutationOptions = Apollo.BaseMutationOptions<SaveAutoSaveMutation, SaveAutoSaveMutationVariables>;
export const SaveAssignmentCodeDocument = gql`
    mutation saveAssignmentCode($saveCodeInput: AssignmentSubmitInput!, $code: String!, $language: String!) {
  saveAssignmentCode(
    saveCodeInput: $saveCodeInput
    code: $code
    language: $language
  )
}
    `;
export type SaveAssignmentCodeMutationFn = Apollo.MutationFunction<SaveAssignmentCodeMutation, SaveAssignmentCodeMutationVariables>;

/**
 * __useSaveAssignmentCodeMutation__
 *
 * To run a mutation, you first call `useSaveAssignmentCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssignmentCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssignmentCodeMutation, { data, loading, error }] = useSaveAssignmentCodeMutation({
 *   variables: {
 *      saveCodeInput: // value for 'saveCodeInput'
 *      code: // value for 'code'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSaveAssignmentCodeMutation(baseOptions?: Apollo.MutationHookOptions<SaveAssignmentCodeMutation, SaveAssignmentCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAssignmentCodeMutation, SaveAssignmentCodeMutationVariables>(SaveAssignmentCodeDocument, options);
      }
export type SaveAssignmentCodeMutationHookResult = ReturnType<typeof useSaveAssignmentCodeMutation>;
export type SaveAssignmentCodeMutationResult = Apollo.MutationResult<SaveAssignmentCodeMutation>;
export type SaveAssignmentCodeMutationOptions = Apollo.BaseMutationOptions<SaveAssignmentCodeMutation, SaveAssignmentCodeMutationVariables>;
export const AddAssignmentToClusterDocument = gql`
    mutation addAssignmentToCluster($campusName: String!, $clusterId: String!, $assignmentId: String!) {
  addAssignmentToCluster(
    campusName: $campusName
    clusterId: $clusterId
    assignmentId: $assignmentId
  ) {
    __typename
    id
    name
    campusName
    courseIds
    assignmentIds
    createdAt
    studentCount
    staffs {
      id
      name
      email
      campusId
      designation
      contactNo
      clusterIds
      isInviteSend
      isAdminStaff
    }
    sendEmail
  }
}
    `;
export type AddAssignmentToClusterMutationFn = Apollo.MutationFunction<AddAssignmentToClusterMutation, AddAssignmentToClusterMutationVariables>;

/**
 * __useAddAssignmentToClusterMutation__
 *
 * To run a mutation, you first call `useAddAssignmentToClusterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssignmentToClusterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssignmentToClusterMutation, { data, loading, error }] = useAddAssignmentToClusterMutation({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useAddAssignmentToClusterMutation(baseOptions?: Apollo.MutationHookOptions<AddAssignmentToClusterMutation, AddAssignmentToClusterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAssignmentToClusterMutation, AddAssignmentToClusterMutationVariables>(AddAssignmentToClusterDocument, options);
      }
export type AddAssignmentToClusterMutationHookResult = ReturnType<typeof useAddAssignmentToClusterMutation>;
export type AddAssignmentToClusterMutationResult = Apollo.MutationResult<AddAssignmentToClusterMutation>;
export type AddAssignmentToClusterMutationOptions = Apollo.BaseMutationOptions<AddAssignmentToClusterMutation, AddAssignmentToClusterMutationVariables>;
export const GetCampusDocument = gql`
    query GetCampus($id: String!) {
  campus(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetCampusQuery__
 *
 * To run a query within a React component, call `useGetCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampusQuery(baseOptions: Apollo.QueryHookOptions<GetCampusQuery, GetCampusQueryVariables> & ({ variables: GetCampusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampusQuery, GetCampusQueryVariables>(GetCampusDocument, options);
      }
export function useGetCampusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampusQuery, GetCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampusQuery, GetCampusQueryVariables>(GetCampusDocument, options);
        }
export function useGetCampusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCampusQuery, GetCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampusQuery, GetCampusQueryVariables>(GetCampusDocument, options);
        }
export type GetCampusQueryHookResult = ReturnType<typeof useGetCampusQuery>;
export type GetCampusLazyQueryHookResult = ReturnType<typeof useGetCampusLazyQuery>;
export type GetCampusSuspenseQueryHookResult = ReturnType<typeof useGetCampusSuspenseQuery>;
export type GetCampusQueryResult = Apollo.QueryResult<GetCampusQuery, GetCampusQueryVariables>;
export const GetAllCampusesDocument = gql`
    query GetAllCampuses {
  allCampus {
    id
    name
  }
}
    `;

/**
 * __useGetAllCampusesQuery__
 *
 * To run a query within a React component, call `useGetAllCampusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCampusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCampusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCampusesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCampusesQuery, GetAllCampusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCampusesQuery, GetAllCampusesQueryVariables>(GetAllCampusesDocument, options);
      }
export function useGetAllCampusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCampusesQuery, GetAllCampusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCampusesQuery, GetAllCampusesQueryVariables>(GetAllCampusesDocument, options);
        }
export function useGetAllCampusesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllCampusesQuery, GetAllCampusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCampusesQuery, GetAllCampusesQueryVariables>(GetAllCampusesDocument, options);
        }
export type GetAllCampusesQueryHookResult = ReturnType<typeof useGetAllCampusesQuery>;
export type GetAllCampusesLazyQueryHookResult = ReturnType<typeof useGetAllCampusesLazyQuery>;
export type GetAllCampusesSuspenseQueryHookResult = ReturnType<typeof useGetAllCampusesSuspenseQuery>;
export type GetAllCampusesQueryResult = Apollo.QueryResult<GetAllCampusesQuery, GetAllCampusesQueryVariables>;
export const GetAllAdminDocument = gql`
    query GetAllAdmin {
  getAllAdmin {
    id
    name
    email
    isSuperAdmin
    permissions {
      courses
      assignments
      reports
      student_creation
    }
  }
}
    `;

/**
 * __useGetAllAdminQuery__
 *
 * To run a query within a React component, call `useGetAllAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAdminQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAdminQuery, GetAllAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAdminQuery, GetAllAdminQueryVariables>(GetAllAdminDocument, options);
      }
export function useGetAllAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAdminQuery, GetAllAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAdminQuery, GetAllAdminQueryVariables>(GetAllAdminDocument, options);
        }
export function useGetAllAdminSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllAdminQuery, GetAllAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAdminQuery, GetAllAdminQueryVariables>(GetAllAdminDocument, options);
        }
export type GetAllAdminQueryHookResult = ReturnType<typeof useGetAllAdminQuery>;
export type GetAllAdminLazyQueryHookResult = ReturnType<typeof useGetAllAdminLazyQuery>;
export type GetAllAdminSuspenseQueryHookResult = ReturnType<typeof useGetAllAdminSuspenseQuery>;
export type GetAllAdminQueryResult = Apollo.QueryResult<GetAllAdminQuery, GetAllAdminQueryVariables>;
export const GetAllStaffsDocument = gql`
    query GetAllStaffs {
  getAllStaffs {
    id
    name
    email
    campusId
  }
}
    `;

/**
 * __useGetAllStaffsQuery__
 *
 * To run a query within a React component, call `useGetAllStaffsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStaffsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStaffsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStaffsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStaffsQuery, GetAllStaffsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStaffsQuery, GetAllStaffsQueryVariables>(GetAllStaffsDocument, options);
      }
export function useGetAllStaffsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStaffsQuery, GetAllStaffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStaffsQuery, GetAllStaffsQueryVariables>(GetAllStaffsDocument, options);
        }
export function useGetAllStaffsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllStaffsQuery, GetAllStaffsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllStaffsQuery, GetAllStaffsQueryVariables>(GetAllStaffsDocument, options);
        }
export type GetAllStaffsQueryHookResult = ReturnType<typeof useGetAllStaffsQuery>;
export type GetAllStaffsLazyQueryHookResult = ReturnType<typeof useGetAllStaffsLazyQuery>;
export type GetAllStaffsSuspenseQueryHookResult = ReturnType<typeof useGetAllStaffsSuspenseQuery>;
export type GetAllStaffsQueryResult = Apollo.QueryResult<GetAllStaffsQuery, GetAllStaffsQueryVariables>;
export const GetClustersByCampusDocument = gql`
    query GetClustersByCampus($campusName: String!, $searchTerm: String, $page: Int, $limit: Int) {
  getClustersByCampus(
    campusName: $campusName
    searchTerm: $searchTerm
    page: $page
    limit: $limit
  ) {
    id
    name
    studentCount
  }
}
    `;

/**
 * __useGetClustersByCampusQuery__
 *
 * To run a query within a React component, call `useGetClustersByCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClustersByCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClustersByCampusQuery({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      searchTerm: // value for 'searchTerm'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetClustersByCampusQuery(baseOptions: Apollo.QueryHookOptions<GetClustersByCampusQuery, GetClustersByCampusQueryVariables> & ({ variables: GetClustersByCampusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClustersByCampusQuery, GetClustersByCampusQueryVariables>(GetClustersByCampusDocument, options);
      }
export function useGetClustersByCampusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClustersByCampusQuery, GetClustersByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClustersByCampusQuery, GetClustersByCampusQueryVariables>(GetClustersByCampusDocument, options);
        }
export function useGetClustersByCampusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClustersByCampusQuery, GetClustersByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClustersByCampusQuery, GetClustersByCampusQueryVariables>(GetClustersByCampusDocument, options);
        }
export type GetClustersByCampusQueryHookResult = ReturnType<typeof useGetClustersByCampusQuery>;
export type GetClustersByCampusLazyQueryHookResult = ReturnType<typeof useGetClustersByCampusLazyQuery>;
export type GetClustersByCampusSuspenseQueryHookResult = ReturnType<typeof useGetClustersByCampusSuspenseQuery>;
export type GetClustersByCampusQueryResult = Apollo.QueryResult<GetClustersByCampusQuery, GetClustersByCampusQueryVariables>;
export const GetStudentsByClusterDocument = gql`
    query GetStudentsByCluster($clusterId: String!, $searchTerm: String, $page: Int, $limit: Int) {
  getStudentsByCluster(
    clusterId: $clusterId
    searchTerm: $searchTerm
    page: $page
    limit: $limit
  ) {
    id
    name
    email
    phno
    dob
    rollNo
    placeOfResidence
    leetCodeUname
    hackerRankUname
    campusId
    assignmentIds
    clusterIds
    courseIds
    isInviteSend
  }
}
    `;

/**
 * __useGetStudentsByClusterQuery__
 *
 * To run a query within a React component, call `useGetStudentsByClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsByClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsByClusterQuery({
 *   variables: {
 *      clusterId: // value for 'clusterId'
 *      searchTerm: // value for 'searchTerm'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetStudentsByClusterQuery(baseOptions: Apollo.QueryHookOptions<GetStudentsByClusterQuery, GetStudentsByClusterQueryVariables> & ({ variables: GetStudentsByClusterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsByClusterQuery, GetStudentsByClusterQueryVariables>(GetStudentsByClusterDocument, options);
      }
export function useGetStudentsByClusterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsByClusterQuery, GetStudentsByClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsByClusterQuery, GetStudentsByClusterQueryVariables>(GetStudentsByClusterDocument, options);
        }
export function useGetStudentsByClusterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentsByClusterQuery, GetStudentsByClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentsByClusterQuery, GetStudentsByClusterQueryVariables>(GetStudentsByClusterDocument, options);
        }
export type GetStudentsByClusterQueryHookResult = ReturnType<typeof useGetStudentsByClusterQuery>;
export type GetStudentsByClusterLazyQueryHookResult = ReturnType<typeof useGetStudentsByClusterLazyQuery>;
export type GetStudentsByClusterSuspenseQueryHookResult = ReturnType<typeof useGetStudentsByClusterSuspenseQuery>;
export type GetStudentsByClusterQueryResult = Apollo.QueryResult<GetStudentsByClusterQuery, GetStudentsByClusterQueryVariables>;
export const GetStaffsByCampusDocument = gql`
    query GetStaffsByCampus($campusId: String!) {
  getStaffsByCampus(campusId: $campusId) {
    id
    name
    email
    campusId
    designation
    contactNo
    isInviteSend
    clusterIds
    isAdminStaff
  }
}
    `;

/**
 * __useGetStaffsByCampusQuery__
 *
 * To run a query within a React component, call `useGetStaffsByCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffsByCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffsByCampusQuery({
 *   variables: {
 *      campusId: // value for 'campusId'
 *   },
 * });
 */
export function useGetStaffsByCampusQuery(baseOptions: Apollo.QueryHookOptions<GetStaffsByCampusQuery, GetStaffsByCampusQueryVariables> & ({ variables: GetStaffsByCampusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffsByCampusQuery, GetStaffsByCampusQueryVariables>(GetStaffsByCampusDocument, options);
      }
export function useGetStaffsByCampusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffsByCampusQuery, GetStaffsByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffsByCampusQuery, GetStaffsByCampusQueryVariables>(GetStaffsByCampusDocument, options);
        }
export function useGetStaffsByCampusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStaffsByCampusQuery, GetStaffsByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStaffsByCampusQuery, GetStaffsByCampusQueryVariables>(GetStaffsByCampusDocument, options);
        }
export type GetStaffsByCampusQueryHookResult = ReturnType<typeof useGetStaffsByCampusQuery>;
export type GetStaffsByCampusLazyQueryHookResult = ReturnType<typeof useGetStaffsByCampusLazyQuery>;
export type GetStaffsByCampusSuspenseQueryHookResult = ReturnType<typeof useGetStaffsByCampusSuspenseQuery>;
export type GetStaffsByCampusQueryResult = Apollo.QueryResult<GetStaffsByCampusQuery, GetStaffsByCampusQueryVariables>;
export const GetStaffByEmailIdDocument = gql`
    query GetStaffByEmailId($email: String!) {
  getStaffByEmailId(email: $email) {
    id
    name
    email
    designation
    contactNo
    campusId
    isInviteSend
    isAdminStaff
    clusterIds
  }
}
    `;

/**
 * __useGetStaffByEmailIdQuery__
 *
 * To run a query within a React component, call `useGetStaffByEmailIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffByEmailIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffByEmailIdQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetStaffByEmailIdQuery(baseOptions: Apollo.QueryHookOptions<GetStaffByEmailIdQuery, GetStaffByEmailIdQueryVariables> & ({ variables: GetStaffByEmailIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffByEmailIdQuery, GetStaffByEmailIdQueryVariables>(GetStaffByEmailIdDocument, options);
      }
export function useGetStaffByEmailIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffByEmailIdQuery, GetStaffByEmailIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffByEmailIdQuery, GetStaffByEmailIdQueryVariables>(GetStaffByEmailIdDocument, options);
        }
export function useGetStaffByEmailIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStaffByEmailIdQuery, GetStaffByEmailIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStaffByEmailIdQuery, GetStaffByEmailIdQueryVariables>(GetStaffByEmailIdDocument, options);
        }
export type GetStaffByEmailIdQueryHookResult = ReturnType<typeof useGetStaffByEmailIdQuery>;
export type GetStaffByEmailIdLazyQueryHookResult = ReturnType<typeof useGetStaffByEmailIdLazyQuery>;
export type GetStaffByEmailIdSuspenseQueryHookResult = ReturnType<typeof useGetStaffByEmailIdSuspenseQuery>;
export type GetStaffByEmailIdQueryResult = Apollo.QueryResult<GetStaffByEmailIdQuery, GetStaffByEmailIdQueryVariables>;
export const GetStudentByEmailIdDocument = gql`
    query GetStudentByEmailId($email: String!) {
  getStudentByEmailId(email: $email) {
    id
    name
    email
    phno
    dob
    rollNo
    campusId
    leetCodeUname
    hackerRankUname
    placeOfResidence
    assignmentIds
    courseIds
    clusterIds
    completedQuestions
  }
}
    `;

/**
 * __useGetStudentByEmailIdQuery__
 *
 * To run a query within a React component, call `useGetStudentByEmailIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentByEmailIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentByEmailIdQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetStudentByEmailIdQuery(baseOptions: Apollo.QueryHookOptions<GetStudentByEmailIdQuery, GetStudentByEmailIdQueryVariables> & ({ variables: GetStudentByEmailIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentByEmailIdQuery, GetStudentByEmailIdQueryVariables>(GetStudentByEmailIdDocument, options);
      }
export function useGetStudentByEmailIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentByEmailIdQuery, GetStudentByEmailIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentByEmailIdQuery, GetStudentByEmailIdQueryVariables>(GetStudentByEmailIdDocument, options);
        }
export function useGetStudentByEmailIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentByEmailIdQuery, GetStudentByEmailIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentByEmailIdQuery, GetStudentByEmailIdQueryVariables>(GetStudentByEmailIdDocument, options);
        }
export type GetStudentByEmailIdQueryHookResult = ReturnType<typeof useGetStudentByEmailIdQuery>;
export type GetStudentByEmailIdLazyQueryHookResult = ReturnType<typeof useGetStudentByEmailIdLazyQuery>;
export type GetStudentByEmailIdSuspenseQueryHookResult = ReturnType<typeof useGetStudentByEmailIdSuspenseQuery>;
export type GetStudentByEmailIdQueryResult = Apollo.QueryResult<GetStudentByEmailIdQuery, GetStudentByEmailIdQueryVariables>;
export const GetStaffDocument = gql`
    query GetStaff($id: String!) {
  getStaff(id: $id) {
    id
    name
    email
    campusId
  }
}
    `;

/**
 * __useGetStaffQuery__
 *
 * To run a query within a React component, call `useGetStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStaffQuery(baseOptions: Apollo.QueryHookOptions<GetStaffQuery, GetStaffQueryVariables> & ({ variables: GetStaffQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
      }
export function useGetStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
        }
export function useGetStaffSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
        }
export type GetStaffQueryHookResult = ReturnType<typeof useGetStaffQuery>;
export type GetStaffLazyQueryHookResult = ReturnType<typeof useGetStaffLazyQuery>;
export type GetStaffSuspenseQueryHookResult = ReturnType<typeof useGetStaffSuspenseQuery>;
export type GetStaffQueryResult = Apollo.QueryResult<GetStaffQuery, GetStaffQueryVariables>;
export const GetAllStudentsDocument = gql`
    query GetAllStudents {
  getAllStudents {
    id
    name
    email
    phno
    dob
    placeOfResidence
    assignmentIds
    courseIds
  }
}
    `;

/**
 * __useGetAllStudentsQuery__
 *
 * To run a query within a React component, call `useGetAllStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStudentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStudentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStudentsQuery, GetAllStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStudentsQuery, GetAllStudentsQueryVariables>(GetAllStudentsDocument, options);
      }
export function useGetAllStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStudentsQuery, GetAllStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStudentsQuery, GetAllStudentsQueryVariables>(GetAllStudentsDocument, options);
        }
export function useGetAllStudentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllStudentsQuery, GetAllStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllStudentsQuery, GetAllStudentsQueryVariables>(GetAllStudentsDocument, options);
        }
export type GetAllStudentsQueryHookResult = ReturnType<typeof useGetAllStudentsQuery>;
export type GetAllStudentsLazyQueryHookResult = ReturnType<typeof useGetAllStudentsLazyQuery>;
export type GetAllStudentsSuspenseQueryHookResult = ReturnType<typeof useGetAllStudentsSuspenseQuery>;
export type GetAllStudentsQueryResult = Apollo.QueryResult<GetAllStudentsQuery, GetAllStudentsQueryVariables>;
export const GetStudentDocument = gql`
    query GetStudent($id: String!) {
  getStudent(id: $id) {
    id
    name
    email
    phno
    dob
    rollNo
    campusId
    leetCodeUname
    hackerRankUname
    placeOfResidence
    assignmentIds
    courseIds
    clusterIds
    completedQuestions
  }
}
    `;

/**
 * __useGetStudentQuery__
 *
 * To run a query within a React component, call `useGetStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStudentQuery(baseOptions: Apollo.QueryHookOptions<GetStudentQuery, GetStudentQueryVariables> & ({ variables: GetStudentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, options);
      }
export function useGetStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, options);
        }
export function useGetStudentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentQuery, GetStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentQuery, GetStudentQueryVariables>(GetStudentDocument, options);
        }
export type GetStudentQueryHookResult = ReturnType<typeof useGetStudentQuery>;
export type GetStudentLazyQueryHookResult = ReturnType<typeof useGetStudentLazyQuery>;
export type GetStudentSuspenseQueryHookResult = ReturnType<typeof useGetStudentSuspenseQuery>;
export type GetStudentQueryResult = Apollo.QueryResult<GetStudentQuery, GetStudentQueryVariables>;
export const GetStudentsByCampusDocument = gql`
    query GetStudentsByCampus($campusId: String!, $page: Int, $limit: Int, $searchTerm: String, $clusterid: String) {
  getStudentsByCampus(
    campusId: $campusId
    page: $page
    limit: $limit
    searchTerm: $searchTerm
    clusterid: $clusterid
  ) {
    students {
      id
      name
      email
      rollNo
      assignmentIds
    }
    total
  }
}
    `;

/**
 * __useGetStudentsByCampusQuery__
 *
 * To run a query within a React component, call `useGetStudentsByCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsByCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsByCampusQuery({
 *   variables: {
 *      campusId: // value for 'campusId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchTerm: // value for 'searchTerm'
 *      clusterid: // value for 'clusterid'
 *   },
 * });
 */
export function useGetStudentsByCampusQuery(baseOptions: Apollo.QueryHookOptions<GetStudentsByCampusQuery, GetStudentsByCampusQueryVariables> & ({ variables: GetStudentsByCampusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsByCampusQuery, GetStudentsByCampusQueryVariables>(GetStudentsByCampusDocument, options);
      }
export function useGetStudentsByCampusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsByCampusQuery, GetStudentsByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsByCampusQuery, GetStudentsByCampusQueryVariables>(GetStudentsByCampusDocument, options);
        }
export function useGetStudentsByCampusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentsByCampusQuery, GetStudentsByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentsByCampusQuery, GetStudentsByCampusQueryVariables>(GetStudentsByCampusDocument, options);
        }
export type GetStudentsByCampusQueryHookResult = ReturnType<typeof useGetStudentsByCampusQuery>;
export type GetStudentsByCampusLazyQueryHookResult = ReturnType<typeof useGetStudentsByCampusLazyQuery>;
export type GetStudentsByCampusSuspenseQueryHookResult = ReturnType<typeof useGetStudentsByCampusSuspenseQuery>;
export type GetStudentsByCampusQueryResult = Apollo.QueryResult<GetStudentsByCampusQuery, GetStudentsByCampusQueryVariables>;
export const GetLeetcodeDataDocument = gql`
    query GetLeetcodeData($username: String!) {
  leetcodeData(username: $username) {
    allQuestionsCount {
      difficulty
      count
    }
    matchedUser {
      username
      githubUrl
      twitterUrl
      linkedinUrl
      contributions {
        points
        questionCount
        testcaseCount
      }
      profile {
        realName
        userAvatar
        birthday
        ranking
        reputation
        websites
        countryName
        company
        school
        skillTags
        aboutMe
        starRating
      }
      badges {
        id
        displayName
        icon
        creationDate
      }
      upcomingBadges {
        name
        icon
      }
      activeBadge {
        id
        displayName
        icon
        creationDate
      }
      submitStats {
        totalSubmissionNum {
          difficulty
          count
          submissions
        }
        acSubmissionNum {
          difficulty
          count
          submissions
        }
      }
      submissionCalendar
    }
    recentSubmissionList {
      title
      titleSlug
      timestamp
      statusDisplay
      lang
    }
  }
}
    `;

/**
 * __useGetLeetcodeDataQuery__
 *
 * To run a query within a React component, call `useGetLeetcodeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeetcodeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeetcodeDataQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetLeetcodeDataQuery(baseOptions: Apollo.QueryHookOptions<GetLeetcodeDataQuery, GetLeetcodeDataQueryVariables> & ({ variables: GetLeetcodeDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeetcodeDataQuery, GetLeetcodeDataQueryVariables>(GetLeetcodeDataDocument, options);
      }
export function useGetLeetcodeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeetcodeDataQuery, GetLeetcodeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeetcodeDataQuery, GetLeetcodeDataQueryVariables>(GetLeetcodeDataDocument, options);
        }
export function useGetLeetcodeDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLeetcodeDataQuery, GetLeetcodeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLeetcodeDataQuery, GetLeetcodeDataQueryVariables>(GetLeetcodeDataDocument, options);
        }
export type GetLeetcodeDataQueryHookResult = ReturnType<typeof useGetLeetcodeDataQuery>;
export type GetLeetcodeDataLazyQueryHookResult = ReturnType<typeof useGetLeetcodeDataLazyQuery>;
export type GetLeetcodeDataSuspenseQueryHookResult = ReturnType<typeof useGetLeetcodeDataSuspenseQuery>;
export type GetLeetcodeDataQueryResult = Apollo.QueryResult<GetLeetcodeDataQuery, GetLeetcodeDataQueryVariables>;
export const GetAllCoursesDocument = gql`
    query GetAllCourses {
  getAllCourses {
    id
    name
    short_description
    description
    categories {
      id
      name
      description
    }
    price
    time_period
    languages
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;

/**
 * __useGetAllCoursesQuery__
 *
 * To run a query within a React component, call `useGetAllCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoursesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCoursesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCoursesQuery, GetAllCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCoursesQuery, GetAllCoursesQueryVariables>(GetAllCoursesDocument, options);
      }
export function useGetAllCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCoursesQuery, GetAllCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCoursesQuery, GetAllCoursesQueryVariables>(GetAllCoursesDocument, options);
        }
export function useGetAllCoursesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllCoursesQuery, GetAllCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCoursesQuery, GetAllCoursesQueryVariables>(GetAllCoursesDocument, options);
        }
export type GetAllCoursesQueryHookResult = ReturnType<typeof useGetAllCoursesQuery>;
export type GetAllCoursesLazyQueryHookResult = ReturnType<typeof useGetAllCoursesLazyQuery>;
export type GetAllCoursesSuspenseQueryHookResult = ReturnType<typeof useGetAllCoursesSuspenseQuery>;
export type GetAllCoursesQueryResult = Apollo.QueryResult<GetAllCoursesQuery, GetAllCoursesQueryVariables>;
export const GetCourseDocument = gql`
    query GetCourse($id: String!) {
  getCourse(id: $id) {
    _id
    id
    name
    question_ids
    short_description
    description
    easy_count
    medium_count
    hard_count
    categories {
      id
      name
      description
    }
    price
    time_period
    languages
    domain
    level
  }
}
    `;

/**
 * __useGetCourseQuery__
 *
 * To run a query within a React component, call `useGetCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseQuery(baseOptions: Apollo.QueryHookOptions<GetCourseQuery, GetCourseQueryVariables> & ({ variables: GetCourseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options);
      }
export function useGetCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseQuery, GetCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options);
        }
export function useGetCourseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCourseQuery, GetCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options);
        }
export type GetCourseQueryHookResult = ReturnType<typeof useGetCourseQuery>;
export type GetCourseLazyQueryHookResult = ReturnType<typeof useGetCourseLazyQuery>;
export type GetCourseSuspenseQueryHookResult = ReturnType<typeof useGetCourseSuspenseQuery>;
export type GetCourseQueryResult = Apollo.QueryResult<GetCourseQuery, GetCourseQueryVariables>;
export const FindAllQuestionsDocument = gql`
    query findAllQuestions($page: Int, $limit: Int, $difficulties: [String], $searchName: String, $tags: [String]) {
  findAllQuestions(
    page: $page
    limit: $limit
    difficulties: $difficulties
    searchName: $searchName
    tags: $tags
  ) {
    id
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    marks
    difficulty
    hints
    tags
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    company_tags
  }
}
    `;

/**
 * __useFindAllQuestionsQuery__
 *
 * To run a query within a React component, call `useFindAllQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllQuestionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      difficulties: // value for 'difficulties'
 *      searchName: // value for 'searchName'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useFindAllQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllQuestionsQuery, FindAllQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllQuestionsQuery, FindAllQuestionsQueryVariables>(FindAllQuestionsDocument, options);
      }
export function useFindAllQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllQuestionsQuery, FindAllQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllQuestionsQuery, FindAllQuestionsQueryVariables>(FindAllQuestionsDocument, options);
        }
export function useFindAllQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindAllQuestionsQuery, FindAllQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAllQuestionsQuery, FindAllQuestionsQueryVariables>(FindAllQuestionsDocument, options);
        }
export type FindAllQuestionsQueryHookResult = ReturnType<typeof useFindAllQuestionsQuery>;
export type FindAllQuestionsLazyQueryHookResult = ReturnType<typeof useFindAllQuestionsLazyQuery>;
export type FindAllQuestionsSuspenseQueryHookResult = ReturnType<typeof useFindAllQuestionsSuspenseQuery>;
export type FindAllQuestionsQueryResult = Apollo.QueryResult<FindAllQuestionsQuery, FindAllQuestionsQueryVariables>;
export const GetAllQuestionDocument = gql`
    query getAllQuestion($courseId: String!, $page: Int, $limit: Int, $categoryId: String!) {
  getAllQuestion(
    courseId: $courseId
    page: $page
    limit: $limit
    categoryId: $categoryId
  ) {
    questions {
      id
      course_id
      assignment_ids
      title
      text
      question_sub_text
      test_cases {
        id
        visibility
        input
        output
        weightage
      }
      category {
        name
        id
      }
      marks
      difficulty
      hints
      tags
      company_tags
    }
    totalCount
  }
}
    `;

/**
 * __useGetAllQuestionQuery__
 *
 * To run a query within a React component, call `useGetAllQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuestionQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetAllQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetAllQuestionQuery, GetAllQuestionQueryVariables> & ({ variables: GetAllQuestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllQuestionQuery, GetAllQuestionQueryVariables>(GetAllQuestionDocument, options);
      }
export function useGetAllQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuestionQuery, GetAllQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllQuestionQuery, GetAllQuestionQueryVariables>(GetAllQuestionDocument, options);
        }
export function useGetAllQuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllQuestionQuery, GetAllQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllQuestionQuery, GetAllQuestionQueryVariables>(GetAllQuestionDocument, options);
        }
export type GetAllQuestionQueryHookResult = ReturnType<typeof useGetAllQuestionQuery>;
export type GetAllQuestionLazyQueryHookResult = ReturnType<typeof useGetAllQuestionLazyQuery>;
export type GetAllQuestionSuspenseQueryHookResult = ReturnType<typeof useGetAllQuestionSuspenseQuery>;
export type GetAllQuestionQueryResult = Apollo.QueryResult<GetAllQuestionQuery, GetAllQuestionQueryVariables>;
export const GetQuestionDocument = gql`
    query getQuestion($id: String!) {
  getQuestion(id: $id) {
    id
    course_id
    assignment_ids
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    category {
      name
      id
    }
    marks
    difficulty
    hints
    tags
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;

/**
 * __useGetQuestionQuery__
 *
 * To run a query within a React component, call `useGetQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables> & ({ variables: GetQuestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, options);
      }
export function useGetQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, options);
        }
export function useGetQuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQuestionQuery, GetQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuestionQuery, GetQuestionQueryVariables>(GetQuestionDocument, options);
        }
export type GetQuestionQueryHookResult = ReturnType<typeof useGetQuestionQuery>;
export type GetQuestionLazyQueryHookResult = ReturnType<typeof useGetQuestionLazyQuery>;
export type GetQuestionSuspenseQueryHookResult = ReturnType<typeof useGetQuestionSuspenseQuery>;
export type GetQuestionQueryResult = Apollo.QueryResult<GetQuestionQuery, GetQuestionQueryVariables>;
export const GetQuestionsByTagsDocument = gql`
    query getQuestionsByTags($tags: [String!]!, $page: Int!, $limit: Int!) {
  getQuestionsByTags(tags: $tags, page: $page, limit: $limit) {
    id
    course_id
    assignment_ids
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    category {
      name
      id
    }
    marks
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;

/**
 * __useGetQuestionsByTagsQuery__
 *
 * To run a query within a React component, call `useGetQuestionsByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsByTagsQuery({
 *   variables: {
 *      tags: // value for 'tags'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetQuestionsByTagsQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionsByTagsQuery, GetQuestionsByTagsQueryVariables> & ({ variables: GetQuestionsByTagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsByTagsQuery, GetQuestionsByTagsQueryVariables>(GetQuestionsByTagsDocument, options);
      }
export function useGetQuestionsByTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsByTagsQuery, GetQuestionsByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsByTagsQuery, GetQuestionsByTagsQueryVariables>(GetQuestionsByTagsDocument, options);
        }
export function useGetQuestionsByTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQuestionsByTagsQuery, GetQuestionsByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuestionsByTagsQuery, GetQuestionsByTagsQueryVariables>(GetQuestionsByTagsDocument, options);
        }
export type GetQuestionsByTagsQueryHookResult = ReturnType<typeof useGetQuestionsByTagsQuery>;
export type GetQuestionsByTagsLazyQueryHookResult = ReturnType<typeof useGetQuestionsByTagsLazyQuery>;
export type GetQuestionsByTagsSuspenseQueryHookResult = ReturnType<typeof useGetQuestionsByTagsSuspenseQuery>;
export type GetQuestionsByTagsQueryResult = Apollo.QueryResult<GetQuestionsByTagsQuery, GetQuestionsByTagsQueryVariables>;
export const GetCoursesByStudentEmailDocument = gql`
    query getCoursesByStudentEmail($email: String!) {
  getCoursesByStudentEmail(email: $email) {
    _id
    id
    name
    short_description
    description
    categories {
      id
      name
      description
    }
    price
    time_period
    languages
    domain
  }
}
    `;

/**
 * __useGetCoursesByStudentEmailQuery__
 *
 * To run a query within a React component, call `useGetCoursesByStudentEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoursesByStudentEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoursesByStudentEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCoursesByStudentEmailQuery(baseOptions: Apollo.QueryHookOptions<GetCoursesByStudentEmailQuery, GetCoursesByStudentEmailQueryVariables> & ({ variables: GetCoursesByStudentEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoursesByStudentEmailQuery, GetCoursesByStudentEmailQueryVariables>(GetCoursesByStudentEmailDocument, options);
      }
export function useGetCoursesByStudentEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoursesByStudentEmailQuery, GetCoursesByStudentEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoursesByStudentEmailQuery, GetCoursesByStudentEmailQueryVariables>(GetCoursesByStudentEmailDocument, options);
        }
export function useGetCoursesByStudentEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCoursesByStudentEmailQuery, GetCoursesByStudentEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCoursesByStudentEmailQuery, GetCoursesByStudentEmailQueryVariables>(GetCoursesByStudentEmailDocument, options);
        }
export type GetCoursesByStudentEmailQueryHookResult = ReturnType<typeof useGetCoursesByStudentEmailQuery>;
export type GetCoursesByStudentEmailLazyQueryHookResult = ReturnType<typeof useGetCoursesByStudentEmailLazyQuery>;
export type GetCoursesByStudentEmailSuspenseQueryHookResult = ReturnType<typeof useGetCoursesByStudentEmailSuspenseQuery>;
export type GetCoursesByStudentEmailQueryResult = Apollo.QueryResult<GetCoursesByStudentEmailQuery, GetCoursesByStudentEmailQueryVariables>;
export const GetAllCoursesByClusterDocument = gql`
    query getAllCoursesByCluster($campusName: String!, $clusterId: String!) {
  getAllCoursesByCluster(campusName: $campusName, clusterId: $clusterId) {
    _id
    id
    name
    short_description
    description
    categories {
      id
      name
      description
    }
    price
    time_period
    languages
    domain
  }
}
    `;

/**
 * __useGetAllCoursesByClusterQuery__
 *
 * To run a query within a React component, call `useGetAllCoursesByClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoursesByClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoursesByClusterQuery({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useGetAllCoursesByClusterQuery(baseOptions: Apollo.QueryHookOptions<GetAllCoursesByClusterQuery, GetAllCoursesByClusterQueryVariables> & ({ variables: GetAllCoursesByClusterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCoursesByClusterQuery, GetAllCoursesByClusterQueryVariables>(GetAllCoursesByClusterDocument, options);
      }
export function useGetAllCoursesByClusterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCoursesByClusterQuery, GetAllCoursesByClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCoursesByClusterQuery, GetAllCoursesByClusterQueryVariables>(GetAllCoursesByClusterDocument, options);
        }
export function useGetAllCoursesByClusterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllCoursesByClusterQuery, GetAllCoursesByClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCoursesByClusterQuery, GetAllCoursesByClusterQueryVariables>(GetAllCoursesByClusterDocument, options);
        }
export type GetAllCoursesByClusterQueryHookResult = ReturnType<typeof useGetAllCoursesByClusterQuery>;
export type GetAllCoursesByClusterLazyQueryHookResult = ReturnType<typeof useGetAllCoursesByClusterLazyQuery>;
export type GetAllCoursesByClusterSuspenseQueryHookResult = ReturnType<typeof useGetAllCoursesByClusterSuspenseQuery>;
export type GetAllCoursesByClusterQueryResult = Apollo.QueryResult<GetAllCoursesByClusterQuery, GetAllCoursesByClusterQueryVariables>;
export const GetClusterDocument = gql`
    query getCluster($campusName: String!, $clusterId: String!) {
  getCluster(campusName: $campusName, clusterId: $clusterId) {
    id
    name
    courseIds
    studentCount
    createdAt
    staffs {
      id
      name
      email
    }
    sendEmail
  }
}
    `;

/**
 * __useGetClusterQuery__
 *
 * To run a query within a React component, call `useGetClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClusterQuery({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useGetClusterQuery(baseOptions: Apollo.QueryHookOptions<GetClusterQuery, GetClusterQueryVariables> & ({ variables: GetClusterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
      }
export function useGetClusterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClusterQuery, GetClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
        }
export function useGetClusterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClusterQuery, GetClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClusterQuery, GetClusterQueryVariables>(GetClusterDocument, options);
        }
export type GetClusterQueryHookResult = ReturnType<typeof useGetClusterQuery>;
export type GetClusterLazyQueryHookResult = ReturnType<typeof useGetClusterLazyQuery>;
export type GetClusterSuspenseQueryHookResult = ReturnType<typeof useGetClusterSuspenseQuery>;
export type GetClusterQueryResult = Apollo.QueryResult<GetClusterQuery, GetClusterQueryVariables>;
export const CompileCodeDocument = gql`
    query compileCode($compileCodeInput: CompilerInput!) {
  compileCode(compileCodeInput: $compileCodeInput) {
    compilationOutput {
      stdout
      stderr
    }
    testCasePassed {
      count
      testCaseIds {
        testCaseId
        stdout
      }
    }
    testCaseFailed {
      count
      testCaseIds {
        testCaseId
        stdout
      }
    }
    Results {
      input
      output
      expextedOutput
      error
    }
  }
}
    `;

/**
 * __useCompileCodeQuery__
 *
 * To run a query within a React component, call `useCompileCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompileCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompileCodeQuery({
 *   variables: {
 *      compileCodeInput: // value for 'compileCodeInput'
 *   },
 * });
 */
export function useCompileCodeQuery(baseOptions: Apollo.QueryHookOptions<CompileCodeQuery, CompileCodeQueryVariables> & ({ variables: CompileCodeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompileCodeQuery, CompileCodeQueryVariables>(CompileCodeDocument, options);
      }
export function useCompileCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompileCodeQuery, CompileCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompileCodeQuery, CompileCodeQueryVariables>(CompileCodeDocument, options);
        }
export function useCompileCodeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CompileCodeQuery, CompileCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CompileCodeQuery, CompileCodeQueryVariables>(CompileCodeDocument, options);
        }
export type CompileCodeQueryHookResult = ReturnType<typeof useCompileCodeQuery>;
export type CompileCodeLazyQueryHookResult = ReturnType<typeof useCompileCodeLazyQuery>;
export type CompileCodeSuspenseQueryHookResult = ReturnType<typeof useCompileCodeSuspenseQuery>;
export type CompileCodeQueryResult = Apollo.QueryResult<CompileCodeQuery, CompileCodeQueryVariables>;
export const GetCampusByNameDocument = gql`
    query getCampusByName($name: String!) {
  getCampusByName(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useGetCampusByNameQuery__
 *
 * To run a query within a React component, call `useGetCampusByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampusByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampusByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetCampusByNameQuery(baseOptions: Apollo.QueryHookOptions<GetCampusByNameQuery, GetCampusByNameQueryVariables> & ({ variables: GetCampusByNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampusByNameQuery, GetCampusByNameQueryVariables>(GetCampusByNameDocument, options);
      }
export function useGetCampusByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampusByNameQuery, GetCampusByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampusByNameQuery, GetCampusByNameQueryVariables>(GetCampusByNameDocument, options);
        }
export function useGetCampusByNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCampusByNameQuery, GetCampusByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampusByNameQuery, GetCampusByNameQueryVariables>(GetCampusByNameDocument, options);
        }
export type GetCampusByNameQueryHookResult = ReturnType<typeof useGetCampusByNameQuery>;
export type GetCampusByNameLazyQueryHookResult = ReturnType<typeof useGetCampusByNameLazyQuery>;
export type GetCampusByNameSuspenseQueryHookResult = ReturnType<typeof useGetCampusByNameSuspenseQuery>;
export type GetCampusByNameQueryResult = Apollo.QueryResult<GetCampusByNameQuery, GetCampusByNameQueryVariables>;
export const GetClustersByIdsAndCampusDocument = gql`
    query GetClustersByIdsAndCampus($clusterIds: [String!]!, $campusName: String!) {
  getClustersByIdsAndCampus(clusterIds: $clusterIds, campusName: $campusName) {
    id
    name
    courseIds
    createdAt
    studentCount
  }
}
    `;

/**
 * __useGetClustersByIdsAndCampusQuery__
 *
 * To run a query within a React component, call `useGetClustersByIdsAndCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClustersByIdsAndCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClustersByIdsAndCampusQuery({
 *   variables: {
 *      clusterIds: // value for 'clusterIds'
 *      campusName: // value for 'campusName'
 *   },
 * });
 */
export function useGetClustersByIdsAndCampusQuery(baseOptions: Apollo.QueryHookOptions<GetClustersByIdsAndCampusQuery, GetClustersByIdsAndCampusQueryVariables> & ({ variables: GetClustersByIdsAndCampusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClustersByIdsAndCampusQuery, GetClustersByIdsAndCampusQueryVariables>(GetClustersByIdsAndCampusDocument, options);
      }
export function useGetClustersByIdsAndCampusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClustersByIdsAndCampusQuery, GetClustersByIdsAndCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClustersByIdsAndCampusQuery, GetClustersByIdsAndCampusQueryVariables>(GetClustersByIdsAndCampusDocument, options);
        }
export function useGetClustersByIdsAndCampusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetClustersByIdsAndCampusQuery, GetClustersByIdsAndCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClustersByIdsAndCampusQuery, GetClustersByIdsAndCampusQueryVariables>(GetClustersByIdsAndCampusDocument, options);
        }
export type GetClustersByIdsAndCampusQueryHookResult = ReturnType<typeof useGetClustersByIdsAndCampusQuery>;
export type GetClustersByIdsAndCampusLazyQueryHookResult = ReturnType<typeof useGetClustersByIdsAndCampusLazyQuery>;
export type GetClustersByIdsAndCampusSuspenseQueryHookResult = ReturnType<typeof useGetClustersByIdsAndCampusSuspenseQuery>;
export type GetClustersByIdsAndCampusQueryResult = Apollo.QueryResult<GetClustersByIdsAndCampusQuery, GetClustersByIdsAndCampusQueryVariables>;
export const GetLeaderboardDocument = gql`
    query getLeaderboard($campusName: String!, $clusterId: String, $filter: String, $limit: Int, $startDate: String, $endDate: String) {
  getLeaderboard(
    campusName: $campusName
    clusterId: $clusterId
    filter: $filter
    limit: $limit
    startDate: $startDate
    endDate: $endDate
  ) {
    student_id
    totalScore
    student {
      id
      name
      email
      phno
      dob
      completedQuestions
    }
    completedQuestions
    latestSubmissionDate
    position
    leetcode_data {
      difficulty
      count
      submissions
    }
    easyQuestionsCompleted
    hardQuestionCompleted
    mediumQuestionsCompleted
  }
}
    `;

/**
 * __useGetLeaderboardQuery__
 *
 * To run a query within a React component, call `useGetLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaderboardQuery({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables> & ({ variables: GetLeaderboardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
      }
export function useGetLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
        }
export function useGetLeaderboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
        }
export type GetLeaderboardQueryHookResult = ReturnType<typeof useGetLeaderboardQuery>;
export type GetLeaderboardLazyQueryHookResult = ReturnType<typeof useGetLeaderboardLazyQuery>;
export type GetLeaderboardSuspenseQueryHookResult = ReturnType<typeof useGetLeaderboardSuspenseQuery>;
export type GetLeaderboardQueryResult = Apollo.QueryResult<GetLeaderboardQuery, GetLeaderboardQueryVariables>;
export const GetQuestionCompletedDocument = gql`
    query GetQuestionCompleted($student_id: String!, $is_paid: Boolean!) {
  getQuestionCompleted(student_id: $student_id, is_paid: $is_paid) {
    easy {
      completed
      total
    }
    medium {
      completed
      total
    }
    hard {
      completed
      total
    }
    overall {
      completed
      total
    }
  }
}
    `;

/**
 * __useGetQuestionCompletedQuery__
 *
 * To run a query within a React component, call `useGetQuestionCompletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionCompletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionCompletedQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      is_paid: // value for 'is_paid'
 *   },
 * });
 */
export function useGetQuestionCompletedQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionCompletedQuery, GetQuestionCompletedQueryVariables> & ({ variables: GetQuestionCompletedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionCompletedQuery, GetQuestionCompletedQueryVariables>(GetQuestionCompletedDocument, options);
      }
export function useGetQuestionCompletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionCompletedQuery, GetQuestionCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionCompletedQuery, GetQuestionCompletedQueryVariables>(GetQuestionCompletedDocument, options);
        }
export function useGetQuestionCompletedSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQuestionCompletedQuery, GetQuestionCompletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuestionCompletedQuery, GetQuestionCompletedQueryVariables>(GetQuestionCompletedDocument, options);
        }
export type GetQuestionCompletedQueryHookResult = ReturnType<typeof useGetQuestionCompletedQuery>;
export type GetQuestionCompletedLazyQueryHookResult = ReturnType<typeof useGetQuestionCompletedLazyQuery>;
export type GetQuestionCompletedSuspenseQueryHookResult = ReturnType<typeof useGetQuestionCompletedSuspenseQuery>;
export type GetQuestionCompletedQueryResult = Apollo.QueryResult<GetQuestionCompletedQuery, GetQuestionCompletedQueryVariables>;
export const GetDashboardCourseDetailsDocument = gql`
    query GetDashboardCourseDetails($student_id: String!, $is_paid: Boolean!) {
  getDashboardCourseDetails(student_id: $student_id, is_paid: $is_paid) {
    __typename
    courseDetails {
      courseId
      courseName
      level
      completedQuestion
      totalQuestion
      percentageCompleted
    }
    rewardPoints
  }
}
    `;

/**
 * __useGetDashboardCourseDetailsQuery__
 *
 * To run a query within a React component, call `useGetDashboardCourseDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardCourseDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardCourseDetailsQuery({
 *   variables: {
 *      student_id: // value for 'student_id'
 *      is_paid: // value for 'is_paid'
 *   },
 * });
 */
export function useGetDashboardCourseDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardCourseDetailsQuery, GetDashboardCourseDetailsQueryVariables> & ({ variables: GetDashboardCourseDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardCourseDetailsQuery, GetDashboardCourseDetailsQueryVariables>(GetDashboardCourseDetailsDocument, options);
      }
export function useGetDashboardCourseDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardCourseDetailsQuery, GetDashboardCourseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardCourseDetailsQuery, GetDashboardCourseDetailsQueryVariables>(GetDashboardCourseDetailsDocument, options);
        }
export function useGetDashboardCourseDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDashboardCourseDetailsQuery, GetDashboardCourseDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardCourseDetailsQuery, GetDashboardCourseDetailsQueryVariables>(GetDashboardCourseDetailsDocument, options);
        }
export type GetDashboardCourseDetailsQueryHookResult = ReturnType<typeof useGetDashboardCourseDetailsQuery>;
export type GetDashboardCourseDetailsLazyQueryHookResult = ReturnType<typeof useGetDashboardCourseDetailsLazyQuery>;
export type GetDashboardCourseDetailsSuspenseQueryHookResult = ReturnType<typeof useGetDashboardCourseDetailsSuspenseQuery>;
export type GetDashboardCourseDetailsQueryResult = Apollo.QueryResult<GetDashboardCourseDetailsQuery, GetDashboardCourseDetailsQueryVariables>;
export const GetLastFiveSubmissionsDocument = gql`
    query GetLastFiveSubmissions($submitInput: SubmitInput!) {
  getLastFiveSubmissions(submitInput: $submitInput) {
    id
    student_id
    course_id
    cluster_id
    question_id
    max_score
    is_completed
    autosave {
      id
      code
      score
      submittedAt
      language
    }
    submissions {
      id
      code
      score
      submittedAt
      language
    }
  }
}
    `;

/**
 * __useGetLastFiveSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetLastFiveSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastFiveSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastFiveSubmissionsQuery({
 *   variables: {
 *      submitInput: // value for 'submitInput'
 *   },
 * });
 */
export function useGetLastFiveSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<GetLastFiveSubmissionsQuery, GetLastFiveSubmissionsQueryVariables> & ({ variables: GetLastFiveSubmissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastFiveSubmissionsQuery, GetLastFiveSubmissionsQueryVariables>(GetLastFiveSubmissionsDocument, options);
      }
export function useGetLastFiveSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastFiveSubmissionsQuery, GetLastFiveSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastFiveSubmissionsQuery, GetLastFiveSubmissionsQueryVariables>(GetLastFiveSubmissionsDocument, options);
        }
export function useGetLastFiveSubmissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLastFiveSubmissionsQuery, GetLastFiveSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLastFiveSubmissionsQuery, GetLastFiveSubmissionsQueryVariables>(GetLastFiveSubmissionsDocument, options);
        }
export type GetLastFiveSubmissionsQueryHookResult = ReturnType<typeof useGetLastFiveSubmissionsQuery>;
export type GetLastFiveSubmissionsLazyQueryHookResult = ReturnType<typeof useGetLastFiveSubmissionsLazyQuery>;
export type GetLastFiveSubmissionsSuspenseQueryHookResult = ReturnType<typeof useGetLastFiveSubmissionsSuspenseQuery>;
export type GetLastFiveSubmissionsQueryResult = Apollo.QueryResult<GetLastFiveSubmissionsQuery, GetLastFiveSubmissionsQueryVariables>;
export const GetAllAssignmentsDocument = gql`
    query GetAllAssignments {
  getAllAssignments {
    _id
    id
    name
    short_description
    description
    due_date
    duration
    languages
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;

/**
 * __useGetAllAssignmentsQuery__
 *
 * To run a query within a React component, call `useGetAllAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAssignmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables>(GetAllAssignmentsDocument, options);
      }
export function useGetAllAssignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables>(GetAllAssignmentsDocument, options);
        }
export function useGetAllAssignmentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables>(GetAllAssignmentsDocument, options);
        }
export type GetAllAssignmentsQueryHookResult = ReturnType<typeof useGetAllAssignmentsQuery>;
export type GetAllAssignmentsLazyQueryHookResult = ReturnType<typeof useGetAllAssignmentsLazyQuery>;
export type GetAllAssignmentsSuspenseQueryHookResult = ReturnType<typeof useGetAllAssignmentsSuspenseQuery>;
export type GetAllAssignmentsQueryResult = Apollo.QueryResult<GetAllAssignmentsQuery, GetAllAssignmentsQueryVariables>;
export const GetAssignmentDocument = gql`
    query GetAssignment($id: String!) {
  getAssignment(id: $id) {
    _id
    id
    name
    short_description
    description
    due_date
    duration
    languages
    max_cheat_count
    max_tabSwitches
    enable_proctoring
    domain
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables> & ({ variables: GetAssignmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
      }
export function useGetAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
        }
export function useGetAssignmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, options);
        }
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentSuspenseQuery>;
export type GetAssignmentQueryResult = Apollo.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const GetAllAssignmentQuestionDocument = gql`
    query getAllAssignmentQuestion($assignmentId: String!, $page: Int = 1, $limit: Int = 10) {
  getAllAssignmentQuestion(
    assignmentId: $assignmentId
    page: $page
    limit: $limit
  ) {
    id
    assignment_id
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    marks
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;

/**
 * __useGetAllAssignmentQuestionQuery__
 *
 * To run a query within a React component, call `useGetAllAssignmentQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssignmentQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssignmentQuestionQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllAssignmentQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetAllAssignmentQuestionQuery, GetAllAssignmentQuestionQueryVariables> & ({ variables: GetAllAssignmentQuestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAssignmentQuestionQuery, GetAllAssignmentQuestionQueryVariables>(GetAllAssignmentQuestionDocument, options);
      }
export function useGetAllAssignmentQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssignmentQuestionQuery, GetAllAssignmentQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAssignmentQuestionQuery, GetAllAssignmentQuestionQueryVariables>(GetAllAssignmentQuestionDocument, options);
        }
export function useGetAllAssignmentQuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllAssignmentQuestionQuery, GetAllAssignmentQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAssignmentQuestionQuery, GetAllAssignmentQuestionQueryVariables>(GetAllAssignmentQuestionDocument, options);
        }
export type GetAllAssignmentQuestionQueryHookResult = ReturnType<typeof useGetAllAssignmentQuestionQuery>;
export type GetAllAssignmentQuestionLazyQueryHookResult = ReturnType<typeof useGetAllAssignmentQuestionLazyQuery>;
export type GetAllAssignmentQuestionSuspenseQueryHookResult = ReturnType<typeof useGetAllAssignmentQuestionSuspenseQuery>;
export type GetAllAssignmentQuestionQueryResult = Apollo.QueryResult<GetAllAssignmentQuestionQuery, GetAllAssignmentQuestionQueryVariables>;
export const GetAssignmentQuestionDocument = gql`
    query getAssignmentQuestion($id: String!) {
  getAssignmentQuestion(id: $id) {
    id
    assignment_id
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    marks
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;

/**
 * __useGetAssignmentQuestionQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssignmentQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentQuestionQuery, GetAssignmentQuestionQueryVariables> & ({ variables: GetAssignmentQuestionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentQuestionQuery, GetAssignmentQuestionQueryVariables>(GetAssignmentQuestionDocument, options);
      }
export function useGetAssignmentQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentQuestionQuery, GetAssignmentQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentQuestionQuery, GetAssignmentQuestionQueryVariables>(GetAssignmentQuestionDocument, options);
        }
export function useGetAssignmentQuestionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentQuestionQuery, GetAssignmentQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentQuestionQuery, GetAssignmentQuestionQueryVariables>(GetAssignmentQuestionDocument, options);
        }
export type GetAssignmentQuestionQueryHookResult = ReturnType<typeof useGetAssignmentQuestionQuery>;
export type GetAssignmentQuestionLazyQueryHookResult = ReturnType<typeof useGetAssignmentQuestionLazyQuery>;
export type GetAssignmentQuestionSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentQuestionSuspenseQuery>;
export type GetAssignmentQuestionQueryResult = Apollo.QueryResult<GetAssignmentQuestionQuery, GetAssignmentQuestionQueryVariables>;
export const GetAssignmentQuestionsByTagsDocument = gql`
    query getAssignmentQuestionsByTags($tags: [String!]!, $page: Int!, $limit: Int!) {
  getAssignmentQuestionsByTags(tags: $tags, page: $page, limit: $limit) {
    id
    assignment_id
    title
    text
    question_sub_text
    test_cases {
      id
      visibility
      input
      output
      weightage
    }
    marks
    predefinedCode {
      c
      java
      python
      cpp
    }
    showFunctionOnly
    targetFunctionName
    difficulty
    hints
    tags
    company_tags
    updated_by
    updated_at
    created_by
    created_at
  }
}
    `;

/**
 * __useGetAssignmentQuestionsByTagsQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuestionsByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuestionsByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuestionsByTagsQuery({
 *   variables: {
 *      tags: // value for 'tags'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAssignmentQuestionsByTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentQuestionsByTagsQuery, GetAssignmentQuestionsByTagsQueryVariables> & ({ variables: GetAssignmentQuestionsByTagsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentQuestionsByTagsQuery, GetAssignmentQuestionsByTagsQueryVariables>(GetAssignmentQuestionsByTagsDocument, options);
      }
export function useGetAssignmentQuestionsByTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentQuestionsByTagsQuery, GetAssignmentQuestionsByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentQuestionsByTagsQuery, GetAssignmentQuestionsByTagsQueryVariables>(GetAssignmentQuestionsByTagsDocument, options);
        }
export function useGetAssignmentQuestionsByTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentQuestionsByTagsQuery, GetAssignmentQuestionsByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentQuestionsByTagsQuery, GetAssignmentQuestionsByTagsQueryVariables>(GetAssignmentQuestionsByTagsDocument, options);
        }
export type GetAssignmentQuestionsByTagsQueryHookResult = ReturnType<typeof useGetAssignmentQuestionsByTagsQuery>;
export type GetAssignmentQuestionsByTagsLazyQueryHookResult = ReturnType<typeof useGetAssignmentQuestionsByTagsLazyQuery>;
export type GetAssignmentQuestionsByTagsSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentQuestionsByTagsSuspenseQuery>;
export type GetAssignmentQuestionsByTagsQueryResult = Apollo.QueryResult<GetAssignmentQuestionsByTagsQuery, GetAssignmentQuestionsByTagsQueryVariables>;
export const GetStudentAndCampusDetailsByAssignmentIdDocument = gql`
    query GetStudentAndCampusDetailsByAssignmentId($assignmentId: String!) {
  getStudentAndCampusDetailsByAssignmentId(assignmentId: $assignmentId) {
    student {
      id
      name
      email
      campusId
      rollNo
      assignmentIds
    }
    campus {
      id
      name
    }
  }
}
    `;

/**
 * __useGetStudentAndCampusDetailsByAssignmentIdQuery__
 *
 * To run a query within a React component, call `useGetStudentAndCampusDetailsByAssignmentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentAndCampusDetailsByAssignmentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentAndCampusDetailsByAssignmentIdQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useGetStudentAndCampusDetailsByAssignmentIdQuery(baseOptions: Apollo.QueryHookOptions<GetStudentAndCampusDetailsByAssignmentIdQuery, GetStudentAndCampusDetailsByAssignmentIdQueryVariables> & ({ variables: GetStudentAndCampusDetailsByAssignmentIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentAndCampusDetailsByAssignmentIdQuery, GetStudentAndCampusDetailsByAssignmentIdQueryVariables>(GetStudentAndCampusDetailsByAssignmentIdDocument, options);
      }
export function useGetStudentAndCampusDetailsByAssignmentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentAndCampusDetailsByAssignmentIdQuery, GetStudentAndCampusDetailsByAssignmentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentAndCampusDetailsByAssignmentIdQuery, GetStudentAndCampusDetailsByAssignmentIdQueryVariables>(GetStudentAndCampusDetailsByAssignmentIdDocument, options);
        }
export function useGetStudentAndCampusDetailsByAssignmentIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentAndCampusDetailsByAssignmentIdQuery, GetStudentAndCampusDetailsByAssignmentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentAndCampusDetailsByAssignmentIdQuery, GetStudentAndCampusDetailsByAssignmentIdQueryVariables>(GetStudentAndCampusDetailsByAssignmentIdDocument, options);
        }
export type GetStudentAndCampusDetailsByAssignmentIdQueryHookResult = ReturnType<typeof useGetStudentAndCampusDetailsByAssignmentIdQuery>;
export type GetStudentAndCampusDetailsByAssignmentIdLazyQueryHookResult = ReturnType<typeof useGetStudentAndCampusDetailsByAssignmentIdLazyQuery>;
export type GetStudentAndCampusDetailsByAssignmentIdSuspenseQueryHookResult = ReturnType<typeof useGetStudentAndCampusDetailsByAssignmentIdSuspenseQuery>;
export type GetStudentAndCampusDetailsByAssignmentIdQueryResult = Apollo.QueryResult<GetStudentAndCampusDetailsByAssignmentIdQuery, GetStudentAndCampusDetailsByAssignmentIdQueryVariables>;
export const GetAssignmentsForStudentDocument = gql`
    query GetAssignmentsForStudent($email: String!) {
  getAssignmentsForStudent(email: $email) {
    id
    name
    short_description
    description
    due_date
  }
}
    `;

/**
 * __useGetAssignmentsForStudentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentsForStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentsForStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentsForStudentQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAssignmentsForStudentQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentsForStudentQuery, GetAssignmentsForStudentQueryVariables> & ({ variables: GetAssignmentsForStudentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentsForStudentQuery, GetAssignmentsForStudentQueryVariables>(GetAssignmentsForStudentDocument, options);
      }
export function useGetAssignmentsForStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentsForStudentQuery, GetAssignmentsForStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentsForStudentQuery, GetAssignmentsForStudentQueryVariables>(GetAssignmentsForStudentDocument, options);
        }
export function useGetAssignmentsForStudentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentsForStudentQuery, GetAssignmentsForStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentsForStudentQuery, GetAssignmentsForStudentQueryVariables>(GetAssignmentsForStudentDocument, options);
        }
export type GetAssignmentsForStudentQueryHookResult = ReturnType<typeof useGetAssignmentsForStudentQuery>;
export type GetAssignmentsForStudentLazyQueryHookResult = ReturnType<typeof useGetAssignmentsForStudentLazyQuery>;
export type GetAssignmentsForStudentSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentsForStudentSuspenseQuery>;
export type GetAssignmentsForStudentQueryResult = Apollo.QueryResult<GetAssignmentsForStudentQuery, GetAssignmentsForStudentQueryVariables>;
export const GetAssignmentSubmitDocument = gql`
    query GetAssignmentSubmit($assignmentId: String!, $studentId: String!, $campus_name: String!) {
  getAssignmentSubmit(
    assignmentId: $assignmentId
    studentId: $studentId
    campus_name: $campus_name
  ) {
    id
    assignment_id
    cluster_id
    student_id
    questions {
      question_id
      answer
      score
      language
    }
    autosave {
      question_id
      answer
      score
      language
    }
    totalScore
    tabSwitches
    cheat_count
    completed
  }
}
    `;

/**
 * __useGetAssignmentSubmitQuery__
 *
 * To run a query within a React component, call `useGetAssignmentSubmitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentSubmitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentSubmitQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      studentId: // value for 'studentId'
 *      campus_name: // value for 'campus_name'
 *   },
 * });
 */
export function useGetAssignmentSubmitQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentSubmitQuery, GetAssignmentSubmitQueryVariables> & ({ variables: GetAssignmentSubmitQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentSubmitQuery, GetAssignmentSubmitQueryVariables>(GetAssignmentSubmitDocument, options);
      }
export function useGetAssignmentSubmitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentSubmitQuery, GetAssignmentSubmitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentSubmitQuery, GetAssignmentSubmitQueryVariables>(GetAssignmentSubmitDocument, options);
        }
export function useGetAssignmentSubmitSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentSubmitQuery, GetAssignmentSubmitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentSubmitQuery, GetAssignmentSubmitQueryVariables>(GetAssignmentSubmitDocument, options);
        }
export type GetAssignmentSubmitQueryHookResult = ReturnType<typeof useGetAssignmentSubmitQuery>;
export type GetAssignmentSubmitLazyQueryHookResult = ReturnType<typeof useGetAssignmentSubmitLazyQuery>;
export type GetAssignmentSubmitSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentSubmitSuspenseQuery>;
export type GetAssignmentSubmitQueryResult = Apollo.QueryResult<GetAssignmentSubmitQuery, GetAssignmentSubmitQueryVariables>;
export const GetStudentSubmissionsForMonthDocument = gql`
    query GetStudentSubmissionsForMonth($studentId: String!, $campusName: String!, $month: Int!, $year: Int!) {
  getStudentSubmissionsForMonth(
    studentId: $studentId
    campusName: $campusName
    month: $month
    year: $year
  ) {
    date
    courseName
    submissions {
      submittedAt
    }
  }
}
    `;

/**
 * __useGetStudentSubmissionsForMonthQuery__
 *
 * To run a query within a React component, call `useGetStudentSubmissionsForMonthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSubmissionsForMonthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSubmissionsForMonthQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      campusName: // value for 'campusName'
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetStudentSubmissionsForMonthQuery(baseOptions: Apollo.QueryHookOptions<GetStudentSubmissionsForMonthQuery, GetStudentSubmissionsForMonthQueryVariables> & ({ variables: GetStudentSubmissionsForMonthQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentSubmissionsForMonthQuery, GetStudentSubmissionsForMonthQueryVariables>(GetStudentSubmissionsForMonthDocument, options);
      }
export function useGetStudentSubmissionsForMonthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentSubmissionsForMonthQuery, GetStudentSubmissionsForMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentSubmissionsForMonthQuery, GetStudentSubmissionsForMonthQueryVariables>(GetStudentSubmissionsForMonthDocument, options);
        }
export function useGetStudentSubmissionsForMonthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentSubmissionsForMonthQuery, GetStudentSubmissionsForMonthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentSubmissionsForMonthQuery, GetStudentSubmissionsForMonthQueryVariables>(GetStudentSubmissionsForMonthDocument, options);
        }
export type GetStudentSubmissionsForMonthQueryHookResult = ReturnType<typeof useGetStudentSubmissionsForMonthQuery>;
export type GetStudentSubmissionsForMonthLazyQueryHookResult = ReturnType<typeof useGetStudentSubmissionsForMonthLazyQuery>;
export type GetStudentSubmissionsForMonthSuspenseQueryHookResult = ReturnType<typeof useGetStudentSubmissionsForMonthSuspenseQuery>;
export type GetStudentSubmissionsForMonthQueryResult = Apollo.QueryResult<GetStudentSubmissionsForMonthQuery, GetStudentSubmissionsForMonthQueryVariables>;
export const GetStudentSubmissionsForTimeRangeDocument = gql`
    query GetStudentSubmissionsForTimeRange($studentId: String!, $campusName: String!, $timeRangeInMonths: Int!) {
  getStudentSubmissionsForTimeRange(
    studentId: $studentId
    campusName: $campusName
    timeRangeInMonths: $timeRangeInMonths
  ) {
    courseName
    date
    submissions {
      submittedAt
    }
  }
}
    `;

/**
 * __useGetStudentSubmissionsForTimeRangeQuery__
 *
 * To run a query within a React component, call `useGetStudentSubmissionsForTimeRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSubmissionsForTimeRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSubmissionsForTimeRangeQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      campusName: // value for 'campusName'
 *      timeRangeInMonths: // value for 'timeRangeInMonths'
 *   },
 * });
 */
export function useGetStudentSubmissionsForTimeRangeQuery(baseOptions: Apollo.QueryHookOptions<GetStudentSubmissionsForTimeRangeQuery, GetStudentSubmissionsForTimeRangeQueryVariables> & ({ variables: GetStudentSubmissionsForTimeRangeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentSubmissionsForTimeRangeQuery, GetStudentSubmissionsForTimeRangeQueryVariables>(GetStudentSubmissionsForTimeRangeDocument, options);
      }
export function useGetStudentSubmissionsForTimeRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentSubmissionsForTimeRangeQuery, GetStudentSubmissionsForTimeRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentSubmissionsForTimeRangeQuery, GetStudentSubmissionsForTimeRangeQueryVariables>(GetStudentSubmissionsForTimeRangeDocument, options);
        }
export function useGetStudentSubmissionsForTimeRangeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentSubmissionsForTimeRangeQuery, GetStudentSubmissionsForTimeRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentSubmissionsForTimeRangeQuery, GetStudentSubmissionsForTimeRangeQueryVariables>(GetStudentSubmissionsForTimeRangeDocument, options);
        }
export type GetStudentSubmissionsForTimeRangeQueryHookResult = ReturnType<typeof useGetStudentSubmissionsForTimeRangeQuery>;
export type GetStudentSubmissionsForTimeRangeLazyQueryHookResult = ReturnType<typeof useGetStudentSubmissionsForTimeRangeLazyQuery>;
export type GetStudentSubmissionsForTimeRangeSuspenseQueryHookResult = ReturnType<typeof useGetStudentSubmissionsForTimeRangeSuspenseQuery>;
export type GetStudentSubmissionsForTimeRangeQueryResult = Apollo.QueryResult<GetStudentSubmissionsForTimeRangeQuery, GetStudentSubmissionsForTimeRangeQueryVariables>;
export const GetAssignmentLeaderBoardDocument = gql`
    query GetAssignmentLeaderBoard($assignmentId: String!, $campus_name: String!) {
  getAssignmentLeaderBoard(assignmentId: $assignmentId, campus_name: $campus_name) {
    __typename
    assignment_id
    student {
      id
      name
      email
      campusId
      rollNo
    }
    totalScore
    clusterNames
    totalNumberOfQuestion
    position
    questions {
      questionId
      score
      AllotedTotalMarks
    }
  }
}
    `;

/**
 * __useGetAssignmentLeaderBoardQuery__
 *
 * To run a query within a React component, call `useGetAssignmentLeaderBoardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentLeaderBoardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentLeaderBoardQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *      campus_name: // value for 'campus_name'
 *   },
 * });
 */
export function useGetAssignmentLeaderBoardQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentLeaderBoardQuery, GetAssignmentLeaderBoardQueryVariables> & ({ variables: GetAssignmentLeaderBoardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentLeaderBoardQuery, GetAssignmentLeaderBoardQueryVariables>(GetAssignmentLeaderBoardDocument, options);
      }
export function useGetAssignmentLeaderBoardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentLeaderBoardQuery, GetAssignmentLeaderBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentLeaderBoardQuery, GetAssignmentLeaderBoardQueryVariables>(GetAssignmentLeaderBoardDocument, options);
        }
export function useGetAssignmentLeaderBoardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentLeaderBoardQuery, GetAssignmentLeaderBoardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentLeaderBoardQuery, GetAssignmentLeaderBoardQueryVariables>(GetAssignmentLeaderBoardDocument, options);
        }
export type GetAssignmentLeaderBoardQueryHookResult = ReturnType<typeof useGetAssignmentLeaderBoardQuery>;
export type GetAssignmentLeaderBoardLazyQueryHookResult = ReturnType<typeof useGetAssignmentLeaderBoardLazyQuery>;
export type GetAssignmentLeaderBoardSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentLeaderBoardSuspenseQuery>;
export type GetAssignmentLeaderBoardQueryResult = Apollo.QueryResult<GetAssignmentLeaderBoardQuery, GetAssignmentLeaderBoardQueryVariables>;
export const FindAssignmentsForStudentWithFilterDocument = gql`
    query findAssignmentsForStudentWithFilter($email: String!, $filterType: String!, $customDateRange: AssignmentFilterInput) {
  findAssignmentsForStudentWithFilter(
    email: $email
    filterType: $filterType
    customDateRange: $customDateRange
  ) {
    __typename
    _id
    id
    name
    short_description
    description
    due_date
    languages
    domain
    duration
    max_cheat_count
    max_tabSwitches
    enable_proctoring
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;

/**
 * __useFindAssignmentsForStudentWithFilterQuery__
 *
 * To run a query within a React component, call `useFindAssignmentsForStudentWithFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAssignmentsForStudentWithFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAssignmentsForStudentWithFilterQuery({
 *   variables: {
 *      email: // value for 'email'
 *      filterType: // value for 'filterType'
 *      customDateRange: // value for 'customDateRange'
 *   },
 * });
 */
export function useFindAssignmentsForStudentWithFilterQuery(baseOptions: Apollo.QueryHookOptions<FindAssignmentsForStudentWithFilterQuery, FindAssignmentsForStudentWithFilterQueryVariables> & ({ variables: FindAssignmentsForStudentWithFilterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAssignmentsForStudentWithFilterQuery, FindAssignmentsForStudentWithFilterQueryVariables>(FindAssignmentsForStudentWithFilterDocument, options);
      }
export function useFindAssignmentsForStudentWithFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAssignmentsForStudentWithFilterQuery, FindAssignmentsForStudentWithFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAssignmentsForStudentWithFilterQuery, FindAssignmentsForStudentWithFilterQueryVariables>(FindAssignmentsForStudentWithFilterDocument, options);
        }
export function useFindAssignmentsForStudentWithFilterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FindAssignmentsForStudentWithFilterQuery, FindAssignmentsForStudentWithFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FindAssignmentsForStudentWithFilterQuery, FindAssignmentsForStudentWithFilterQueryVariables>(FindAssignmentsForStudentWithFilterDocument, options);
        }
export type FindAssignmentsForStudentWithFilterQueryHookResult = ReturnType<typeof useFindAssignmentsForStudentWithFilterQuery>;
export type FindAssignmentsForStudentWithFilterLazyQueryHookResult = ReturnType<typeof useFindAssignmentsForStudentWithFilterLazyQuery>;
export type FindAssignmentsForStudentWithFilterSuspenseQueryHookResult = ReturnType<typeof useFindAssignmentsForStudentWithFilterSuspenseQuery>;
export type FindAssignmentsForStudentWithFilterQueryResult = Apollo.QueryResult<FindAssignmentsForStudentWithFilterQuery, FindAssignmentsForStudentWithFilterQueryVariables>;
export const AllCategoriesDocument = gql`
    query allCategories {
  allCategories {
    id
    name
    description
  }
}
    `;

/**
 * __useAllCategoriesQuery__
 *
 * To run a query within a React component, call `useAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
      }
export function useAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
        }
export function useAllCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllCategoriesQuery, AllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(AllCategoriesDocument, options);
        }
export type AllCategoriesQueryHookResult = ReturnType<typeof useAllCategoriesQuery>;
export type AllCategoriesLazyQueryHookResult = ReturnType<typeof useAllCategoriesLazyQuery>;
export type AllCategoriesSuspenseQueryHookResult = ReturnType<typeof useAllCategoriesSuspenseQuery>;
export type AllCategoriesQueryResult = Apollo.QueryResult<AllCategoriesQuery, AllCategoriesQueryVariables>;
export const GetQuestionsByCategoryDocument = gql`
    query getQuestionsByCategory($categoryId: String!, $courseId: String!, $page: Int, $limit: Int, $searchName: String) {
  getQuestionsByCategory(
    categoryId: $categoryId
    courseId: $courseId
    page: $page
    limit: $limit
    searchName: $searchName
  ) {
    questions {
      id
      course_id
      assignment_ids
      title
      text
      question_sub_text
      test_cases {
        id
        visibility
        input
        output
        weightage
      }
      category {
        id
        name
      }
      marks
      predefinedCode {
        c
        java
        python
        cpp
      }
      showFunctionOnly
      targetFunctionName
      difficulty
      hints
      tags
      company_tags
    }
    totalCount
  }
}
    `;

/**
 * __useGetQuestionsByCategoryQuery__
 *
 * To run a query within a React component, call `useGetQuestionsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionsByCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      courseId: // value for 'courseId'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      searchName: // value for 'searchName'
 *   },
 * });
 */
export function useGetQuestionsByCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionsByCategoryQuery, GetQuestionsByCategoryQueryVariables> & ({ variables: GetQuestionsByCategoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionsByCategoryQuery, GetQuestionsByCategoryQueryVariables>(GetQuestionsByCategoryDocument, options);
      }
export function useGetQuestionsByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionsByCategoryQuery, GetQuestionsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionsByCategoryQuery, GetQuestionsByCategoryQueryVariables>(GetQuestionsByCategoryDocument, options);
        }
export function useGetQuestionsByCategorySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetQuestionsByCategoryQuery, GetQuestionsByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetQuestionsByCategoryQuery, GetQuestionsByCategoryQueryVariables>(GetQuestionsByCategoryDocument, options);
        }
export type GetQuestionsByCategoryQueryHookResult = ReturnType<typeof useGetQuestionsByCategoryQuery>;
export type GetQuestionsByCategoryLazyQueryHookResult = ReturnType<typeof useGetQuestionsByCategoryLazyQuery>;
export type GetQuestionsByCategorySuspenseQueryHookResult = ReturnType<typeof useGetQuestionsByCategorySuspenseQuery>;
export type GetQuestionsByCategoryQueryResult = Apollo.QueryResult<GetQuestionsByCategoryQuery, GetQuestionsByCategoryQueryVariables>;
export const GetAssignmentByClusterDocument = gql`
    query getAssignmentByCluster($campusName: String!, $clusterId: String!) {
  getAssignmentByCluster(campusName: $campusName, clusterId: $clusterId) {
    id
    name
    short_description
    description
    due_date
    languages
    domain
    duration
  }
}
    `;

/**
 * __useGetAssignmentByClusterQuery__
 *
 * To run a query within a React component, call `useGetAssignmentByClusterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentByClusterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentByClusterQuery({
 *   variables: {
 *      campusName: // value for 'campusName'
 *      clusterId: // value for 'clusterId'
 *   },
 * });
 */
export function useGetAssignmentByClusterQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentByClusterQuery, GetAssignmentByClusterQueryVariables> & ({ variables: GetAssignmentByClusterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentByClusterQuery, GetAssignmentByClusterQueryVariables>(GetAssignmentByClusterDocument, options);
      }
export function useGetAssignmentByClusterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentByClusterQuery, GetAssignmentByClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentByClusterQuery, GetAssignmentByClusterQueryVariables>(GetAssignmentByClusterDocument, options);
        }
export function useGetAssignmentByClusterSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentByClusterQuery, GetAssignmentByClusterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentByClusterQuery, GetAssignmentByClusterQueryVariables>(GetAssignmentByClusterDocument, options);
        }
export type GetAssignmentByClusterQueryHookResult = ReturnType<typeof useGetAssignmentByClusterQuery>;
export type GetAssignmentByClusterLazyQueryHookResult = ReturnType<typeof useGetAssignmentByClusterLazyQuery>;
export type GetAssignmentByClusterSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentByClusterSuspenseQuery>;
export type GetAssignmentByClusterQueryResult = Apollo.QueryResult<GetAssignmentByClusterQuery, GetAssignmentByClusterQueryVariables>;
export const GetAdminUsingEmailDocument = gql`
    query getAdminUsingEmail($email: String!) {
  getAdminUsingEmail(email: $email) {
    id
    name
    email
    isSuperAdmin
    permissions {
      courses
      assignments
      reports
      student_creation
    }
  }
}
    `;

/**
 * __useGetAdminUsingEmailQuery__
 *
 * To run a query within a React component, call `useGetAdminUsingEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUsingEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUsingEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetAdminUsingEmailQuery(baseOptions: Apollo.QueryHookOptions<GetAdminUsingEmailQuery, GetAdminUsingEmailQueryVariables> & ({ variables: GetAdminUsingEmailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminUsingEmailQuery, GetAdminUsingEmailQueryVariables>(GetAdminUsingEmailDocument, options);
      }
export function useGetAdminUsingEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUsingEmailQuery, GetAdminUsingEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminUsingEmailQuery, GetAdminUsingEmailQueryVariables>(GetAdminUsingEmailDocument, options);
        }
export function useGetAdminUsingEmailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdminUsingEmailQuery, GetAdminUsingEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminUsingEmailQuery, GetAdminUsingEmailQueryVariables>(GetAdminUsingEmailDocument, options);
        }
export type GetAdminUsingEmailQueryHookResult = ReturnType<typeof useGetAdminUsingEmailQuery>;
export type GetAdminUsingEmailLazyQueryHookResult = ReturnType<typeof useGetAdminUsingEmailLazyQuery>;
export type GetAdminUsingEmailSuspenseQueryHookResult = ReturnType<typeof useGetAdminUsingEmailSuspenseQuery>;
export type GetAdminUsingEmailQueryResult = Apollo.QueryResult<GetAdminUsingEmailQuery, GetAdminUsingEmailQueryVariables>;
export const GetStudentsResultsDocument = gql`
    query getStudentsResults($campusId: String!, $searchTerm: String, $page: Int, $limit: Int, $clusterid: String) {
  getStudentsResults(
    campusId: $campusId
    searchTerm: $searchTerm
    page: $page
    limit: $limit
    clusterid: $clusterid
  ) {
    __typename
    students {
      id
      name
      email
      rollNo
      courseDetails {
        courseDetails {
          courseId
          percentageCompleted
          completedQuestion
          totalQuestion
        }
        rewardPoints
      }
      assignmentDetails {
        assignment_id
        student_id
        totalScore
      }
    }
    total
  }
}
    `;

/**
 * __useGetStudentsResultsQuery__
 *
 * To run a query within a React component, call `useGetStudentsResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsResultsQuery({
 *   variables: {
 *      campusId: // value for 'campusId'
 *      searchTerm: // value for 'searchTerm'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      clusterid: // value for 'clusterid'
 *   },
 * });
 */
export function useGetStudentsResultsQuery(baseOptions: Apollo.QueryHookOptions<GetStudentsResultsQuery, GetStudentsResultsQueryVariables> & ({ variables: GetStudentsResultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsResultsQuery, GetStudentsResultsQueryVariables>(GetStudentsResultsDocument, options);
      }
export function useGetStudentsResultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsResultsQuery, GetStudentsResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsResultsQuery, GetStudentsResultsQueryVariables>(GetStudentsResultsDocument, options);
        }
export function useGetStudentsResultsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStudentsResultsQuery, GetStudentsResultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStudentsResultsQuery, GetStudentsResultsQueryVariables>(GetStudentsResultsDocument, options);
        }
export type GetStudentsResultsQueryHookResult = ReturnType<typeof useGetStudentsResultsQuery>;
export type GetStudentsResultsLazyQueryHookResult = ReturnType<typeof useGetStudentsResultsLazyQuery>;
export type GetStudentsResultsSuspenseQueryHookResult = ReturnType<typeof useGetStudentsResultsSuspenseQuery>;
export type GetStudentsResultsQueryResult = Apollo.QueryResult<GetStudentsResultsQuery, GetStudentsResultsQueryVariables>;
export const GetAssignmentByCampusDocument = gql`
    query getAssignmentByCampus($campusName: String!) {
  getAssignmentByCampus(campusName: $campusName) {
    __typename
    _id
    id
    name
    short_description
    description
    due_date
    languages
    domain
    duration
    max_cheat_count
    max_tabSwitches
    enable_proctoring
    updatedBy
    updatedAt
    createdBy
    createdAt
  }
}
    `;

/**
 * __useGetAssignmentByCampusQuery__
 *
 * To run a query within a React component, call `useGetAssignmentByCampusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentByCampusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentByCampusQuery({
 *   variables: {
 *      campusName: // value for 'campusName'
 *   },
 * });
 */
export function useGetAssignmentByCampusQuery(baseOptions: Apollo.QueryHookOptions<GetAssignmentByCampusQuery, GetAssignmentByCampusQueryVariables> & ({ variables: GetAssignmentByCampusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignmentByCampusQuery, GetAssignmentByCampusQueryVariables>(GetAssignmentByCampusDocument, options);
      }
export function useGetAssignmentByCampusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignmentByCampusQuery, GetAssignmentByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignmentByCampusQuery, GetAssignmentByCampusQueryVariables>(GetAssignmentByCampusDocument, options);
        }
export function useGetAssignmentByCampusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignmentByCampusQuery, GetAssignmentByCampusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignmentByCampusQuery, GetAssignmentByCampusQueryVariables>(GetAssignmentByCampusDocument, options);
        }
export type GetAssignmentByCampusQueryHookResult = ReturnType<typeof useGetAssignmentByCampusQuery>;
export type GetAssignmentByCampusLazyQueryHookResult = ReturnType<typeof useGetAssignmentByCampusLazyQuery>;
export type GetAssignmentByCampusSuspenseQueryHookResult = ReturnType<typeof useGetAssignmentByCampusSuspenseQuery>;
export type GetAssignmentByCampusQueryResult = Apollo.QueryResult<GetAssignmentByCampusQuery, GetAssignmentByCampusQueryVariables>;
export const StudentsNotAttendedAssignmentDocument = gql`
    query studentsNotAttendedAssignment($campus_id: String!, $assignmentId: String!, $cluster_id: String) {
  studentsNotAttendedAssignment(
    campus_id: $campus_id
    assignmentId: $assignmentId
    cluster_id: $cluster_id
  ) {
    __typename
    student_name
    student_id
    cluster_names
    student_rollno
    student_email
  }
}
    `;

/**
 * __useStudentsNotAttendedAssignmentQuery__
 *
 * To run a query within a React component, call `useStudentsNotAttendedAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsNotAttendedAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsNotAttendedAssignmentQuery({
 *   variables: {
 *      campus_id: // value for 'campus_id'
 *      assignmentId: // value for 'assignmentId'
 *      cluster_id: // value for 'cluster_id'
 *   },
 * });
 */
export function useStudentsNotAttendedAssignmentQuery(baseOptions: Apollo.QueryHookOptions<StudentsNotAttendedAssignmentQuery, StudentsNotAttendedAssignmentQueryVariables> & ({ variables: StudentsNotAttendedAssignmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentsNotAttendedAssignmentQuery, StudentsNotAttendedAssignmentQueryVariables>(StudentsNotAttendedAssignmentDocument, options);
      }
export function useStudentsNotAttendedAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsNotAttendedAssignmentQuery, StudentsNotAttendedAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentsNotAttendedAssignmentQuery, StudentsNotAttendedAssignmentQueryVariables>(StudentsNotAttendedAssignmentDocument, options);
        }
export function useStudentsNotAttendedAssignmentSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StudentsNotAttendedAssignmentQuery, StudentsNotAttendedAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StudentsNotAttendedAssignmentQuery, StudentsNotAttendedAssignmentQueryVariables>(StudentsNotAttendedAssignmentDocument, options);
        }
export type StudentsNotAttendedAssignmentQueryHookResult = ReturnType<typeof useStudentsNotAttendedAssignmentQuery>;
export type StudentsNotAttendedAssignmentLazyQueryHookResult = ReturnType<typeof useStudentsNotAttendedAssignmentLazyQuery>;
export type StudentsNotAttendedAssignmentSuspenseQueryHookResult = ReturnType<typeof useStudentsNotAttendedAssignmentSuspenseQuery>;
export type StudentsNotAttendedAssignmentQueryResult = Apollo.QueryResult<StudentsNotAttendedAssignmentQuery, StudentsNotAttendedAssignmentQueryVariables>;