import React, { useEffect } from "react";
import { Table, Spinner, Button } from "flowbite-react";
import { MdOutlinePersonOff } from "react-icons/md";

import formatDate from "../../Admin/Helpers/formatDate.jsx";
import { AiOutlineDownload } from "react-icons/ai";
import { useGetClusterQuery } from "../../../graphql/generated.tsx";

const LeaderboardStaff = ({
  clusterId,
  campusName,
  refetch,
  filter,
  leaderboardLoading,
  leaderboardError,
  leaderboardData,
}) => {
  useEffect(() => {
    refetch({ campusName, clusterId, filter });
  }, [filter, campusName, clusterId, refetch]);

  if (leaderboardLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  if (leaderboardError) {
    return (
      <div className="text-center text-red-600">
        <p>Error: {leaderboardError.message}</p>
      </div>
    );
  }

  const leaderboard = leaderboardData?.getLeaderboard || [];
  const notStartedStudents = leaderboard.filter(
    (student) => student.totalScore === 0
  );
  const filteredLeaderboard = leaderboard.filter(
    (student) => student.totalScore > 0
  );

  const top20Students = filteredLeaderboard.slice(0, 20);
  const least20Students =
    filteredLeaderboard.length > 20 ? filteredLeaderboard.slice(-20) : [];

  
  return (
    <div className="rounded-lg bg-white p-4 shadow dark:bg-gray-800 sm:p-6 xl:p-8 my-5">
     

      {leaderboard.length > 0 ? (
        <>
          {[
            { title: "Top 20 Students", students: top20Students },
            {
              title: "Least 20 Students (Need to be monitored)",
              students: least20Students,
            },
            { title: "Not Yet Started", students: notStartedStudents },
          ].map(
            ({ title, students }) =>
              students.length > 0 && (
                <div key={title} className="mb-6">
                  <h4 className="mb-4 text-xl font-bold">{title}</h4>
                  <div className="overflow-x-auto rounded-sm shadow">
                    <Table className="min-w-full text-center">
                      <Table.Head>
                        <Table.HeadCell>Student Email</Table.HeadCell>
                        <Table.HeadCell>Last Submission</Table.HeadCell>
                        <Table.HeadCell>Total Score</Table.HeadCell>
                        <Table.HeadCell>Questions Completed</Table.HeadCell>
                      </Table.Head>
                      <Table.Body>
                        {students.map((student, index) => (
                          <Table.Row key={index} className="hover:bg-gray-50">
                            <Table.Cell>{student.student.email}</Table.Cell>
                            <Table.Cell>
                              {student.latestSubmissionDate
                                ? formatDate(student.latestSubmissionDate)
                                : "Not yet started"}
                            </Table.Cell>
                            <Table.Cell>{student.totalScore}</Table.Cell>
                            <Table.Cell>
                              {student.student.completedQuestions?.length || 0}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              )
          )}
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-[500px] text-center">
          <MdOutlinePersonOff className="text-7xl text-gray-400 mb-4" />
          <h2 className="text-2xl font-bold text-gray-600 mb-2">
            Oops, No students are enrolled in this batch
          </h2>
          <p className="text-gray-500 mb-6 lg:w-[500px]">
            It looks like we couldn't find any matching data. Try refining the
            filter options.
          </p>
        </div>
      )}
    </div>
  );
};

export default LeaderboardStaff;
