import React, { useState, useEffect } from "react";
import {
  useGetAssignmentByClusterQuery,
  useGetCampusQuery,
  useGetClustersByCampusQuery,
  useGetStaffByEmailIdQuery,
  useGetStudentsResultsQuery,
  useGetAllCoursesByClusterQuery,
} from "../../../../graphql/generated.tsx";
import { Table, Pagination, Spinner, Select } from "flowbite-react";
import AdminLayout from "../../Layout/index.jsx";
import * as XLSX from "xlsx";
import "jspdf-autotable";
import { MdOutlinePersonOff } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";

const AllStudentsInCampus = () => {
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: staffData, loading: staffLoading } = useGetStaffByEmailIdQuery({
    variables: { email },
  });
  const { data: campusDetails, loading: campusLoading } = useGetCampusQuery({
    variables: { id: staffData?.getStaffByEmailId?.campusId },
    skip: staffLoading,
  });
  const campus = campusDetails?.campus;
  const [page, setPage] = useState(1);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const limit = 30;

  const { data: clusters, loading: clusterLoading } =
    useGetClustersByCampusQuery({
      variables: { campusName: campus?.name },
      skip: campusLoading,
    });

  const availableClusters = staffData?.getStaffByEmailId?.isAdminStaff
    ? clusters?.getClustersByCampus
    : clusters?.getClustersByCampus.filter((cluster) =>
        staffData?.getStaffByEmailId?.clusterIds?.includes(cluster.id)
      );

  const [selectedClusterId, setSelectedClusterId] = useState(
    availableClusters?.[0]?.id
  );
  const { loading, error, data, refetch } = useGetStudentsResultsQuery({
    variables: {
      campusId: campus?.id,
      page,
      limit,
      clusterid: selectedClusterId || availableClusters?.[0]?.id,
    },
    skip: clusterLoading,
  });

  const { data: assignmentData, loading: assignmentLoading } =
    useGetAssignmentByClusterQuery({
      variables: { campusName: campus?.name, clusterId: selectedClusterId || availableClusters?.[0]?.id },
      skip: loading,
    });
  const { data: courseData, loading: courseLoading } =
    useGetAllCoursesByClusterQuery({
      variables: {
        campusName: campus?.name,
        clusterId: selectedClusterId || availableClusters?.[0]?.id,
      },
      skip: assignmentLoading,
    });

  const handleClusterChange = (event) => {
    setSelectedClusterId(event.target.value);
    setPage(1);
    refetch({
      campusId: campus?.id,
      page: 1,
      limit,
      clusterid: event.target.value,
    });
  };

  const totalPages = Math.max(
    1,
    Math.ceil((data?.getStudentsResults?.total || 0) / limit)
  );

  const fetchAllStudentsData = async (clusterId) => {
    const { data, loading } = await refetch({
      campusId: campus?.id,
      clusterid: clusterId,
      limit: 0,
    });
    return data?.getStudentsResults?.students || [];
  };

  const exportToExcel = async () => {
    setLoadingExcel(true);

    try {
      const allStudentData = await fetchAllStudentsData(selectedClusterId);

      const assignmentHeaders =
        assignmentData?.getAssignmentByCluster?.map(
          (assignment) => assignment?.name
        ) || [];

      const courseHeaders =
        courseData?.getAllCoursesByCluster?.flatMap((course) => [
          `${course?.name} percentage`, // Percentage header
          `${course?.name} overall`, // Overall header
        ]) || [];

      const headers = [
        "Student Name",
        "Roll Number",
        ...assignmentHeaders,
        ...courseHeaders,
      ];

      const studentData = allStudentData.map((student) => {
        const assignmentScores = assignmentData?.getAssignmentByCluster?.map(
          (assignment) => {
            const studentAssignment = student?.assignmentDetails?.find(
              (assign) => assign.assignment_id === assignment?.id
            );
            return studentAssignment?.totalScore || "-";
          }
        );

        const courseProgress = courseData?.getAllCoursesByCluster?.flatMap(
          (course) => {
            const studentCourse = student?.courseDetails?.courseDetails.find(
              (courseDetail) => courseDetail.courseId === course?.id
            );
            return [
              ` ${Math.floor(studentCourse?.percentageCompleted || 0)}%`,
              `${studentCourse?.completedQuestion || 0}/${
                studentCourse?.totalQuestion || 0
              }`,
            ];
          }
        );

        return [
          student?.name || "-",
          student?.rollNo || "-",
          ...assignmentScores,
          ...courseProgress,
        ];
      });

      const excelData = [headers, ...studentData];

      const worksheet = XLSX.utils.aoa_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Students");

      XLSX.writeFile(workbook, `${campus?.name}_students_data.xlsx`);

      setLoadingExcel(false);
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      setLoadingExcel(false);
    }
  };

  useEffect(() => {
    refetch({
      campusId: campus?.id,
      page,
      limit,
      clusterid: selectedClusterId || availableClusters?.[0]?.id,
    });
  }, [clusters]);

  if (
    loading ||
    assignmentLoading ||
    courseLoading ||
    clusterLoading ||
    staffLoading ||
    campusLoading
  )
    return (
      <AdminLayout>
        <div className="p-8 max-w-full">
          <h2 className="text-3xl font-bold mb-4 text-center">
            {campus?.name}'s Students
          </h2>
          <div className="flex flex-col justify-center items-center h-screen font-light text-gray-500 gap-2 text-center">
            <Spinner size="lg" />
            Fetching all assignments and course data...
          </div>
        </div>
      </AdminLayout>
    );

  if (error)
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          Error: {error.message}
        </div>
      </AdminLayout>
    );

  const assignmentHeaders = assignmentData?.getAssignmentByCluster.map(
    (assignment) => (
      <Table.HeadCell key={assignment?.id} className="px-10 py-3">
        {assignment?.name}
      </Table.HeadCell>
    )
  );

  const courseHeaders = courseData?.getAllCoursesByCluster.map((course) => (
    <>
      <Table.HeadCell key={course?.id} className="px-6 py-3">
        {course?.name}
        <span className="font-light text-xsm">(course)</span>
      </Table.HeadCell>
      <Table.HeadCell key={course?.id} className="px-6 py-3">
        {course?.name} Percentage
        <span className="font-light text-xsm">(course)</span>
      </Table.HeadCell>
    </>
  ));

  return (
    <AdminLayout>
      <div className="p-8 max-w-full">
        <h2 className="text-3xl font-bold mb-4 text-center">
          {campus?.name}'s Students
        </h2>
        <div className="flex justify-between mb-4 items-center">
          <div>
            <Select
              value={selectedClusterId}
              onChange={handleClusterChange}
              disabled={availableClusters?.length == 1}
            >
              {availableClusters?.map((cluster) => (
                <option key={cluster.id} value={cluster.id}>
                  {cluster.name}
                </option>
              ))}
            </Select>
          </div>
          <button
            onClick={exportToExcel}
            className="px-4 py-2 bg-primary text-white rounded-md"
            disabled={loadingExcel}
          >
            {loadingExcel ? (
              <span className="flex items-center">
                <Spinner size="sm" className="mr-2" />
                <span>Downloading...</span>
              </span>
            ) : (
              <span className="flex items-center">
                <AiOutlineDownload className="mr-2 h-5 w-4" />
                <span>Download</span>
              </span>
            )}
          </button>
        </div>

        {/* Student Table */}
        <div className="overflow-x-auto shadow-md sm:rounded-lg mt-10 max-w-full">
          {data?.getStudentsResults?.students.length != 0 ? (
            <Table className="overflow-x-auto max-w-full">
              <Table.Head>
                <Table.HeadCell>Name</Table.HeadCell>
                <Table.HeadCell>Roll No</Table.HeadCell>
                {assignmentHeaders}
                {courseHeaders}
              </Table.Head>
              <Table.Body>
                {data?.getStudentsResults?.students.map((student) => (
                  <Table.Row key={student?.id} className="bg-white">
                    <Table.Cell>{student?.name}</Table.Cell>
                    <Table.Cell>{student?.rollNo}</Table.Cell>
                    {assignmentData?.getAssignmentByCluster.map(
                      (assignment) => {
                        const studentAssignment =
                          student?.assignmentDetails?.find(
                            (assign) => assign.assignment_id === assignment?.id
                          );
                        return (
                          <Table.Cell
                            key={assignment?.id}
                            className="text-center"
                          >
                            {studentAssignment?.totalScore || "-"}
                          </Table.Cell>
                        );
                      }
                    )}
                    {courseData?.getAllCoursesByCluster.map((course) => {
                      const studentCourse =
                        student?.courseDetails?.courseDetails.find(
                          (courseDetail) => courseDetail.courseId === course?.id
                        );
                      return (
                        <>
                          <Table.Cell key={course?.id} className="text-center">
                            {studentCourse.completedQuestion}/
                            {studentCourse.totalQuestion}
                          </Table.Cell>
                          <Table.Cell key={course?.id} className="text-center">
                            {`${Math.floor(
                              studentCourse?.percentageCompleted
                            )}% ` || "0%"}
                          </Table.Cell>
                        </>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <div className="flex flex-col items-center justify-center h-[500px] text-center">
              <MdOutlinePersonOff className="text-7xl text-gray-400 mb-4" />
              <h2 className="text-2xl font-bold text-gray-600 mb-2">
                Oops, No students are enrolled in this batch
              </h2>
              <p className="text-gray-500 mb-6 lg:w-[500px]">
                It looks like we couldn't find any matching data. Try refining
                the filter options.
              </p>
            </div>
          )}
        </div>

        <div className="flex justify-center mt-4">
          <Pagination
            currentPage={page}
            layout="navigation"
            onPageChange={(newPage) => setPage(newPage)}
            totalPages={totalPages}
          />
        </div>
      </div>
    </AdminLayout>
  );
};

export default AllStudentsInCampus;
