import React from "react";
import LeftSideBar from "./Sidebar";
import NavBar from "../../../Common/NavBar";

const AdminLayout = ({ children }) => {
  return (
    <>
      <NavBar />
      <div className="flex items-start pt-14">
        <LeftSideBar />
        <main className="w-full min-h-screen bg-slate-100 max-md:ml-14 max-w-[85%] max-md:max-w-[88%]">
          {children}
        </main>
      </div>
    </>
  );
};

export default AdminLayout;
