import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout/index.jsx";
import {
  useGetCampusQuery,
  useGetClusterQuery,
  useGetClustersByCampusQuery,
  useGetLeaderboardQuery,
  useGetStaffByEmailIdQuery,
  useGetStudentsByCampusQuery,
} from "../../../../graphql/generated.tsx";
import LeaderboardStaff from "../../Components/LeaderBoardStaff.jsx";
import {
  Spinner,
  Table,
  Tabs,
  TextInput,
  Button,
  Pagination,
  Select,
} from "flowbite-react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { MdDashboard, MdOutlinePersonOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { TiGroup } from "react-icons/ti";
import { FaPerson } from "react-icons/fa6";
import formatDate from "../../Helpers/formatDate.jsx";
import { AiOutlineDownload } from "react-icons/ai";

const StaffDashboard = () => {
  const navigate = useNavigate();
  const email = JSON.parse(localStorage.getItem("user"))?.email;
  const { data: staffData } = useGetStaffByEmailIdQuery({
    variables: { email },
  });

  const { data: campusDetails, loading: campusLoading } = useGetCampusQuery({
    variables: { id: staffData?.getStaffByEmailId?.campusId },
    skip: !staffData,
  });
  const campus = campusDetails?.campus;

  const { data: clustersData, loading: clustersLoading } =
    useGetClustersByCampusQuery({
      variables: { campusName: campus?.name },
      skip: !campus,
    });

  const campusName = campus?.name;

  const handleFilter = (value) => {
    setFilter(value);
  };

  const availableClusters = staffData?.getStaffByEmailId?.isAdminStaff
    ? clustersData?.getClustersByCampus
    : clustersData?.getClustersByCampus.filter((cluster) =>
        staffData?.getStaffByEmailId?.clusterIds?.includes(cluster.id)
      );

  const [selectedCluster, setSelectedCluster] = useState(
    availableClusters?.[0]?.id
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("custom");
  const studentsPerPage = 20;
  const clusterId = selectedCluster || availableClusters?.[0]?.id;

  const { data } = useGetClusterQuery({
    variables: { clusterId: clusterId, campusName: campusName },
  });

  const {
    data: studentData,
    loading: studentLoading,
    refetch,
  } = useGetStudentsByCampusQuery({
    variables: {
      campusId: campus?.id,
      clusterid: selectedCluster || availableClusters?.[0]?.id,
      searchTerm: searchClicked ? searchTerm : "",
      page,
      limit: studentsPerPage,
    },
  });

  const handleSearchClick = () => {
    setSearchClicked(true);
    refetch({
      campusId: campus?.id,
      clusterid: selectedCluster,
      searchTerm,
    });
    setSearchClicked(false);
  };

  const totalStudents = studentData?.getStudentsByCampus?.total || 0;
  const totalPages = Math.ceil(totalStudents / studentsPerPage);

  const {
    data: leaderboardData,
    loading: leaderboardLoading,
    error: leaderboardError,
    refetch: leaderboardRefetch,
  } = useGetLeaderboardQuery({
    variables: {
      campusName: campus?.name,
      clusterId: selectedCluster || availableClusters?.[0]?.id,
    },
  });

  const Datepicker = () => {
    return (
      <Select value={filter} onChange={(e) => handleFilter(e.target.value)}>
        <option value="custom">TILL DATE</option>
        <option value="weekly">PAST 7 DAYS</option>
        <option value="daily">TODAY</option>
        <option value="past10days">PAST 10 DAYS</option>
        <option value="monthly">THIS MONTH</option>
      </Select>
    );
  };

  const leaderboard = leaderboardData?.getLeaderboard || [];
  const notStartedStudents = leaderboard.filter(
    (student) => student.totalScore === 0
  );
  const filteredLeaderboard = leaderboard.filter(
    (student) => student.totalScore > 0
  );

  const top20Students = filteredLeaderboard.slice(0, 20);
  const least20Students =
    filteredLeaderboard.length > 20 ? filteredLeaderboard.slice(-20) : [];

  const downloadExcel = () => {
    const headers = [
      "Position",
      "Student Email",
      "Last Submission Date",
      "Total Score",
      "Total Completed Questions",
      "Easy Questions Completed (Course)",
      "Medium Questions Completed (Course)",
      "Hard Questions Completed (Course)",
      "Total Leetcode Questions Completed",
      "Leetcode Easy",
      "Leetcode Medium",
      "Leetcode Hard",
    ];
    const getData = (students) =>
      students.map((student, index) => {
        const leetcodeEasy =
          student.leetcode_data?.find((data) => data.difficulty === "Easy")
            ?.count || 0;
        const leetcodeMedium =
          student.leetcode_data?.find((data) => data.difficulty === "Medium")
            ?.count || 0;
        const leetcodeHard =
          student.leetcode_data?.find((data) => data.difficulty === "Hard")
            ?.count || 0;

        return [
          index + 1,
          student.student.email,
          student.latestSubmissionDate
            ? formatDate(student.latestSubmissionDate)
            : "Not yet started",
          student.totalScore,
          student.student.completedQuestions?.length || 0,
          student.easyQuestionsCompleted || 0,
          student.mediumQuestionsCompleted || 0,
          student.hardQuestionCompleted || 0,
          leetcodeEasy + leetcodeMedium + leetcodeHard,
          leetcodeEasy,
          leetcodeMedium,
          leetcodeHard,
        ];
      });

    const workbook = XLSX.utils.book_new();
    const addSheet = (data, sheetName) => {
      const sheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
      XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
    };

    addSheet(getData(top20Students), "Top 20 Students");
    addSheet(getData(least20Students), "Least 20 Students");
    addSheet(getData(notStartedStudents), "Not Yet Started");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      `Leaderboard_${campusName}_${data?.getCluster?.name}.xlsx`
    );
  };

  if (clustersLoading || studentLoading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center h-screen">
          <Spinner size="xl" />
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="overflow-x-auto">
        <Tabs aria-label="Full width tabs" variant="fullWidth">
          {/* Individual Tab */}
          <Tabs.Item title="Student Lookup" active icon={FaPerson}>
            <div className="p-4 sm:p-10">
              <div className="flex flex-col sm:flex-row justify-between items-center mb-4 gap-2">
                <Select
                  value={selectedCluster}
                  onChange={(e) => setSelectedCluster(e.target.value)}
                  disabled={availableClusters?.length == 1}
                >
                  {availableClusters?.map((cluster) => (
                    <option key={cluster.id} value={cluster.id}>
                      {cluster.name}
                    </option>
                  ))}
                </Select>

                <form
                  className="flex w-full sm:w-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearchClick();
                  }}
                >
                  <TextInput
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    placeholder="Search by name, roll no, or email"
                    className="rounded-none rounded-l-lg w-[235px] "
                    style={{ borderRadius: "10px 0px 0px 10px" }}
                  />
                  <Button
                    type="submit"
                    className="rounded-none rounded-r-lg bg-primary"
                  >
                    Search
                  </Button>
                </form>
              </div>
              <div className="overflow-x-auto">
                {/* Display student data in a table */}

                {studentData?.getStudentsByCampus?.students?.length != 0 && (
                  <div className="rounded-lg bg-white p-6 shadow-lg dark:bg-gray-900 sm:p-8 xl:p-10 my-6">
                    <h4 className="mb-4 text-2xl font-bold text-gray-900 dark:text-white">
                      Student Dashboard
                    </h4>
                    <p className="text-gray-600 dark:text-gray-300 mb-6">
                      Track an individual student's daily submissions, course
                      progress, and LeetCode progress.
                    </p>
                    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 border text-center rounded-lg">
                      <Table.Head className="bg-gray-50 dark:bg-gray-800">
                        <Table.HeadCell className="text-sm font-semibold text-gray-800 dark:text-gray-200">
                          Name
                        </Table.HeadCell>
                        <Table.HeadCell className="text-sm font-semibold text-gray-800 dark:text-gray-200">
                          Roll No
                        </Table.HeadCell>
                        <Table.HeadCell className="text-sm font-semibold text-gray-800 dark:text-gray-200">
                          Email
                        </Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="bg-white dark:bg-gray-900">
                        {studentData?.getStudentsByCampus?.students?.map(
                          (student) => (
                            <Table.Row
                              key={student.id}
                              className="hover:bg-gray-100 dark:hover:bg-gray-800 transition duration-150 ease-in-out"
                            >
                              <Table.Cell
                                onClick={() =>
                                  navigate(`student/${student.id}`)
                                }
                                className="cursor-pointer px-4 py-3 text-gray-800 dark:text-gray-200 hover:underline hover:text-blue-500"
                              >
                                {student.name}
                              </Table.Cell>
                              <Table.Cell className="px-4 py-3 text-gray-600 dark:text-gray-400">
                                {student.rollNo}
                              </Table.Cell>
                              <Table.Cell className="px-4 py-3 text-gray-600 dark:text-gray-400">
                                {student.email}
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </Table>
                    {studentData?.getStudentsByCampus?.students?.length !== 0 &&
                      !searchTerm && (
                        <div className="flex justify-center mt-6">
                          <Pagination
                            currentPage={page}
                            layout="navigation"
                            onPageChange={(newPage) => setPage(newPage)}
                            totalPages={totalPages}
                          />
                        </div>
                      )}
                  </div>
                )}
              </div>
              {studentData?.getStudentsByCampus?.students?.length === 0 && (
                <div className="flex flex-col items-center justify-center h-[500px] text-center">
                  <MdOutlinePersonOff className="text-7xl text-gray-400 mb-4" />
                  <h2 className="text-2xl font-bold text-gray-600 mb-2">
                    Oops, No students are enrolled in this batch
                  </h2>
                  <p className="text-gray-500 mb-6 lg:w-[500px]">
                    It looks like we couldn't find any matching data. Try
                    refining the filter options.
                  </p>
                </div>
              )}
            </div>
          </Tabs.Item>

          <Tabs.Item title="Batch Analysis" icon={TiGroup}>
            <div className="p-4 sm:p-10">
              <div className="flex justify-between">
                <Select
                  value={selectedCluster}
                  onChange={(e) => setSelectedCluster(e.target.value)}
                  disabled={availableClusters?.length == 1}
                >
                  {availableClusters?.map((cluster) => (
                    <option key={cluster.id} value={cluster.id}>
                      {cluster.name}
                    </option>
                  ))}
                </Select>
                <div className="flex gap-5 items-center justify-center">
                  {leaderboardData?.getLeaderboard.length != 0 && (
                    <div className="flex items-center justify-between">
                      <Datepicker />
                    </div>
                  )}
                  <Button
                    onClick={downloadExcel}
                    className="bg-primary text-white flex items-center h-[40px]"
                  >
                    <AiOutlineDownload className="mr-2 h-5 w-4" />
                    Download
                  </Button>
                </div>
              </div>
              <LeaderboardStaff
                onDownload={downloadExcel}
                clusterId={clusterId}
                campusName={campusName}
                refetch={leaderboardRefetch}
                filter={filter}
                leaderboardLoading={leaderboardLoading}
                leaderboardError={leaderboardError}
                leaderboardData={leaderboardData}
              />
            </div>
          </Tabs.Item>
        </Tabs>
      </div>
    </AdminLayout>
  );
};

export default StaffDashboard;
