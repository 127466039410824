import { Button, Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import {
  useAddAssignmentToClusterMutation,
  useGetAllAssignmentsQuery,
  useGetAssignmentByClusterQuery,
  useRemoveAssignmentFromClusterMutation,
} from "../../../../graphql/generated.tsx";
import AdminLayout from "../../Layout/index.jsx";
import Select from "react-select";
import AssignmentCard from "../AssignmentCard.jsx";

const ManageAssignmentTab = ({ campusName, clusterId }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [assignments, setAssignments] = useState([]);

  const { data: allAssignmentData } = useGetAllAssignmentsQuery();
  const [addAssignmentToCluster] = useAddAssignmentToClusterMutation();
  const [removeAssignmentFromCluster] =
    useRemoveAssignmentFromClusterMutation();

  const {
    data: assignmentData,
    error: assignmentError,
    refetch,
  } = useGetAssignmentByClusterQuery({
    variables: { campusName, clusterId },
    onCompleted: (data) => setAssignments(data.getAssignmentByCluster),
  });

  const handleAddAssignment = async () => {
    try {
      await addAssignmentToCluster({
        variables: {
          clusterId,
          assignmentId: selectedAssignment?.value, // Convert to array with a single value
          campusName,
        },
      });
      refetch();
      setShowModal(false);
    } catch (error) {
      console.error("Error adding assignment:", error);
    }
  };

  useEffect(() => {
    refetch();
    setAssignments(assignmentData?.getAssignmentByCluster);
  }, [assignmentData]);

  const handleRemoveAssignment = async (assignmentId) => {
    try {
      await removeAssignmentFromCluster({
        variables: { clusterId, assignmentId, campusName },
      });
      setAssignments((prevAssignments) =>
        prevAssignments.filter((assignment) => assignment.id !== assignmentId)
      );
    } catch (error) {
      console.error("Error removing assignment:", error);
    }
  };

  if (assignmentError)
    return <AdminLayout>Error: {assignmentError.message}</AdminLayout>;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <div className="p-10">
        <div className="flex justify-end items-end mb-8 max-md:gap-2">
          <Button
            onClick={toggleModal}
            className="text-white p-0 rounded-lg shadow-md flex items-center bg-primary"
          >
            <IoMdAdd className="mr-1 mt-1" /> <span>Add Assignment</span>
          </Button>
        </div>
        <div className="flex gap-6 flex-wrap max-md:p-5">
          {assignments?.map((assignment) => (
            <AssignmentCard
              key={assignment.id}
              assignment_id={assignment.id}
              name={assignment.name}
              description={assignment.short_description}
              due_date={assignment.due_date}
              onRemove={() => handleRemoveAssignment(assignment.id)}
            />
          ))}
        </div>
      </div>
      <Modal show={showModal} onClose={toggleModal}>
        <Modal.Header>Add Assignment</Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleAddAssignment();
            }}
          >
            <div className="mb-4 h-[200px]">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Select Assignment
              </label>
              <Select
                options={allAssignmentData?.getAllAssignments?.map(
                  (assignment) => ({
                    label: assignment.name,
                    value: assignment.id,
                  })
                )}
                value={selectedAssignment} // Single selected value
                onChange={setSelectedAssignment}
                placeholder="Select an assignment"
                isClearable // Optional: to clear the selection
              />
            </div>
            <div className="flex items-center justify-between">
              <Button type="submit">Add</Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageAssignmentTab;
