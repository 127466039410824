import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetAllAssignmentQuestionQuery,
  useGetAssignmentSubmitQuery,
  useGetCampusQuery,
  useGetStudentByEmailIdQuery,
} from "../../../graphql/generated.tsx";
import TestScreenQuestionCard from "../Components/TestScreenQuestionCard.jsx";
import { Button, Spinner } from "flowbite-react";
import { IoMdArrowRoundBack } from "react-icons/io";
import swal from "sweetalert";
import TestLayout from "../Layout/TestLayout.jsx";

const AssignmentTestScreen = () => {
  const { assignmentId } = useParams();
  const navigate = useNavigate();
  const [assignmentQuestion, setAssignmentQuestion] = useState([]);
  const [fullScreen, setFullScreen] = useState(document.fullscreenElement);
  const studentEmail = JSON.parse(localStorage.getItem("user")).email;

  const {
    data: student,
    loading: studentLoading,
    error: studentError,
  } = useGetStudentByEmailIdQuery({
    variables: { email: studentEmail },
  });

  const {
    data: AssignmentQuestions,
    loading: questionsLoading,
    error: questionsError,
  } = useGetAllAssignmentQuestionQuery({
    variables: { assignmentId },
  });

  const { data: campus } = useGetCampusQuery({
    variables: { id: student?.getStudentByEmailId.campusId },
  });

  const {
    data: AssignmentCompleted,
    loading: submitLoading,
    error: submitError,
    refetch,
  } = useGetAssignmentSubmitQuery({
    variables: {
      assignmentId,
      studentId: student?.getStudentByEmailId?.id,
      campus_name: campus?.campus?.name,
    },
    skip: !student?.getStudentByEmailId || !campus?.campus,
  });

  useEffect(() => {
    if (AssignmentQuestions) {
      setAssignmentQuestion(AssignmentQuestions.getAllAssignmentQuestion);
    }
    refetch();
  }, [AssignmentQuestions]);

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    }
  };

  const handleFullScreenChange = () => {
    setFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (!document.fullscreenElement) {
      toggleFullScreen();
    }
  }, []);

  if (studentLoading || questionsLoading || submitLoading) {
    return (
      <div className="flex justify-center items-center w-screen h-screen bg-gray-100">
        <Spinner aria-label="Loading" size="xl" />
      </div>
    );
  }

  if (studentError || questionsError || submitError) {
    return (
      <div className="flex justify-center items-center w-screen h-screen bg-gray-100">
        <h1 className="text-red-500 text-center">
          An error occurred. Please try again later.
        </h1>
      </div>
    );
  }

  return (
    <>
      {AssignmentCompleted?.getAssignmentSubmit?.completed ? (
        <div className="w-screen h-screen bg-gray-100 flex justify-center items-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-md flex flex-col justify-center items-center gap-4 max-w-lg w-full">
            <h1 className="text-2xl md:text-3xl font-bold text-center text-gray-800">
              Assignment already completed
            </h1>
            <Button
              onClick={() => navigate(`/assignments/${assignmentId}`)}
              className="bg-primary flex justify-center items-center gap-2 hover:bg-primary-dark transition-all duration-300"
            >
              <IoMdArrowRoundBack className="mt-1 mr-2" /> Go Back
            </Button>
          </div>
        </div>
      ) : fullScreen ? (
        <TestLayout assignmentId={assignmentId}>
          <h1 className="text-2xl md:text-3xl font-bold text-center">
            Assignment Test
          </h1>
          <div className="mt-6 md:mt-8 p-4">
            <div className="flex flex-col gap-4">
              {assignmentQuestion?.map((question, index) => (
                <div key={index}>
                  <TestScreenQuestionCard
                    data={question}
                    completedQuestions={
                      AssignmentCompleted?.getAssignmentSubmit?.questions
                    }
                    refetch={refetch}
                  />
                </div>
              ))}
            </div>
          </div>
        </TestLayout>
      ) : (
        <div className="flex flex-col justify-center items-center w-screen h-screen bg-gray-100 p-4">
          <h1 className="text-lg md:text-xl mb-4 text-center">
            Please switch to Fullscreen to continue
          </h1>
          <Button className="bg-primary" onClick={toggleFullScreen}>
            Full Screen
          </Button>
        </div>
      )}
    </>
  );
};

export default AssignmentTestScreen;
