import { Card } from "flowbite-react";
import React, { useState, useEffect } from "react";
import ChangingProgressProvider from "../Utils/ChangingProgressProvider";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useGetQuestionCompletedQuery } from "../../../graphql/generated.tsx";

const QuestionSolved = ({studentData}) => {
  const student_id = studentData ? studentData.id : null;
  
  const { data: studentQuestion, loading, error } = useGetQuestionCompletedQuery({
    variables: { student_id, is_paid: false },
  });

  const [hoverText, setHoverText] = useState(null);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (studentQuestion) {
      const overallCompleted = studentQuestion.getQuestionCompleted.overall.completed;
      const overallTotal = studentQuestion.getQuestionCompleted.overall.total;
      setPercentage((overallCompleted / overallTotal) * 100);
    }
  }, [studentQuestion]);

  const handleMouseEnter = () => {
    setHoverText(
      `${studentQuestion?.getQuestionCompleted?.easy.completed || 0}/${
        studentQuestion?.getQuestionCompleted?.easy.total || 0
      }`
    );
  };

  const handleMouseLeave = () => {
    setHoverText(null);
  };

  if (loading) return <p className="flex justify-center items-center h-full">Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Card className="w-[320px] mt-5">
      <div className="flex justify-between gap-10 w-full">
        <div className="w-[250px] h-[200px] flex items-center">
          <ChangingProgressProvider values={[0, percentage]}>
            {(value) => (
              <div
                onMouseEnter={() => handleMouseEnter(value)}
                onMouseLeave={handleMouseLeave}
              >
                <CircularProgressbarWithChildren
                  value={value}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathTransitionDuration: 0.5,
                    pathColor: `#053859`,
                    trailColor: "#F6C550",
                    backgroundColor: "#053859",
                  })}
                >
                  <span className="text-2xl text-primary">
                    {hoverText || `${value.toFixed(2)}%`}
                  </span>
                  <span className="text-sm font-light text-primary">
                    Question Solved
                  </span>
                </CircularProgressbarWithChildren>
              </div>
            )}
          </ChangingProgressProvider>
        </div>
        <div className="text-center text-[15px] flex flex-col justify-between">
          <div className="bg-gray-50 p-1 w-[80px] rounded-md">
            <h5 className="block text-green-800 font-semibold">Easy</h5>
            <p className="font-medium">
              {studentQuestion?.getQuestionCompleted?.easy.completed || 0}/
              {studentQuestion?.getQuestionCompleted?.easy.total || 0}
            </p>
          </div>
          <div className="bg-gray-50 p-1 w-[80px] rounded-md mt-2">
            <h5 className="block text-yellow-800 font-semibold">Medium</h5>
            <p className="font-medium">
              {studentQuestion?.getQuestionCompleted?.medium.completed || 0}/
              {studentQuestion?.getQuestionCompleted?.medium.total || 0}
            </p>
          </div>
          <div className="bg-gray-50 p-1 w-[80px] rounded-md mt-2">
            <h5 className="block text-red-800 font-semibold">Hard</h5>
            <p className="font-medium">
              {studentQuestion?.getQuestionCompleted?.hard.completed || 0}/
              {studentQuestion?.getQuestionCompleted?.hard.total || 0}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default QuestionSolved;
