import React, { useEffect, useState } from "react";
import AdminLayout from "../../Layout";
import { useParams } from "react-router-dom";
import axios from "axios";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Button, Select } from "flowbite-react";
import * as XLSX from "xlsx";
import { AiOutlineDownload } from "react-icons/ai";

const CampusFeedback = () => {
  const { college_name } = useParams();
  const [feedbackData, setFeedbackData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("All");
  const [batchCounts, setBatchCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_FEEDBACK_API}/apiforms/api/getFeedbackByCollege`,
          {
            params: { collegeName: college_name },
          }
        );

        if (response.data.success) {
          const data = response.data.data;
          setFeedbackData(data);
          setFilteredData(data);

          const batchCounts = data.reduce((counts, feedback) => {
            const batch = feedback.batch || "Unknown";
            counts[batch] = (counts[batch] || 0) + 1;
            return counts;
          }, {});

          batchCounts["All"] = data.length;

          const uniqueBatches = [
            "All",
            ...Object.keys(batchCounts).filter((b) => b !== "All"),
          ];
          setBatches(uniqueBatches);
          setBatchCounts(batchCounts);
        }
      } catch (error) {
        console.error("Error fetching feedback data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedback();
  }, [college_name]);

  const handleBatchFilter = (batch) => {
    setSelectedBatch(batch);
    if (batch === "All") setFilteredData(feedbackData);
    else
      setFilteredData(
        feedbackData.filter((feedback) => feedback.batch === batch)
      );
  };

  const handleDateFilter = (date) => {
    setSelectedDate(date);
    if (!date) setFilteredData(feedbackData);
    else
      setFilteredData(
        feedbackData.filter((feedback) => {
          const feedbackDate = new Date(feedback.timestamp)
            .toISOString()
            .split("T")[0];
          return feedbackDate === date;
        })
      );
  };

  const handleDownload = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback");
    XLSX.writeFile(workbook, `${college_name}_feedback.xlsx`);
  };

 <AdminLayout>
   <div className="flex items-center justify-center h-screen">
     <p className="">Loading...</p>
   </div>
 </AdminLayout>;

  return (
    <AdminLayout>
      <div className="p-10 space-y-8">
        <div className="flex items-center justify-between">
          <button
            onClick={() => window.history.back()}
            className="text-gray-500 hover:text-gray-700 focus:outline-none flex items-center"
          >
            <IoMdArrowRoundBack className="h-6 w-6 mr-2" />
            <span className="text-xl font-bold">Back</span>
          </button>
          <div className="text-2xl font-bold text-gray-800">
            Feedback for {college_name}
          </div>
          <div className="space-y-1 text-right">
            <div className="text-lg">
              <b>Total Feedback: </b>
              {batchCounts["All"] || 0}
            </div>
            <div className="text-lg">
              <b>Feedback Count in {selectedBatch}: </b>
              {batchCounts[selectedBatch] || 0}
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex gap-4 items-center">
            <div>
              <label htmlFor="batch-filter" className="block text-sm font-bold">
                Filter by Batch
              </label>
              <Select
                id="batch-filter"
                value={selectedBatch}
                onChange={(e) => handleBatchFilter(e.target.value)}
                className="w-48"
              >
                {batches.map((batch) => (
                  <option key={batch} value={batch}>
                    {batch} ({batchCounts[batch] || 0})
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <label htmlFor="date-filter" className="block text-sm font-bold">
                Filter by Date
              </label>
              <input
                type="date"
                id="date-filter"
                value={selectedDate}
                onChange={(e) => handleDateFilter(e.target.value)}
                className="w-48 border border-gray-300 rounded-md"
              />
            </div>
            <Button
              onClick={handleDownload}
              className="ml-auto bg-primary hover:bg-blue-600 text-white"
            >
              <AiOutlineDownload className="mr-2 h-5 w-4" />
              <span>Download</span>
            </Button>
          </div>
        </div>

        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full border-collapse">
            <thead className="bg-gray-200 text-gray-800 text-sm font-bold">
              <tr>
                {Object.keys(filteredData[0] || {}).map(
                  (key) =>
                    key !== "_id" && (
                      <th
                        key={key}
                        className="px-4 py-2 border border-gray-300"
                      >
                        {key.replace(/_/g, " ").toUpperCase()}
                      </th>
                    )
                )}
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 ? (
                <tr>
                  <td
                    colSpan={Object.keys(filteredData[0] || {}).length}
                    className="text-center px-4 py-2 text-gray-500"
                  >
                    No feedback available for this college.
                  </td>
                </tr>
              ) : (
                filteredData.map((feedback, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                  >
                    {Object.entries(feedback).map(([key, value]) =>
                      key !== "_id" ? (
                        <td
                          key={key}
                          className="px-4 py-2 border border-gray-300"
                        >
                          {value || "_"}
                        </td>
                      ) : null
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default CampusFeedback;
