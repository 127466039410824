import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import AdminLayout from "../../Layout/index.jsx";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Checkbox, Label, TextInput, Textarea } from "flowbite-react";
import { toast } from "react-toastify";
import { useCreateAssignmentMutation } from "../../../../graphql/generated.tsx";

const CreateAssignment = () => {
  const navigate = useNavigate();
  const [createAssignment] = useCreateAssignmentMutation();
  const [formData, setFormData] = useState({
    name: "",
    short_description: "",
    description: "",
    due_date: "",
    duration: 1,
    languages: [],
    domain: "",
    max_tabSwitches: 0,
    max_cheat_count: 0,
    enable_proctoring: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLanguageChange = (selectedOptions) => {
    const selectedLanguages = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setFormData({
      ...formData,
      languages: selectedLanguages,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createAssignment({
        variables: {
          createAssignmentInput: {
            name: formData.name,
            short_description: formData.short_description,
            description: formData.description,
            duration: parseInt(formData.duration),
            due_date: new Date(formData.due_date),
            languages: formData.languages,
            domain: formData.domain,
            max_tabSwitches: parseInt(formData.max_tabSwitches),
            max_cheat_count: parseInt(formData.max_cheat_count),
            enable_proctoring: Boolean(formData.enable_proctoring),
          },
        },
      });
      toast.success("Assignment created successfully");
      navigate("/admin/assignments");
    } catch (error) {
      toast.error("Error creating assignment");
      console.error(error);
    }
  };

  const languageOptions = [
    { value: "Python", label: "Python" },
    { value: "C", label: "C" },
    { value: "Java", label: "Java" },
    { value: "Cpp", label: "Cpp" },
  ];

  return (
    <AdminLayout>
      <div className="p-8 max-md:p-5">
        <div className="flex items-center mb-6">
          <button
            onClick={() => window.history.back()}
            className="text-gray-500 hover:text-gray-700 focus:outline-none w-[100px]"
          >
            <span className="gap-2 text-sm font-medium flex items-center">
              <IoMdArrowRoundBack className="h-6 w-6" />
              Go Back
            </span>
          </button>
        </div>
        <h2 className="text-3xl font-bold text-center w-full max-md:text-xl mb-4">
          Create Assignment
        </h2>
        <form
          onSubmit={handleSubmit}
          className="max-w-2xl mx-auto bg-white rounded-md shadow-md p-7"
        >
          <Label className="font-semibold mb-2" htmlFor="name" value="Name:" />
          <TextInput
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full mb-4"
            required
          />

          <Label
            className="font-semibold mb-2"
            htmlFor="short_description"
            value="Short Description:"
          />
          <Textarea
            id="short_description"
            name="short_description"
            value={formData.short_description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md mb-4"
            required
          />

          <Label
            className="font-semibold mb-2"
            htmlFor="description"
            value="Description:"
          />
          <Textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md mb-4"
            required
          />

          <Label
            className="font-semibold mb-2"
            htmlFor="duration"
            value="Duration:"
          />
          <TextInput
            type="number"
            id="duration"
            name="duration"
            value={formData.duration}
            onChange={handleChange}
            className="w-full mb-4"
            required
          />

          <div className="mb-5">
            <Checkbox
              name="enable_proctoring"
              id="enableProctoring"
              value={formData.enable_proctoring}
              onChange={handleChange}
            />
            <Label
              htmlFor="enable_proctoring"
              value="Enable Proctoring"
              className="ml-4"
            />
          </div>

          <Label
            className="font-semibold mb-2"
            htmlFor="max_cheat_count"
            value="Maximum cheat count:"
          />
          <TextInput
            type="number"
            id="max_cheat_count"
            name="max_cheat_count"
            value={formData.max_cheat_count}
            onChange={handleChange}
            className="w-full mb-4"
            required
          />
          <Label
            className="font-semibold mb-2"
            htmlFor="max_tabSwitches"
            value="Maximum Tab Switches:"
          />
          <TextInput
            type="number"
            id="max_tabSwitches"
            name="max_tabSwitches"
            value={formData.max_tabSwitches}
            onChange={handleChange}
            className="w-full mb-4"
            required
          />

          <Label
            className="font-semibold mb-2"
            htmlFor="due_date"
            value="Due Date:"
          />
          <input
            type="date"
            id="due_date"
            name="due_date"
            value={formData.due_date}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-md mb-4"
            required
          />

          <Label
            className="font-semibold mb-2"
            htmlFor="languages"
            value="Languages:"
          />
          <Select
            id="languages"
            name="languages"
            options={languageOptions}
            isMulti
            onChange={handleLanguageChange}
            className="w-full mb-4"
            required
          />

          <Label
            className="font-semibold mb-2"
            htmlFor="domain"
            value="Domain:"
          />
          <TextInput
            type="text"
            id="domain"
            name="domain"
            value={formData.domain}
            onChange={handleChange}
            className="w-full mb-4"
            required
          />

          <button
            type="submit"
            className="bg-primary hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-4"
          >
            Create
          </button>
        </form>
      </div>
    </AdminLayout>
  );
};

export default CreateAssignment;
